import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap'
import { Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';
import { Disclaimer } from '../../pages/registration/disclaimer/Disclaimer';

const CheckboxGroup = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  md,
  rows,
  controlId,
  options,
  disabled,
  readOnly,
  ref,
  id,
  checked,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>

      <Field name={name} validate={validate}  {...props}>
        {({
          field,
          form: { touched, errors },
          meta,
        }) => (
          <>
            <Form.Check className='d-flex align-items-start'>
              <Form.Check.Input id={id} name={name}
                ref={ref}
                as={as}
                type={type}
                label={label}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                options={options}
                errors={errors}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
                placeholder={placeholder}
                checked={checked} />
              <span className="ms-2 d-flex">
                <Form.Check.Label for={id}>{label}</Form.Check.Label>

                <span
                  className={"ms-2 mb-0"}
                >
                  <Disclaimer />
                </span>
              </span>

            </Form.Check>
            {/* <Form.Check
              name={name}
              ref={ref}
              as={as}
              id={id}
              type={type}
              label={label}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              options={options}
              errors={errors}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              checked={checked}
            /> */}

            {errors[name] && (
              <span id={`${name}-error"`} className="errorMessage">
                <ErrorMessage name={name} />
              </span>
            )}
          </>
        )}

      </Field>
    </React.Fragment>
  )
}

CheckboxGroup.defaultProps = {
  type: "checkbox",
  className: ""
}
CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  // type: PropTypes.string,
  type: PropTypes.oneOf(['checkbox']),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export default CheckboxGroup;