export const formattedLocation = (content) => {
   // const content = JSON.parse(localStorage.getItem("content"));

    const locationFormat = content && content.activity && content.activity.content && content.activity.content.locationFormat;
    const location = content && content.activity && content.activity.location;
    
    if (locationFormat === "City") {
        return location.city;
    } else if (locationFormat === "State") {
        return location.state;
    } else if (locationFormat === "Country") {
        return location.country;
    } else if (locationFormat === "City-Country") {
        return (location.city + " - " + location.country);
    }
    else if (locationFormat === "State-Country") {
        return (location.state + " - " + location.country);
    }
    else if (locationFormat === "City-State-Country") {
        return (location.city + " - " + location.state + " - " + location.country);
    }
    return false

};