import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const CMSFormRadioButtons = (props) => {
  const { label, name, options, form, required, ...rest } = props;
  const { t, i18n } = useTranslation();
  return (
    <div>
      <label
        htmlFor={name}
        className={`${i18n.language === "ar" ? "arabic pe-2" : ""} ${
          required == true ? "mandatory_field ps-2 " : " "
        } ps-1 mb-3`}
      >
        {" "}
        {label}
      </label>
      <div
        className={` ${
          form.touched[name] && form.errors[name]
            ? "is-invalid payment "
            : "payment "
        }`}
      >
        {options.map((option) => {
          return (
            // <div key={option.label} className="checkBoxContainer mb-1 pb-1 ms-2">
            //   <Field
            //     name={name}
            //     type="radio"
            //     id={option.value}
            //     value={option.value}
            //     checked={form.values[name] ? form.values[name] === option.value:false}
            //   />

            //   <label
            //     htmlFor={option.value}
            //     className={` ${i18n.language === "ar" ?"me-2" : "ms-2"} ${
            //       form.touched[name] && form.errors[name]
            //         ? "isInvalidText  radioText"
            //         : " radioText"
            //     }`}
            //   >
            //     {option.label}
            //   </label>
            // </div>
            <div
              key={option.label}
              className="checkBoxContainer mb-1 pb-1 ms-2 "
            >
              <label
                className={` ${
                  form.touched[name] && form.errors[name]
                    ? "isInvalidText radioText"
                    : "radioText"
                }`}
              >
                <Field
                  name={name}
                  type="radio"
                  id={option.value}
                  className={`${i18n.language === "ar" ?"arabic" :"ms-2"} checkbox`}
                  value={option.value}
                  //defaultChecked={form.values[name] && form.values[name].includes(option.value)}
                />
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage component="div" name={name} className="errorMessage" />
        </span>
      )}
    </div>
  );
};

export default CMSFormRadioButtons;
