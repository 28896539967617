import React from "react";
import "./loading.scss";
export const ContainerLoading = () => {
  return (
    <div className="loadingio-spinner-interwind-e2jlp3mbxhq">
      <div className="ldio-41eevpgdn5u">
        <div>
          <div>
            <div>
              <div></div>
            </div>
          </div>
          <div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const SubmissionLoading = () => {
  return (
    <div className="SubmissionLoading hasLightBg">
      <div className="loadingImage">
      <div className="loadingio-spinner-interwind-e2jlp3mbxhq">
      <div className="ldio-41eevpgdn5u">
        <div>
          <div>
            <div>
              <div></div>
            </div>
          </div>
          <div>
            <div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>

  );
};
export const LocationLoading = () => {
  return (
    <div className="loadingContainer hasLightBg">
      <div className="loadingImage">
        <h1>Loading</h1>
      </div>
    </div>
  );
};
