import React, { useState, useEffect } from "react";
import "./countDown.scss";
import { useTranslation } from "react-i18next";

const Countdown = ({ date }) => {
  const [state, setState] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });
  const [items, setItems] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }

    const dateObject = new Date(date.date);
    const startTime = date.startTime || "00:00";
    const [hours, minutes] = startTime.split(':');
    dateObject.setHours(parseInt(hours, 10), parseInt(minutes, 10));

    const updateCountdown = () => {
      const now = new Date().getTime();
      const diff = dateObject.getTime() - now;

      if (diff <= 0) {
        clearInterval(countdown);
        setState({ days: "00", hours: "00", minutes: "00", seconds: "00" });
        return;
      }

      const days = String(Math.floor(diff / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'));
      const hours = String(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0'));
      const minutes = String(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0'));
      const seconds = String(Math.floor((diff % (1000 * 60)) / 1000).toString().padStart(2, '0'));

      setState({ days, hours, minutes, seconds });
    };

    const countdown = setInterval(updateCountdown, 1000);

    // Run the update once immediately to avoid delay
    updateCountdown();

    return () => clearInterval(countdown);
  }, [date]);

  return (
    <>
      {date && (
        <div className="countdown w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex flex-column-reverse text-center">
            <span className="number days">{state.days}</span>
            {state.days !== "NaN" && <span className="text">{items.countDownDays}</span>}
          </div>
          <div className="d-flex flex-column-reverse text-center">
            <span className="number hours">{state.hours}</span>
            {state.hours !== "NaN" && <span className="text">{items.countDownHours}</span>}
          </div>
          <div className="d-flex flex-column-reverse text-center">
            <span className="number minutes">{state.minutes}</span>
            {state.minutes !== "NaN" && <span className="text">{items.countDownMinutes}</span>}
          </div>
          <div className="d-flex flex-column-reverse text-center">
            <span className="number seconds">{state.seconds}</span>
            {state.seconds !== "NaN" && <span className="text">{items.countDownSeconds}</span>}
          </div>
        </div>
      )}
    </>
  );
};

export default Countdown;
