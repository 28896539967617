import React, { useState, useEffect, useReducer } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { IoMdCloseCircle } from "react-icons/io";
import "./disclaimer.scss";
import { useTranslation } from "react-i18next";
import {
  listingInitialState,
  listingReducer,
} from "../../../shared/reducers/listingReducer";
import axios from "../../../../config/axios";
import BlockErrorMessage from "../../../shared/errorMessages/BlockErrorMessage";
function MyVerticallyCenteredModal(props) {
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={i18n.language === "ar" ? "modalDiv arabic" : "modalDiv"}>
        <Modal.Header className="bg-tertiaryClient">
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="mb-0 text-primaryWhite modalTitle ">
              {" "}
              {screen.items && screen.items.formLabelDisclaimer}
            </h4>
          </Modal.Title>
          <div>
            <IoMdCloseCircle
              onClick={() => props.onHide()}
              className="closeIcon"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <Row className="p-3">
            {screen.content && screen.content.activity && screen.content.activity.disclaimer && screen.content.activity.disclaimer.disclaimer ?
              <Col className="d-flex align-item-center justify-content-center flex-column">
                {i18n.language === "ar" ? (
                  <div
                    className="html fs-7"
                    dangerouslySetInnerHTML={{
                      __html: screen.content.activity.disclaimer.disclaimerArabic,
                    }}
                  />
                ) : (
                  <div
                    className="html fs-7"
                    dangerouslySetInnerHTML={{
                      __html: screen.content.activity.disclaimer.disclaimer
                    }}
                  />
                )}
              </Col> : <BlockErrorMessage message1="Disclaimer Not Available" />}
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
}
export const Disclaimer = () => {
  const [modalShow, setModalShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);

  return (
    <>
      {screen.content && screen.content.activity && screen.content.activity.disclaimer && screen.content.activity.disclaimer.hasDisclaimer === "Add as Checkbox in Form" && (
        <>
          <p
            className="text-tertiaryClient cursor-pointer tandc mb-1"
            onClick={() => setModalShow(true)}
          >
            {screen.items && screen.items.formLabelTerms}
          </p>

          <MyVerticallyCenteredModal
            show={modalShow}
            data={screen.content.activity.disclaimer.disclaimer}
            onHide={() => setModalShow(false)}
          />
        </>
      )}
    </>
  );
};
