import React from "react";
import { Button } from "react-bootstrap";
import "./buttons.scss";
const pathname = window.location.pathname.split("/").pop(); //returns the current url minus the domain name

export const HoverBlue = (props) => {
  return (
    <div style={props.style}>
      <Button className="HoverBlue" style={props.style}>
        {props.name}
      </Button>
    </div>
  );
};
export const HoverWhite = (props) => {
  return (
    <div style={props.container}>
      <Button className="HoverWhite" style={props.style}>
        {props.name}
      </Button>
    </div>
  );
};
export const BorderBlue = (props) => {
  return (
    <div style={props.container}>
      <Button
        className={`BorderBlue`}
        style={props.style}
      >
        {props.name}
      </Button>
    </div>
  );
};
export const BorderPrimaryBlue = (props) => {
  return (
    <div style={props.container}>
      <Button
        className={`BorderPrimaryBlue`}
      >
        {props.name}
      </Button>
    </div>
  );
};
