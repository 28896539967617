import React, { useReducer, useEffect, useState, useContext } from "react";
import Header from "./header/Header";
import { Container } from "react-bootstrap";
import Footer from "./footer/Footer";
import "./layout.scss";
import Subheader from "./header/subheader/SubHeader";
import SubFooter from "./footer/subFooter/SubFooter";
import { useTranslation } from "react-i18next";
import {
  detailsInitialState,
  detailsReducer,
} from "../shared/reducers/detailsReducer";
import { ContainerLoading } from "../shared/loading/Loading";
import Tweet from "./footer/tweet/Tweet";
import axios from "../../config/axios";
import constantsEn from '../../assets/constantsEn.json';
import { listingInitialState, listingReducer } from "../shared/reducers/listingReducer";
import { useParams } from "react-router-dom";
import ErrorPage from "../pages/error/ErrorPage";
import { ClientContext } from "../../context/ClientContext";
const BodyLayout = (props) => {
  const { t, i18n } = useTranslation();
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    detailsInitialState
  );
  const { client, setClient } = useContext(ClientContext)
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const { slug, url } = useParams();
  localStorage.setItem("slug", JSON.stringify(slug));
  const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`activities/content/${slug}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      sessionStorage.setItem("content", JSON.stringify(res.data));
      localStorage.setItem("content", JSON.stringify(res.data));
      localStorage.setItem("currentActivity", JSON.stringify({ clientId: res.data.activity && res.data.activity.clientId, activityId: res.data.activity && res.data.activity._id, slug: slug }));
      localStorage.setItem("clientDetails", JSON.stringify(res.data.clientData));

      setClient((prevState) => ({
        ...prevState,
        data: res.data?.clientData,
      }));
      // localStorage.setItem("currentActivity", JSON.stringify({ clientId: process.env.REACT_APP_CLIENT_ID, activityId: process.env.REACT_APP_ACTIVITY_ID, slug: slug }));
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      //localStorage.removeItem("content")
    }
  };
  // localStorage.removeItem("content")
  useEffect(() => {
    //loadItems();

    if (i18n.language === "en") {
      localStorage.setItem("constants", JSON.stringify(constantsEn["constants-en"]));
    }
    else if (i18n.language === "ar") {
      localStorage.setItem("constants", JSON.stringify(constantsEn["constants-ar"]));
    }

  }, [slug]);
  useEffect(() => {
    //loadItems();
    const pathname = window.location.pathname;
    const shouldLoadItems = pathname.includes("agenda") ||
      pathname.includes("speakers") ||
      pathname.includes("sponsors-partners") ||
      pathname.includes("about") ||
      pathname.includes("registration");

    if (shouldLoadItems) {
      loadItems();
    }

  }, [slug]);


  return (
    <>
      {!client.data && <div className="loadingContainer">
        <ContainerLoading />
      </div>}
      {listingState.status === "fetchFailed" ? <ErrorPage slug={slug} url={url} /> : (
        <Container fluid="xl" className="containerMaxWidth p-0">
          <Header />
          <Subheader />
          {props.children}

          <SubFooter />
          <Tweet />
          <Footer />
        </Container>
      )}
    </>
  );
};

export default BodyLayout;
