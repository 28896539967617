import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getStatusByDay } from "../../../../Helper/featuresHelper";

const TopGrid = ({ parent, parentState, maxDay }) => {
  const { i18n } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    active: "1",
    items: {}
  });
  const { slug, day, url } = useParams();
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    const activeDay = JSON.parse(localStorage.getItem(`activeAgendaDay${slug}`));
    if (items) {
      setState((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    if (activeDay) {
      setState((prevState) => ({
        ...prevState,
        active: day,
      }));
    }
  }, [slug]);
  const navigate = useNavigate();
  const changeParameter = (path) => {
    if (slug != undefined && url != undefined) {
      navigate(`/agenda/${slug}/${url}/${path}`)
    }
    else if (slug !== undefined) {
      navigate(`/agenda/${slug}/${path}`)
    }
    else {
      navigate(`/agenda/${path}`)
    }

  };
  const jsxArray = [];

  for (let i = 1; i <= maxDay; i++) {
    if (getStatusByDay(i,slug) && maxDay > 1) {
      jsxArray.push(
        <div
          key={i}
          title={state.items && state.items.tooltipAgendaDay}
          className={`grid-item ${i18n.language === "ar" ? "arabic" : ""} ${day === `${i}` || day === i ? "active" : ""
            }`}
          onClick={() => {
            setState({ ...state, active: `${i}` });
            parentState({ ...parent, active: `${i}` });
            localStorage.setItem(
              `activeAgendaDay${slug}`,
              JSON.stringify(i)
            )
            changeParameter(i);
          }}
        >
          {state.items && state.items.agendaDayText} {i}
        </div>
      );
    }

  }

  // jsxArray contains the JSX code block repeated three times

  return (
    <div className="grid-container">
      {jsxArray}

    </div>
  );
};

export default TopGrid;
