import React, { useRef, useState, useReducer, useEffect } from "react";
// Import Swiper React components
import "swiper/scss";
import "../../../../../assets/scss/_swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import "./animation.scss";
import {
  listingInitialState,
  listingReducer,
} from "../../../../shared/reducers/listingReducer";
import axios from "../../../../../config/axios";
function Animation() {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );

  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`event_tweets`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
    //  loadItems();
    }, 100);
  }, []);
  return (
    <>
      <div className="tweetSwiper">
        {listingState &&
        !listingState.isFetching &&
        listingState.status === "fetched" &&
        listingState.data &&
        listingState.data.length > 0 ? (
          <Swiper
            direction={"vertical"}
            pagination={false}
            autoplay={{
              delay: 2500,
            }}
            loop
            modules={[Autoplay]}
          >
            {listingState.data &&
              listingState.data.map((tweets, index) => {
                return (
                  <SwiperSlide key={index}>
                    <span
                      className="truncate"
                      dangerouslySetInnerHTML={{
                        __html: tweets.text,
                      }}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <Swiper
            direction={"vertical"}
            pagination={false}
            autoplay={false}
            loop
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <p>Tweet your thoughts! Use the hashtag and join the online community.</p>
            </SwiperSlide>
          </Swiper>
        )}
      </div>
    </>
  );
}
export default Animation;
