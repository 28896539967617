import React, { useState, useEffect } from "react";
import "../subfooter.scss";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { HoverWhite } from "../../../../shared/buttons/Buttons";
import { useTranslation } from "react-i18next";
function RegisterNow() {
  const button_style = {
    width: "133.02px",
    height: "52.02px",
  };
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  const { slug, url } = useParams();
  const pathname = window.location.pathname;
  const { t, i18n } = useTranslation();
  function doesPathnameMatch(pathname) {
    // Regular expression pattern to match "about" or "disclaimer" anywhere in the pathname
    const pattern = /\/(about|disclaimer|sponsors-partners|contact-us|speakers|highlights)\//i;
    const pattern2 = /\/(1|2|3|4|5|6|7|8|9)\//i;
    const pattern3 = /\/(registration)\//i;
    if (pattern.test(pathname)) {
  
      return 1
    } else if (pattern2.test(pathname)) {
   
      return 2
    } else if (pattern3.test(pathname)) {
    
      return 3
    } else { return 4 }


  }
  return (
    <>
      {items.buttonRegisterNow && items.buttonClickHere &&
        <div className="paddingContainerReg">
          <div
            className={
              i18n.language === "ar"
                ? "contentContainerReg arabic"
                : "contentContainerReg"
            }
          >
            <Row className="d-flex align-items-center registerNowRow">
              <Col
              xl={3}
                lg={4}
                md={5}
                sm={6}
                xs={12}
                className={i18n.language === "ar" ? "pe-xl-0" : "ps-0"}
              >
                <h2 className="left-text">{items.buttonRegisterNow}</h2>
              </Col>
              <Col xl={2} lg={3} md={6} xs={2} sm={6} className="ps-0">
                {doesPathnameMatch(pathname) === 3 ? null : <Link
                  to={slug != undefined && url != undefined ? `/registration/${slug}/${url}` : slug != undefined ? `/registration/${slug}` : "/registration"}
                  title={items.buttonRegisterNow}
                  className="subFooterClickHereLink "
                >
                  <HoverWhite name={items.buttonClickHere} style={button_style} />
                </Link>}

              </Col>
            </Row>
          </div>
        </div>}
    </>
  );
}

export default RegisterNow;
