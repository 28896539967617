import React, { useState, useEffect, useReducer } from "react";
import BodyLayout from "../../layout/BodyLayout";
import { useTranslation } from "react-i18next";
import "./about.scss";
import {
  listingInitialState,
  listingReducer,
} from "../../shared/reducers/listingReducer";
import axios from "../../../config/axios";
import { ContainerLoading } from "../../shared/loading/Loading";
import BlockErrorMessage from "../../shared/errorMessages/BlockErrorMessage";
import Heading from "./header/Header";
import { useParams } from "react-router-dom";
import { removeAndCapitalizeEachWord } from "../../../Helper/featuresHelper";
const AboutTheEvent = () => {
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  const { slug } = useParams();
  
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    //loadItems();
 

  }, []);
  const content = JSON.parse(sessionStorage.getItem("content"));
  useEffect(() => {
      if (content) {
        setScreen((prevState) => ({
          ...prevState,
          content: content,
        }));
      }
  }, [])
  useEffect(() => {
    document.title = `${removeAndCapitalizeEachWord(slug)} | About The Event`;
  }, [slug]);

  return (
    <BodyLayout>
    
        {screen.content && screen.content.activity && screen.content.activity.content &&
          <div className="paddingContainerAbout">
            <div className="contentContainerAbout">
              {" "}
              <div
                className={`aboutContent ${i18n.language === "ar" ? "arabic" : ""}`}
              >

                {screen.content.activity.content.description ?
                  <>
                    <Heading />
                    <div className="about_description">
                      {i18n.language === "ar" ? (
                        <div
                          className="html"
                          dangerouslySetInnerHTML={{
                            __html: screen.content.activity.content.descriptionArabic,
                          }}
                        />
                      ) : (
                        <div
                          className="html"
                          dangerouslySetInnerHTML={{
                            __html: screen.content.activity.content.description,
                          }}
                        />
                      )}
                    </div>
                  </>
                  : <BlockErrorMessage message1="Content Not Available " />}


              </div>
            </div>
          </div>
        }
      
    </BodyLayout>
  );
};

export default AboutTheEvent;
