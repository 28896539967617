import React, { useEffect, useReducer, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./registerSubheader.scss";
import calender from "../../../../assets/images/calender.svg";
import { InlineShareButtons } from "sharethis-reactjs";
import share from "../../../../assets/images/share.svg";
import {
  listingInitialState,
  listingReducer,
} from "../../../shared/reducers/listingReducer";
import axios from "../../../../config/axios";
import i18n from "../../../../i18n";
import { featureAllowed } from "../../../../Helper/featuresHelper";
function RegisterSubheader(props) {

  const [screen, setScreen] = useState({
    show: false,
    content: {}
  })
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`events`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const [registrationState, registrationDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadRegistrationDetails = async () => {
    registrationDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`event_Registration`); //
      registrationDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

    } catch (error) {
      registrationDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    //loadItems();
    //loadRegistrationDetails();
  }, []);
  useEffect(() => {
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {

      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  return (
    <div className="paddingContainerRegTop">
      <div
        className={
          i18n.language === "ar"
            ? "contentContainerRegTop arabic"
            : "contentContainerRegTop"
        }
      >
        <Row className=" mx-auto  text-primaryWhite">
          <Col xl={12} className="mx-auto Titlecontainer ">
            {props.needed == "no" ? (
              <Row className="d-flex align-items-center TitleRow">
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className={`${i18n.language === "ar" ? "pe-xl-0" : "ps-xl-0"
                  }`}>
                  {screen.content && screen.content.activity && (
                    <>

                      <h2
                        className={`titleText ${i18n.language === "ar" ? "arabic" : ""
                          }`}
                      >
                        {i18n.language === "ar"
                          ? screen.content.activity.titleArabic
                          : screen.content.activity.title}
                      </h2>

                    </>
                  )}
                </Col>
              </Row>
            ) : (
              <>
                <Row
                  className={`d-flex align-items-center TitleRow   py-xl-0  ${i18n.language === "ar" ? "arabic" : ""
                    }`}
                >
                  <Col
                    xl={props.file && featureAllowed("showAddToCalendarOnRegPage") && featureAllowed("showShareLinkOnRegPage") ? 10 : featureAllowed("showShareLinkOnRegPage") ? 10 : props.file && featureAllowed("showAddToCalendarOnRegPage") ? 10 : 12}
                    lg={props.file && featureAllowed("showAddToCalendarOnRegPage") && featureAllowed("showShareLinkOnRegPage") ? 8 : featureAllowed("showShareLinkOnRegPage") ? 10 : props.file && featureAllowed("showAddToCalendarOnRegPage") ? 10 : 12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={`${i18n.language === "ar" ? "pe-xl-0" : "ps-xl-0"
                      }`}
                  >
                    {screen.content && screen.content.activity && (
                      <>

                        <h2
                          className={`titleText mb-md-3 mb-xl-0 mb-lg-0 ${i18n.language === "ar" ? "arabic" : ""
                            }`}
                        >
                          {i18n.language === "ar"
                            ? screen.content.activity.titleArabic
                            : screen.content.activity.title}
                        </h2>

                      </>
                    )}
                  </Col>
                  {props.file && (
                    <>
                      <>
                        {featureAllowed("showShareLinkOnRegPage") && (
                          <Col
                            xl={(props.file && featureAllowed("showAddToCalendarOnRegPage"))
                              ? 1
                              : 2
                            }
                            lg={2}
                            md={1}
                            sm={2}
                            xs={3}
                            className="d-flex align-items-center justify-content-end position-relative mt-xl-0 mt-lg-0 mt-md-2"
                          >
                            {screen.show &&
                              <div className={`${i18n.language === "ar" ? "inlineShareArabic" : "inlineShare"}`}>

                                <InlineShareButtons
                                  config={{
                                    alignment: "center", // alignment of buttons (left, center, right)
                                    color: "social", // set the color of buttons (social, white)
                                    enabled: true, // show/hide buttons (true, false)
                                    font_size: 15, // font size for the buttons
                                    labels: "", // button labels (cta, counts, null)
                                    language: "en", // which language to use (see LANGUAGES)
                                    networks: [
                                      "facebook",
                                      "twitter",
                                      "linkedin",
                                      "email",
                                      "whatsapp", // Add Instagram
                                      "twitter",   // Add Twitter
                                    ],
                                    padding: 5, // padding within buttons (INTEGER)
                                    radius: 4, // the corner radius on each button (INTEGER)
                                    show_total: false,
                                    size: 40, // the size of each button (INTEGER)

                                    // OPTIONAL PARAMETERS

                                    // url: "https://www.sharethis.com", // (defaults to current url)
                                    // image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
                                    // description: "custom text", // (defaults to og:description or twitter:description)
                                    // title: "custom title", // (defaults to og:title or twitter:title)
                                    // message: "custom email text", // (only for email sharing)
                                    // subject: "custom email subject", // (only for email sharing)
                                    // username: "custom twitter handle", // (only for twitter sharing)
                                  }}
                                />
                              </div>}

                            <a
                              className="ms-xl-0 "
                              title="Share"

                              onClick={() => setScreen((prevState) => ({
                                ...prevState,
                                show: !screen.show,
                              }))}
                            >
                              <img
                                src={calender}
                                alt="Share"
                                className="cursor-pointer"
                                width="35"
                                height="40"
                              />
                            </a>
                          </Col>
                        )}
                        {props.file && featureAllowed("showAddToCalendarOnRegPage") && (
                          <Col
                            xl={
                              featureAllowed("showShareLinkOnRegPage")
                                ? 1
                                : 2
                            }
                            lg={2}
                            md={1}
                            sm={2}
                            xs={3}
                            className={`mt-xl-0 mt-lg-0 mt-md-2 d-flex align-items-center justify-content-end ${i18n.language === "ar" ? "ps-xl-0" : "pe-xl-0"
                              }`}
                          >
                            <a
                              href={props.file}
                              download
                              target="_blank"
                              title="Add to calender"
                              className="ms-xl-0 "
                            >
                              <img
                                src={share}
                                alt="Add to Calender"
                                width="40"
                                height="40"
                              />
                            </a>
                          </Col>
                        )}
                      </>
                    </>
                  )}

                </Row>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div >
  );
}

export default RegisterSubheader;
