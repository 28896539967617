import React, { useState, useEffect, useReducer } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import "../../pages/speakers/speakerPage.scss";
import dummy from "../../../assets/images/dummy-speaker.png";
import { useTranslation } from "react-i18next";
import { IoMdCloseCircle } from "react-icons/io";
import AgendaForSpeaker from "../../pages/agenda/agendaPartials/AgendaForSpeaker";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { featureAllowed, generalFeatureAllowed } from "../../../Helper/featuresHelper";
function MyVerticallyCenteredModal(speaker) {
  const handleOnError = (e) => {
    e.target.src = dummy;
  };

  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  return (
    <Modal
      {...speaker}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >


      <div
        key={speaker.speaker._id}
        className={
          i18n.language === "ar" ? "modalDiv arabic" : "modalDiv"
        }
      >
        <Modal.Header className="bg-tertiaryClient">
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="text-primaryWhite modalTitle mb-0">
              {screen.items && screen.items.modalSpeakerDetailsText}
            </h4>
          </Modal.Title>
          <div>
            <IoMdCloseCircle
              onClick={() => speaker.onHide()}
              className="closeIcon"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Row className="px-4">
            <Col
              xl={11} lg={11} md={10}
              className="speakerStyleDisplay my-3"
            >
              <div
                className={
                  i18n.language === "ar"
                    ? "d-flex align-item-center justify-content-center modalSpeakerImage mt-3 ms-4"
                    : "d-flex align-item-center justify-content-center modalSpeakerImage mt-3 me-xl-4 me-lg-4 me-md-4"
                }
              >
                <img
                  src={speaker.speaker.photo ? process.env.REACT_APP_BACKEND_URL + "public/" + speaker.speaker.photo : dummy}
                  width="150"
                  height="150"
                  onError={handleOnError}
                />
              </div>
              <div className="d-flex align-item-center justify-content-center flex-column mt-3">
                <h4 className="speaker_name noHeight">
                  {i18n.language === "ar"
                    ? speaker.speaker.firstNameArabic +
                    " " +
                    speaker.speaker.lastNameArabic
                    : speaker.speaker.firstName + " " + speaker.speaker.lastName}
                </h4>
                <h6 className="text-secondaryClient italic mb-1">
                  {i18n.language === "ar"
                    ? speaker.speaker.companyArabic
                    : speaker.speaker.company}
                  {speaker.speaker.company && speaker.speaker.designation && ", "}
                  {i18n.language === "ar"
                    ? speaker.speaker.designationArabic
                    : speaker.speaker.designation}
                </h6>
                {speaker.speaker.country &&
                  <h6 className="text-tertiaryClient fs-8 mb-0">
                    {i18n.language === "ar"
                      ? speaker.speaker.countryArabic
                      : speaker.speaker.country}
                  </h6>}
                {/* {speaker.speaker.nationality &&
                  <h6 className="text-quaternaryClient fs-8 mb-0">
                    {i18n.language === "ar"
                      ? speaker.speaker.nationalityArabic
                      : speaker.speaker.nationality}
                  </h6>} */}
                {screen.content && screen.content.activity && screen.content.activity.speakerSettings && <>

                  {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showEmail === "1" && speaker.speaker.email &&
                    <a href={`mailto:${speaker.speaker.email}`} className="mb-1  emailSpeaker">
                      {i18n.language === "ar" ? (
                        <>{speaker.speaker.email}</>
                      ) : (
                        <>
                          {speaker.speaker.email}</>
                      )}
                    </a>}
                  {screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.showPhone === "1" && speaker.speaker.mobile &&
                    <a href={`tel:${speaker.speaker.mobile}`} className="mb-1  phoneSpeaker">
                      {i18n.language === "ar" ? (
                        <>{speaker.speaker.mobile}</>
                      ) : (
                        <>{speaker.speaker.mobile}</>
                      )}
                    </a>}
                </>}
                <p
                  className="fs-8 text-primaryGray html mt-1"
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "ar"
                        ? speaker.speaker.introductionArabic
                        : speaker.speaker.introduction,
                  }}
                ></p>
              </div>
            </Col>
            <Col className="speakerSocialChannel">
              <div className="speaker_img_social" >
                {speaker.speaker.facebookProfile && <a className=" icon" href={speaker.speaker.facebookProfile} title="View facebook"><FaFacebook className=" icon" /></a>}
                {speaker.speaker.instagramProfile && <a className=" icon" href={speaker.speaker.instagramProfile} title="View instagram"><FaInstagram className=" icon" /></a>}
                {speaker.speaker.linkedinProfile && <a className=" icon" href={speaker.speaker.linkedinProfile} title="View linkedin"><FaLinkedin className=" icon" /></a>}
                {speaker.speaker.twitterProfile && <a className=" icon" href={speaker.speaker.twitterProfile} title="View twitter"><FaSquareXTwitter className=" icon" /></a>}
                {speaker.speaker.youtubeProfile && <a className=" icon" href={speaker.speaker.youtubeProfile} title="View youtube"><FaYoutube className=" icon" /></a>}



              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col className="mx-3 mt-2"><h6 className="bold">Session(s)</h6></Col>
          </Row> */}
          {generalFeatureAllowed("agenda") &&
            <AgendaForSpeaker id={speaker.speaker._id} sessions={speaker.speaker.speakerSessions} />}
        </Modal.Body>
      </div>


    </Modal>
  );
}
export const SpeakersModal = ({ effects, speaker }) => {
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    modalShow: false,
    items: {}
  });
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
  }, []);
  return (
    <>
      {effects === "no" ? (
        <Col className="speakerColumn2" xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="speaker_container ">
            <div className="speaker_img mx-auto">
              <img
                className="mx-auto"
                // src={`https://ahead.events/aep/uploads/images/${speakers.image}`}
                src={speaker.photo ? process.env.REACT_APP_BACKEND_URL + "public/" + speaker.photo : dummy}
                alt={speaker.firstName}
                loading="lazy"
              />
            </div>
            <hr />

            <h6 className={`speaker_name ${(speaker.firstName + " " + speaker.lastName).length > 40 ? "small" : ""}`}>
              {i18n.language === "ar"
                ? speaker.firstNameArabic + " " + speaker.lastNameArabic
                : speaker.firstName + " " + speaker.lastName}
            </h6>
            {speaker.company &&
              <p className="speaker_designation mb-1 text-secondaryClient italic mb-1">
                {i18n.language === "ar" ? speaker.companyArabic : speaker.company}
              </p>}
            {speaker.designation &&
              <p className="speaker_designation mb-2">
                {" "}
                {i18n.language === "ar"
                  ? speaker.designationArabic
                  : speaker.designation}
              </p>}
            <a onClick={() => setScreen((prevState) => ({
              ...prevState,
              modalShow: true,
            }))}>
              <div
                className="about_speaker cursor-pointer"
                title={`${i18n.language === "ar"
                  ? "معرفة المزيد عن " +
                  speaker.firstNameArabic +
                  " " +
                  speaker.lastNameArabic
                  : "Know more about " +
                  speaker.firstName +
                  " " +
                  speaker.lastName
                  }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M2203.756,1877.677l2.041-2.035a2.73,2.73,0,0,0,.824-2,2.759,2.759,0,0,0-.864-2.042l.864-.864a2.762,2.762,0,0,0,2.046.864,2.727,2.727,0,0,0,1.991-.813l2.031-2.016a2.823,2.823,0,0,0,.014-3.993l-1.427-1.441a2.664,2.664,0,0,0-1.991-.835,2.718,2.718,0,0,0-2,.823l-2.035,2.043a2.836,2.836,0,0,0,.041,4.036l-.864.864a2.759,2.759,0,0,0-2.046-.864,2.67,2.67,0,0,0-1.99.82l-2.031,2.016a2.685,2.685,0,0,0-.835,1.995,2.731,2.731,0,0,0,.812,1.991l1.435,1.442a2.654,2.654,0,0,0,1.984.834A2.729,2.729,0,0,0,2203.756,1877.677Zm4.2-8.275c.018-.022.08-.08.183-.183s.176-.172.212-.212l.147-.183a.911.911,0,0,0,.128-.249,1.038,1.038,0,0,0,.033-.271.9.9,0,0,0-.275-.662.907.907,0,0,0-.662-.275,1.046,1.046,0,0,0-.275.033.752.752,0,0,0-.245.128,1.357,1.357,0,0,0-.19.143c-.04.033-.11.106-.213.209s-.157.168-.175.19a.929.929,0,0,1-.3-.7.894.894,0,0,1,.264-.669l2.042-2.035a.9.9,0,0,1,.662-.275.9.9,0,0,1,.659.275l1.427,1.442a.949.949,0,0,1,0,1.332l-2.031,2.016a.911.911,0,0,1-.669.275A.933.933,0,0,1,2207.953,1869.4Zm-6.851,6.942-1.434-1.441a.939.939,0,0,1-.253-.67.891.891,0,0,1,.264-.663l2.031-2.016a.91.91,0,0,1,.669-.274.944.944,0,0,1,.714.318c-.022.018-.088.08-.19.183s-.176.172-.213.212a1.618,1.618,0,0,0-.146.183.769.769,0,0,0-.124.253.879.879,0,0,0-.037.267.939.939,0,0,0,.944.944,1.006,1.006,0,0,0,.267-.033.887.887,0,0,0,.249-.132c.084-.066.147-.117.187-.15s.11-.1.212-.2.161-.168.183-.19a.955.955,0,0,1,.315.71.907.907,0,0,1-.275.662l-2.042,2.042a.931.931,0,0,1-1.321,0Z"
                    transform="translate(-2197.523 -1862.5)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </a>
          </div>
        </Col>
      ) : (
        <Col
          title={screen.items && screen.items.tooltipMoreDetails}
          onClick={() => setScreen((prevState) => ({
            ...prevState,
            modalShow: true,
          }))}
          className="cursor-pointer speakerColumn"
          xl={3}
          lg={3}
          md={4}
          sm={6}
          xs={12}
        >
          <div className="speaker_container ">
            <div className="speaker_img mx-auto">
              <img
                className="mx-auto"
                // src={`https://ahead.events/aep/uploads/images/${speakers.image}`}
                src={speaker.photo ? process.env.REACT_APP_BACKEND_URL + "public/" + speaker.photo : dummy}
                alt={speaker.firstName}
                loading="lazy"
              />
            </div>
            <hr />
            <h6 className={`speaker_name ${(speaker.firstName + " " + speaker.lastName).length > 40 ? "small" : ""}`}>
              {i18n.language === "ar"
                ? speaker.firstNameArabic + " " + speaker.lastNameArabic
                : speaker.firstName + " " + speaker.lastName}
            </h6>
            {speaker.company &&
              <p className="speaker_designation mb-1 text-secondaryClient italic mb-1">
                {i18n.language === "ar" ? speaker.companyArabic : speaker.company}
              </p>}
            {speaker.designation &&
              <p className="speaker_designation fs-7 mb-2">
                {" "}
                {i18n.language === "ar"
                  ? speaker.designationArabic
                  : speaker.designation}
              </p>}
            <a onClick={() => setScreen((prevState) => ({
              ...prevState,
              modalShow: true,
            }))}>
              <div
                className="about_speaker cursor-pointer"
                title={`${i18n.language === "ar"
                  ? "معرفة المزيد عن " +
                  speaker.firstNameArabic +
                  " " +
                  speaker.lastNameArabic
                  : "Know more about " +
                  speaker.firstName +
                  " " +
                  speaker.lastName
                  }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M2203.756,1877.677l2.041-2.035a2.73,2.73,0,0,0,.824-2,2.759,2.759,0,0,0-.864-2.042l.864-.864a2.762,2.762,0,0,0,2.046.864,2.727,2.727,0,0,0,1.991-.813l2.031-2.016a2.823,2.823,0,0,0,.014-3.993l-1.427-1.441a2.664,2.664,0,0,0-1.991-.835,2.718,2.718,0,0,0-2,.823l-2.035,2.043a2.836,2.836,0,0,0,.041,4.036l-.864.864a2.759,2.759,0,0,0-2.046-.864,2.67,2.67,0,0,0-1.99.82l-2.031,2.016a2.685,2.685,0,0,0-.835,1.995,2.731,2.731,0,0,0,.812,1.991l1.435,1.442a2.654,2.654,0,0,0,1.984.834A2.729,2.729,0,0,0,2203.756,1877.677Zm4.2-8.275c.018-.022.08-.08.183-.183s.176-.172.212-.212l.147-.183a.911.911,0,0,0,.128-.249,1.038,1.038,0,0,0,.033-.271.9.9,0,0,0-.275-.662.907.907,0,0,0-.662-.275,1.046,1.046,0,0,0-.275.033.752.752,0,0,0-.245.128,1.357,1.357,0,0,0-.19.143c-.04.033-.11.106-.213.209s-.157.168-.175.19a.929.929,0,0,1-.3-.7.894.894,0,0,1,.264-.669l2.042-2.035a.9.9,0,0,1,.662-.275.9.9,0,0,1,.659.275l1.427,1.442a.949.949,0,0,1,0,1.332l-2.031,2.016a.911.911,0,0,1-.669.275A.933.933,0,0,1,2207.953,1869.4Zm-6.851,6.942-1.434-1.441a.939.939,0,0,1-.253-.67.891.891,0,0,1,.264-.663l2.031-2.016a.91.91,0,0,1,.669-.274.944.944,0,0,1,.714.318c-.022.018-.088.08-.19.183s-.176.172-.213.212a1.618,1.618,0,0,0-.146.183.769.769,0,0,0-.124.253.879.879,0,0,0-.037.267.939.939,0,0,0,.944.944,1.006,1.006,0,0,0,.267-.033.887.887,0,0,0,.249-.132c.084-.066.147-.117.187-.15s.11-.1.212-.2.161-.168.183-.19a.955.955,0,0,1,.315.71.907.907,0,0,1-.275.662l-2.042,2.042a.931.931,0,0,1-1.321,0Z"
                    transform="translate(-2197.523 -1862.5)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </a>
          </div>
        </Col>
      )}

      <MyVerticallyCenteredModal
        show={screen.modalShow}
        id={speaker.id}
        speaker={speaker}

        onHide={() => setScreen((prevState) => ({
          ...prevState,
          modalShow: false,
        }))}
      />
    </>
  );
};
