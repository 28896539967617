import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import "../../speakerPage.scss";
import buttonLoad from "../../../../../assets/images/buttonload.json";
import { BiErrorCircle } from "react-icons/bi";
import Lottie from "lottie-react";
import { SpeakerLayoutList } from "../SpeakerLayoutList";
import { SpeakersModal } from "../../../../shared/speakerModal/SpeakerModal";
import { BorderBlue } from "../../../../shared/buttons/Buttons";
import {
  listingInitialState,
  listingReducer,
} from "../../../../shared/reducers/listingReducer";
import axios from "../../../../../config/axios";
import {
  ContainerLoading,
  LocationLoading,
} from "../../../../shared/loading/Loading";
import BlockErrorMessage from "../../../../shared/errorMessages/BlockErrorMessage";
import { useParams } from "react-router-dom";
function ListingComponent() {
  const array = [];
  const button_style = {
    width: "120px",
    height: "41px",
  };
  const container_style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  let newDirectory = Object.values(
    array.reduce((acc, item) => {
      if (!acc[item.day])
        acc[item.day] = {
          day: item.day,
          title: [],
        };
      acc[item.day].title.push(item.title);
      return acc;
    }, {})
  );


  let subtitle;
  const imagePerRow = 4;


  const handleMoreImage = () => {

    setScreen((prevState) => ({
      ...prevState,
      imageLoading: true,
    }));
    setTimeout(() => {
      setScreen((prevState) => ({
        ...prevState,
        next: screen.next + imagePerRow,
        imageLoading: false,
      }));
    }, 2000);
  };

  const [screen, setScreen] = useState({
    items: {},
    content: {},
    imageLoading: false,
    next: imagePerRow
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [displayState, displayDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const { slug, url } = useParams();
  const loadDisplay = async () => {
    displayDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(
        `event_settings_content`
      ); //
      displayDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

    } catch (error) {
      displayDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const loadItems = async () => {
    const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));


    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`activities/speakers/${slug}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data.items,
      });

    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }

  };
  useEffect(() => {
    loadItems();
    //  loadDisplay();
  }, []);
  return (
    <>
      {listingState.isLoading && <ContainerLoading />}

      {listingState.status !== "fetching" && (
        <>
          {listingState.error && listingState.status === "fetchFailed" ? (
            <BlockErrorMessage error={listingState.error} />
          ) : (
            <section className="my-1">
              {listingState.data.length > 0 && (
                <h2 className="heading_text  px-0 text-primaryClient pb-5 ms-0">
                  {screen.items && screen.items.menuSpeakersText}
                </h2>
              )}
              {listingState.data.length <= 0 && (
                <div className="noItemsFound">
                  <BiErrorCircle className="errorIcon" />
                  <h1>No Speakers Found!</h1>
                  <p>Please try again after some time.</p>
                </div>
              )}

              <Row className="px-0 mt-3 mx-auto">
                {screen.content && screen.content.activity &&
                  screen.content.activity.speakerSettings && screen.content.activity.speakerSettings.template === "Row" ? (
                  <>
                    {listingState.data
                      ?.slice(0, screen.next)
                      .map((speakers, index) => {
                        return (
                          <React.Fragment key={Math.random()}>

                            <SpeakerLayoutList
                              speaker={speakers}
                            />

                          </React.Fragment>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {listingState.data
                      ?.slice(0, screen.next)
                      .map((speakers, index) => {
                        return (
                          <React.Fragment key={Math.random()}>
                            <SpeakersModal
                              speaker={speakers}
                            />

                          </React.Fragment>
                        );
                      })}

                  </>
                )}

                <div className="mx-auto d-flex align-items-center justify-content-center">
                  {screen.imageLoading === true && (
                    <span class="loader mt-4"></span>
                  )}
                </div>
                {screen.next < listingState.data?.length > 0 &&
                  screen.imageLoading != true && (
                    <a
                      title="View more"
                      className="mb-0 mx-auto mt-4 text-decoration-none"
                      onClick={handleMoreImage}
                    >
                      <BorderBlue
                        name={screen.items && screen.items.buttonLoadMore}
                      style={button_style}
                        container={container_style}
                      />
                    </a>
                  )}
              </Row>
            </section>
          )}
        </>
      )}
    </>
  );
}

export default ListingComponent;
