import React, { useState, useEffect, useReducer } from "react";
import "./header.scss";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generalFeatureAllowed } from "../../../Helper/featuresHelper";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {},
    show: false,
  })
  const { slug, url } = useParams();
  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(true);
  }
  const activeDay = JSON.parse(localStorage.getItem(`activeAgendaDay${slug}`));
  const pathname = useLocation();
  useEffect(() => {

    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      //setItems(items);
      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }


    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      //setContent(content);
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }

  }, []);
  const clientFeatureAllowed = (feature) => {
    const content = JSON.parse(localStorage.getItem("clientDetails"));

    if (feature) {
      if (
        content &&
        content.packageFeatures &&
        content.packageFeatures[feature] === "1"
      ) {

        return true;
      }
    }

    return false

  };
  const showClientLogo = screen.content && screen.content.activity && screen.content.activity.regPageCustomization && screen.content.activity.regPageCustomization.showClientLogo;
  return (
    <>

      {screen.items &&
        <Navbar
          collapseOnSelect
          sticky="top"
          className={`${i18n.language === "ar"
            ? "headerContainer arabic"
            : "headerContainer english"
            } `}
        >

          <Container fluid className="topNav">
            {showClientLogo === "Show Client Logo" ? <>
            {screen.content && screen.content.activity && screen.content.activity.client && screen.content.activity.client.logo ?
              <Link to={slug != undefined && url != undefined ? `/${slug}/${url}` : slug != undefined ? `/${slug}`: `/`}>
            <div className="logo" id="logo">
              <img
                id="logo"
                src={screen.content.activity.client.logo ? process.env.REACT_APP_BACKEND_URL + "public/" + screen.content.activity.client.logo : null}
                className="appLogo"
                alt=""
                title={i18n.language === "ar" ? "الصفحة الرئيسية" : "Home page"}
                loading="lazy"
              />
            </div>
          </Link> : <Link to={slug != undefined && url != undefined ? `/${slug}/${url}` : slug != undefined ? `/${slug}`: `/`}>
            <div className="logo" id="logo">
              <img
                id="logo"
                src=""
                className="appLogo"
                alt=""
                title={i18n.language === "ar" ? "الصفحة الرئيسية" : "Home page"}
                loading="lazy"
              />
            </div>
          </Link>}
            </>:showClientLogo === "Show Different Logo" ?<>
            {screen.content && screen.content.activity && screen.content.activity.regPageCustomization && screen.content.activity.regPageCustomization.clientLogo ?
              <Link to={slug != undefined && url != undefined ? `/${slug}/${url}` : slug != undefined ? `/${slug}`: `/`}>
            <div className="logo" id="logo">
              <img
                id="logo"
                src={screen.content.activity.regPageCustomization.clientLogo ? process.env.REACT_APP_BACKEND_URL + "public/" + screen.content.activity.regPageCustomization.clientLogo : null}
                className="appLogo"
                alt=""
                title={i18n.language === "ar" ? "الصفحة الرئيسية" : "Home page"}
                loading="lazy"
              />
            </div>
          </Link> : <Link to={slug != undefined && url != undefined ? `/${slug}/${url}` : slug != undefined ? `/${slug}`: `/`}>
            <div className="logo" id="logo">
              <img
                id="logo"
                src=""
                className="appLogo"
                alt=""
                title={i18n.language === "ar" ? "الصفحة الرئيسية" : "Home page"}
                loading="lazy"
              />
            </div>
          </Link>}
            </>:null}
           

          <Nav className={`${show ? "mobile-nav" : "list"}`}>
            <ul>
              <li title={screen.items.menuHomeText}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "link active" : "link"
                  }
                  to={slug != undefined && url != undefined ? `/${slug}/${url}` : slug != undefined ? `/${slug}`: `/`}
                >
                  {screen.items.menuHomeText}
                </NavLink>
              </li>
              {screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.description &&
                <li title={screen.items.menuAboutText}>
                  <NavLink className={`link`}  to={slug != undefined && url != undefined ? `/about/${slug}/${url}` : slug != undefined ? `/about/${slug}`: `/`}>
                    {screen.items.menuAboutText}
                  </NavLink>
                </li>}
                {console.log(generalFeatureAllowed("agenda") , clientFeatureAllowed('agenda'))}
              {generalFeatureAllowed("agenda") && clientFeatureAllowed('agenda') &&
                <li title={screen.items.menuAgendaText}>
                  <NavLink className={`link`} to={slug != undefined && url != undefined ? `/agenda/${slug}/${url}/${activeDay ? activeDay : "1"}` :slug != undefined ? `/agenda/${slug}/${activeDay ? activeDay : "1"}` : `/agenda/${activeDay ? activeDay : "1"}`}>
                    {screen.items.menuAgendaText}
                  </NavLink>
                </li>
              }
              {generalFeatureAllowed("speakers") && clientFeatureAllowed('speakers') &&
                <li title={screen.items.menuSpeakersText}>
                  <NavLink className={`link`} to={slug != undefined && url != undefined ? `/speakers/${slug}/${url}` :slug != undefined ? `/speakers/${slug}` : `/speakers`}>
                    {screen.items.menuSpeakersText}
                  </NavLink>
                </li>
              }

              {((generalFeatureAllowed("sponsors") || generalFeatureAllowed("partners")) || (clientFeatureAllowed("sponsors") || clientFeatureAllowed("partners"))) ?
                <li title={screen.items.menuSponsorsPartnersText}>
                  <NavLink className={`link`} to={slug != undefined && url != undefined ? `/sponsors-partners/${slug}/${url}`: slug != undefined ? `/sponsors-partners/${slug}` : `/sponsors-partners`}>
                    {generalFeatureAllowed("sponsors") && generalFeatureAllowed("partners") && clientFeatureAllowed("sponsors") && clientFeatureAllowed("partners") ?
                      screen.items && screen.items.menuSponsorsPartnersText : generalFeatureAllowed("partners") && clientFeatureAllowed("partners") ? screen.items && screen.items.sectionPartnersText : generalFeatureAllowed("sponsors") && clientFeatureAllowed("sponsors") && screen.items && screen.items.sectionSponsorsText}
                    {/* {screen.items.menuSponsorsPartnersText} */}
                  </NavLink>
                </li> : null}
              {screen.items.buttonRegisterNow &&
                <li className="registerButton pe-0">
                  <Link to={slug != undefined && url != undefined ? `/registration/${slug}/${url}`:slug != undefined ? `/registration/${slug}` : `/registration`}>
                    {pathname.pathname === (slug != undefined && url != undefined ? `/registration/${slug}/${url}`:slug != undefined ? `/registration/${slug}` : `/registration`) ? (
                      <Button
                        title="Click to register"
                        className={`BorderBlue acctive `}
                      >
                        <NavLink className={`link`} to={slug != undefined && url != undefined ? `/registration/${slug}/${url}`:slug != undefined ? `/registration/${slug}` : `/registration`}>
                          {screen.items.buttonRegisterNow}
                        </NavLink>
                      </Button>
                    ) : (
                      <Button
                        title="Click to register"
                        className={`BorderBlue`}
                      >
                        {screen.items.buttonRegisterNow}
                      </Button>
                    )}
                  </Link>
                </li>}
                {screen.items.buttonRegisterNow &&
                <li className="showOnMobileTabletOnly">
                  <Link to={slug != undefined && url != undefined ? `/registration/${slug}/${url}`:slug != undefined ? `/registration/${slug}` : `/registration`}>
                    
                     
                        <NavLink className={`link`} to={slug != undefined && url != undefined ? `/registration/${slug}/${url}`:slug != undefined ? `/registration/${slug}` : `/registration`}>
                          {screen.items.buttonRegisterNow}
                        </NavLink>
                    
                    
                  </Link>
                </li>}
              {/* <li className="language">
                  <LanguageSelector />
                </li> */}
            </ul>
          </Nav>

          <div className="toggleBtn">
            <>
              {show ? (
                <svg
                  onClick={() => setShow(false)}
                  id="close_menu_icon"
                  data-name="close menu icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50.52"
                  height="32.63"
                  viewBox="0 0 38.106 38.106"
                >
                  <rect
                    id="Rectangle_35"
                    data-name="Rectangle 35"
                    width="50.515"
                    height="3.375"
                    transform="translate(2.386) rotate(45)"
                    fill="#019de1"
                  />
                  <rect
                    id="Rectangle_81"
                    data-name="Rectangle 81"
                    width="50.515"
                    height="3.375"
                    transform="translate(38.106 2.387) rotate(135)"
                    fill="#019de1"
                  />
                </svg>
              ) : (
                <svg
                  onClick={() => handleShow()}
                  id="menu_icon"
                  className="menu_icon"
                  data-name="menu icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36.515"
                  height="32.625"
                  viewBox="0 0 50.515 32.625"
                  fill="#019de1"
                >
                  <rect
                    id="Rectangle_34"
                    className="Rectangle_34"
                    data-name="Rectangle 34"
                    width="50.515"
                    height="3.375"
                  />
                  <rect
                    id="Rectangle_35"
                    className="Rectangle_34"
                    data-name="Rectangle 35"
                    width="50.515"
                    height="3.375"
                    transform="translate(0 14.625)"
                  />
                  <rect
                    id="Rectangle_36"
                    className="Rectangle_34"
                    data-name="Rectangle 36"
                    width="50.515"
                    height="3.375"
                    transform="translate(0 29.25)"
                  />
                </svg>
              )}
            </>
          </div>
        </Container>
        </Navbar >}
    </>
  );
};

export default Header;
