import React, { createContext, useState } from 'react';
export const ClientContext = createContext({});
const ClientContextProvider = (props) => {
    const [client, setClient] = useState({
        data: null,
    })
    return (
        <ClientContext.Provider value={{ client, setClient }}>
            {props.children}
        </ClientContext.Provider>
    );
};
export default ClientContextProvider;

