import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // headers: {
    //     'Authorization': (user && user.token) ? `Bearer ${user.token}` : ''
    // }
});

axiosInstance.interceptors.request.use((req) => {

    let user = localStorage.getItem('user');

    if (user && user !== undefined && user !== 'undefined') {
        user = JSON.parse(user)
        if (user && user.token) {
            req.headers.Authorization = ((user && user.token) ? `Bearer ${user.token}` : '');
        }
    }
    return req;
})

axiosInstance.interceptors.response.use((res) => {
    return res;
}, (error) => {
    // console.error("in axios ===> ", error.response);
    const status = error.response ? error.response.status : 500;
    if (status && status === 403) {
        localStorage.clear();
        window.location.href = "/login";
        //Navigate('/signin');
        //userAuthDispatch({ type: "LOGOUT_SUCCESS" })
    }
    return Promise.reject(error);
})

export default axiosInstance;