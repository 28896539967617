
export const detailsInitialState = {
    data: [],
    isFetching: false,
    hasError: false,
    error: '',
};

export const detailsReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                hasError: true,
                isFetching: false,
                error: action.payload
            };
        default:
            return state;
    }
};