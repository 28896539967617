import axios from "../config/axios";
export const featureAllowed = (custom) => {
    const content = JSON.parse(sessionStorage.getItem("content"));
    // console.log(content,"====");
    if (custom === "showBannerOnHomePage") {
        if (
            content &&
            content.activity &&
            content.activity.regPageCustomization && content.activity.regPageCustomization.homePageBanner &&
            content.activity.regPageCustomization[custom] === "1"
        ) {

            return true;
        }
    }
    else if (custom === "showBannerOnSubPage") {
        if (
            content &&
            content.activity &&
            content.activity.regPageCustomization && content.activity.regPageCustomization.subPageBanner &&
            content.activity.regPageCustomization[custom] === "1"
        ) {

            return true;
        }
    }
    else if (custom === "showAgendaOnHomePage") {
        if (generalFeatureAllowed("agenda") &&
            content &&
            content.activity &&
            content.activity.regPageCustomization &&
            content.activity.regPageCustomization[custom] === "1"
        ) {

            return true;
        }

    }
    else if (custom === "showSpeakersOnHomePage") {
        if (generalFeatureAllowed("speakers") &&
            content &&
            content.activity &&
            content.activity.regPageCustomization &&
            content.activity.regPageCustomization[custom] === "1"
        ) {

            return true;
        }

    }
    else if (custom === "showSponsorsPartnersOnHomePage") {
        if ((generalFeatureAllowed("sponsors") || generalFeatureAllowed("partners")) &&
            content &&
            content.activity &&
            content.activity.regPageCustomization &&
            content.activity.regPageCustomization[custom] === "1"
        ) {

            return true;
        }

    }
    else {
        if (
            content &&
            content.activity &&
            content.activity.regPageCustomization &&
            content.activity.regPageCustomization[custom] === "1"
        ) {

            return true;
        }
        return false
    }

};

export const getStatusByDay = async (dayValue, slug) => {
    const content = JSON.parse(localStorage.getItem("content"));
    const eventDays = content && content.activity && content.activity.days
    const dayEntry = eventDays.find(day => day.day === dayValue);


    if (dayEntry.status === "1") {

        return true;
    }
    else {
        return false
    }
    // try {
    //     const res = await axios.get(`activities/content/${slug}`);
    //     localStorage.setItem("currentActivity", JSON.stringify({ clientId: res.data.activity && res.data.activity.clientId, activityId: res.data.activity && res.data.activity._id, slug: slug }));
    //     localStorage.setItem("content", JSON.stringify(res.data));

    //     const content = res.data;
    //     const eventDays = content && content.activity && content.activity.days
    //     const dayEntry = eventDays.find(day => day.day === dayValue);


    //     if (dayEntry.status === "1") {

    //         return true;
    //     }
    //     else {
    //         return false
    //     }



    // } catch (error) {
    //     // Handle error
    //     return false
    // }

};
export const countDaysWithStatus1 = () => {
    const content = JSON.parse(localStorage.getItem("content"));
    const schedule = content && content.activity && content.activity.days
    let count = 0;

    // Loop through each day in the schedule
    schedule.forEach(day => {
        // Check if the status is equal to 1
        if (day.status === "1") {
            // If yes, increment the count
            count++;
        }
    });

    // Return the final count
    return count;
}
export const findLargestDateOver = () => {
    const content = JSON.parse(localStorage.getItem("content"));
    const schedule = content && content.activity && content.activity.days
    let largestDate = null;

    schedule.forEach(day => {
        if (day.date) {
            const currentDate = new Date(day.date);

            if (!largestDate || currentDate > largestDate) {
                largestDate = currentDate;
            }
        }
    });
    const today = new Date();
    const eventLargestDay = new Date(largestDate)
    const isLargestDateGreaterThanToday = eventLargestDay < today;
    return isLargestDateGreaterThanToday
}
export const findPackageExpired = () => {
    const content = JSON.parse(localStorage.getItem("content"));
    var endDate = new Date(content.activity && content.activity.client && content.activity.client.package && content.activity.client.package.endDate);
    var today = new Date();

    if (endDate > today) {
        return false;
    } else if (endDate < today) {
        return true
    } else {
        return false;
    }
}
export const generalFeatureAllowed = (feature) => {
    const content = JSON.parse(sessionStorage.getItem("content"));
  
    if (feature) {
        if (
            (content &&
                content.activity &&
                content.activity.allowedFeatures &&
                content.activity.allowedFeatures[feature] === "1") && (content &&
                    content.activity &&
                    content.activity.activityFeatures &&
                    content.activity.activityFeatures[feature] === "1")
        ) {

            return true;
        }
    }

    return false

};
export const clientFeatureAllowed = (feature) => {
    const content = JSON.parse(sessionStorage.getItem("content"));
    const client = content && content.activity && content.activity.client && content.activity.client.packageFeatures

    if (feature) {
        if (client[feature] === "1"
        ) {

            return true;
        }
    }

    return false

};
export const removeAndCapitalizeEachWord = (str) => {
    return str.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}