import React, { useState, useEffect } from "react";
import BodyLayout from "../../layout/BodyLayout";
import { useTranslation } from "react-i18next";
import "./registrationPage.scss";
import { RegistrationForm } from "./RegistrationForm";
import { RegistrationFormBackup2 } from "./RegistrationFormBackup2";
import { removeAndCapitalizeEachWord } from "../../../Helper/featuresHelper";
import { useParams } from "react-router-dom";
const RegistrationPage = () => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);

  const content = JSON.parse(localStorage.getItem("content"));
  const { slug } = useParams();
  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
    document.title = `${removeAndCapitalizeEachWord(slug)} | Registration`;
  }, [slug]);
  return (
    <BodyLayout>
      <div className="paddingContainerRegister">
        <div className={`contentContainerRegister ${i18n.language === "ar" ? "arabic":""} `}>
          {/* <RegistrationFormBackup2/> */}
          <RegistrationForm />
        </div>
      </div>
    </BodyLayout>
  );
};

export default RegistrationPage;
