import { ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import ReactFancyBox from "react-fancybox";
import { Row, Col } from "react-bootstrap";
import { GrFormClose } from "react-icons/gr";
import { FiUpload } from "react-icons/fi";
import { useTranslation } from "react-i18next";
const CMSFormImage = (props) => {
  const { name, label, form, required,description } = props;
  let initialImageURL =
    form.values[name] != "null"
      ? process.env.REACT_APP_BACKEND_URL + "public/" + form.values[name]
      : null;
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(initialImageURL);
  const [fileName, setFileName] = useState("Choose file");
  useEffect(() => {
    if (!image || !image.name) return;
    setImageURL(URL.createObjectURL(image));
    setFileName(image.name);
  }, [image]);

  const onImageChange = (e) => {
    if (e.target && e.target.files[0]) {
      setImage(e.target.files[0]);
      form.setFieldValue(name, e.target.files[0]);
    } else {
      clearImage();
    }
  };
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  const clearImage = async () => {
    setImage(null);
    await form.setFieldValue(name, null, true);
    setImageURL(null);
    setFileName(null);
    form.setFieldTouched(name, true);
  };

  return (
    <>
      <div className="form-group ">
        <label
          htmlFor={name}
          className={`${i18n.language === "ar" ? "arabic pe-2" : ""} ${required == true ? "mandatory_field ps-2 " : " "
        } ps-1 mb-2`}
        >
          {" "}
          {label}
        </label>
        {description &&
        <p
          className="text-primaryBlack ps-1"
          style={{
            fontStyle: "italic",
            fontSize: 12,
            color: "#555",
            marginBottom: "5px",
          }}
        >
          ({description})
        </p>}
        <div
          className={`input-group ${
            form.touched[name] && form.errors[name] ? "is-invalid" : ""
          }`}
        >
          {i18n.language === "ar" ? (
            <>
              <label class="custom-file-input-ar" for="Upload" />{" "}
              <input
                id="Upload"
                type="file"
                multiple="multiple"
                name="_photos"
                accept="image/*"
                className={`form-control custom-file-input-ar ${
                  i18n.language === "ar" ? "arabic" : ""
                }  ${
                  form.touched[name] && form.errors[name] ? "is-invalid" : " "
                }`}
                onChange={(e) => onImageChange(e)}
                onBlur={form.handleBlur}
                style={{ visibility: "hidden" }}
              ></input>
            </>
          ) : (
            <>
              <label class="custom-file-input" for="Upload" />{" "}
              <input
                id="Upload"
                type="file"
                multiple="multiple"
                name="_photos"
                accept="image/*"
                className={`form-control custom-file-input ${
                  i18n.language === "ar" ? "arabic" : ""
                }  ${
                  form.touched[name] && form.errors[name] ? "is-invalid" : " "
                }`}
                onChange={(e) => onImageChange(e)}
                onBlur={form.handleBlur}
                style={{ visibility: "hidden" }}
              ></input>
            </>
          )}

          <div className="custom-file customInput">
            {/* <input
              type="file"
              id="image"
              aria-describedby="inputGroupFileAddon01"
              accept="image/*"
              className={`form-control custom-file-input ${
                i18n.language === "ar" ? "arabic" : ""
              }  ${
                form.touched[name] && form.errors[name] ? "is-invalid" : " "
              }`}
              onChange={(e) => onImageChange(e)}
              onBlur={form.handleBlur}
            /> */}
            <label
              className={`required ${i18n.language === "ar" ? "arabic" : ""} ${
                form.touched[name] && form.errors[name]
                  ? "is-invalid custom-file-label"
                  : "custom-file-label"
              }`}
              htmlFor="inputGroupFile01"
            >
              {form.values[name]
                ? items.formImageUploaded
                : items.formTextUploadImage}
            </label>
            {/* <div className="uploadIcon">
              <FiUpload />
            </div> */}
          </div>
        </div>
     
        {form.errors[name] && (
          <span id={`${name}-error"`} className="error  errorMessage">
            <ErrorMessage name={name} /> 
          </span>
        )}
      </div>

      {form.values[name] != null && (
        <Row>
          <Col xs={6} md={2} lg={1} className="pageContent mt-3">
            {/* <Image src={imageURL} thumbnail /> */}
            <div
              className={`iconDiv ${i18n.language === "ar" ? "arabic" : ""}`}
            >
              <GrFormClose onClick={() => clearImage()} />
              {/* <i
                className=" fas fa-times position-absolute"
                onClick={() => clearImage()}
              /> */}
            </div>

            <ReactFancyBox thumbnail={imageURL} image={imageURL} />
          </Col>
          <Col></Col>
        </Row>
      )}
    </>
  );
};

export default CMSFormImage;
