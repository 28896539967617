import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
const CMSFormTelephone = ({
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  description,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  validate,
  as,
  md,
  rows,
  form,
  controlId,
  options,
  disabled,
  readOnly,
  ref,
  errorState,
  setErrorState,

  ...props
}) => {
  const formatPhoneNumberOutput = (
    isValid,
    newNumber,
    countryData,
    fullNumber,
    isExtension
  ) => {
    return fullNumber.replace(/(\s|-)/g, "");
  };
  const [state, setstate] = useState();
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  const [preferredCountries, setPreferredCountries] = useState(["lb"]);
  function fetchDialCode(initialValues, setInitialValues) {
    axios
      .get(
        "https://ipapi.co/json/?key=o9Bmy7K51dx6ChF7iKA8ndWr4uSdmiJsvtjOtLE2OqOukoYYj3"
      )
      .then((response) => {
        let data = response.data;
        setstate(data.country.toLowerCase());
        // setTimeout(() => {
        //   let aaa = (state || "").toLowerCase();
        //   setstate(aaa);
        // }, 1000);
      })
      .catch((error) => {
        //console.log(error);
      });
  }
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (!value) {
      fetchDialCode();
    }
  }, []);

  // https://ipapi.co/json/?key=o9Bmy7K51dx6ChF7iKA8ndWr4uSdmiJsvtjOtLE2OqOukoYYj3

  const [telephoneValid, setTelephoneValid] = useState(true);
  const errorMap = [
    "Invalid Phone Number",
    "Invalid Country Code",
    "Please enter complete number",
    "Please enter valid number",
    "Invalid Phone Number",
  ];
  const setValidity = (valid) => {
    setTelephoneValid(valid);
  };
  const setError = (valid, setFieldError, value) => {
    valid ? setErrorState(false) : setErrorState(true);
    if (value && value.length > 1) {
      setFieldError(name, "Please enter a valid number");
    }

  };
  // process number into string with area code for submission
  const processNumber = (isValid, phone, country) => {
    return `${phone}`;
  };
  return (
    <>
      <div className="form-group">
        <label
          htmlFor={name}
          className={`${i18n.language === "ar" ? "arabic pe-2" : ""} ${required == true ? "mandatory_field ps-2 " : " "
            } ps-1 mb-0`}
        >
          {" "}
          {label}
        </label>
        <p
          className="ps-1"
          style={{
            fontStyle: "italic",
            fontSize: 11,
            color: "#555",
            marginBottom: "2px",
            marginTop: "1px",
            minHeight: 16,
          }}
        >{description &&
          (description)}
        </p>
        <Field
          name={name}
          className={`form-control ${i18n.language === "ar" ? "arabic" : ""} ${telephoneValid ? "" : "is-invalid"
            } customInput ${form.touched[name] && form.errors[name] ? "is-invalid" : ""}`}
        >
          {({
            field: { value },
            form: {
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              setFieldError,
            },
          }) => (
            <Form.Group md={md} controlId={controlId}>
              <IntlTelInput
                {...props}
                containerClassName={`intl-tel-input ${i18n.language === "ar" ? "arabic" : ""
                  }`}
                defaultCountry={state ? state : "ae"}
                inputClassName={`form-control  ${i18n.language === "ar" ? "arabic" : ""
                  }  customInput ${((form.touched[name] && form.errors[name]) || !telephoneValid) ? "is-invalid" : ""
                  }`}
                label="telephone"
                defaultValue={value}
                // value={value}
                separateDialCode

                nationalMode={false}
                disabled={false}
                autoHideDialCode={false}
                fieldId={name}
                fieldName={name}
                format={false}
                autoPlaceholder={false}
                geoIpLookup={state}
                utilsScript="libphonenumber.js"
                preferredCountries={preferredCountries}
                onPhoneNumberBlur={(value) => {
                  setFieldTouched(name, true);
                  //setValidity(isValid);
                  //setError(isValid, setFieldError)

                }}
                onPhoneNumberChange={(isValid, phone, country, fullNumber) => {

                  setFieldValue(name, fullNumber)
                  if (phone.length > 0) {
                    setValidity(isValid);
                  }
                  if (phone.length <= 0) {
                    setValidity(true);
                  }


                  setError(isValid, setFieldError, form.values[name])


                }}
              />
            </Form.Group>
          )}
        </Field>
      </div>

      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error errorMessage">
          <ErrorMessage name={name} />
        </span>
      )}
      {form.values[name] &&
        form.touched[name] &&
        !form.errors[name] &&
        !telephoneValid && (
          <span id={`${name}-error"`} className="error errorMessage">
            {items.formErrorMobileValid}
          </span>
        )}
    </>
  );
};

export default CMSFormTelephone;
