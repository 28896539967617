import React, { useState, useEffect, } from "react";

import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PiMicrophoneStageFill } from "react-icons/pi";
function AgendaForSpeaker(props) {
  const array = [];
  const [state, setState] = useState({
    loading: true,
    active: "1",
    agenda: props.sessions,
    speakers: [],
    items: {}
  });

  const content = JSON.parse(localStorage.getItem("content"));
  const { i18n } = useTranslation();
  // const [agendaState, agendaDispatch] = useReducer(
  //   listingReducer,
  //   listingInitialState
  // );
  // const loadAgenda = async () => {
  //   agendaDispatch({
  //     type: "FETCH_REQUEST",
  //   });
  //   try {
  //     const res = await axios.get(`event_agenda`); //
  //     agendaDispatch({
  //       type: "FETCH_SUCCESS",
  //       payload: res.data,
  //     });
  //     setState({
  //       ...state,
  //       agenda: res.data,
  //     });
  //     
  //   } catch (error) {
  //     agendaDispatch({
  //       type: "FETCH_FAILURE",
  //       payload: error,
  //     });
  //   }
  // };
  // useEffect(() => {
  //   loadAgenda();
  // }, []);
  // const starttime = state.agenda.map(function (item) {
  //   if (item.speaker_id === props.id) return array.push(item);
  // });

  const day = props.sessions.reduce((acc, d) => {
    if (Object.keys(acc).includes(d.day && d._id)) return acc;

    acc[d.day] = state.agenda.filter(
      (g) => g.day === d.day
    );
    return acc;
  }, {});
  delete day.null;

  let newDirectory = Object.values(
    state.agenda.reduce((acc, item) => {
      if (!acc[item.day])
        acc[item.day] = {
          day: item.day,
          title: [],
          titleArabic: [],
        };
      acc[item.day].title.push(item.title);
      acc[item.day].titleArabic.push(item.titleArabic);
      return acc;
    }, {})
  );
  const { slug, url } = useParams();
  const length = newDirectory.length;
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      // setItems(items);
      setState((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
  }, []);
  return (
    <>
      {newDirectory.filter((e) => e.day !== undefined).length > 0 &&
        <Row>
          <Col className="mx-3 mt-2"><h6 className="bold">Session(s)</h6></Col>
        </Row>
      }
      {/* {agendaState.isLoading && <ContainerLoading />} */}
      <div className="px-0 listLayoutAgenda">
        <div className="p-1 ">

          {/* {props.sessions && props.sessions.length > 0 && props.sessions.sort((a, b) => a.sortOrder - b.sortOrder).map((item, index) => {
            return (
              <>
                <div className="box px-2 mb-1 pt-1">
                  <Link
                    to="/agenda"
                    onClick={() =>
                      localStorage.setItem(
                        "activeAgendaDay",
                        JSON.stringify(item.day)
                      )
                    }
                    className="linkDisable"
                  >
                    <h5 className="fs-9 text-primaryBlack mb-1 bold">
                      {items.agendaDayText} {item.day}
                    </h5>

                    {day[item.day] && day[item.day].map((day, index) => {
                      return (
                        <>

                          {day.title} <br />
                          
                        </>
                      )
                    })}
                  
                  </Link>
                  {
                      delete day[item.day]
                    }
                </div>
setState
              </>
            )
          })} */}

          {newDirectory.filter((e) => e.day !== undefined).map((item) => (
            <div className="box px-2 mb-1 pt-1">
              <Link
                to={slug != undefined && url != undefined ? `/agenda/${slug}/${url}/${item.day}` : slug != undefined ? `/agenda/${slug}/${item.day}` : `/agenda/${item.day}`}
                onClick={() =>
                  localStorage.setItem(
                    `activeAgendaDay${slug}`,
                    JSON.stringify(item.day)
                  )
                }
                className="linkDisable"
              >
                {content && content.activity && content.activity.days && content.activity.days.length > 1 &&
                  <h5 className="fs-9 text-primaryBlack mb-1 bold">
                    {state.items && state.items.agendaDayText} {item.day}
                  </h5>}
                {day[item.day] && day[item.day].map((day, index) => {
                  if (day._id) return (
                    <>

                      <Row
                        style={{ minHeight: "25px", height: "auto" }}
                      >
                        <Col
                          xs={2}
                          className={`time ${i18n.language === "ar" ? "ps-0" : "pe-0"
                            }`}
                        >
                          <p className="fs-9  mb-1">
                            {day.startTime}
                          </p>
                        </Col>
                        <Col className="ps-0">
                          <p
                            className={
                              day.isHeading == "1"
                                ? "ps-2 ps-xl-2 fs-9 clientPrimary mb-1"
                                : "ps-2 ps-xl-2  fs-9 mb-1"
                            }
                          >
                            {i18n.language === "ar"
                              ? day.titleArabic
                              : day.title} <span class="moderatingText">{day.isModerator === "1" ? <>(<PiMicrophoneStageFill className="mx-1 fs-8" /> Moderating this session )</> : null}</span>
                          </p>


                        </Col>
                      </Row>

                    </>
                  )
                })}

              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default AgendaForSpeaker;
