import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import BodyLayout from "../../layout/BodyLayout";
import ListingComponent from "./speakerPartials/listing/ListingComponent";
import { useTranslation } from "react-i18next";
import { featureAllowed, generalFeatureAllowed, removeAndCapitalizeEachWord } from "../../../Helper/featuresHelper";
import { useNavigate, useParams } from "react-router-dom";
import ErrorPage from "../error/ErrorPage";

function SpeakerPage() {
  const { t, i18n } = useTranslation();

  const { slug, url } = useParams();
  const content = JSON.parse(localStorage.getItem("content"));
  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
     document.title = `${removeAndCapitalizeEachWord(slug)} | Speakers`;
   }, [slug]);
  return (
    <>
      {generalFeatureAllowed("speakers") ? <BodyLayout>
        <div className="paddingContainerSpeakerPage">
          <div
            className={`contentContainerSpeakerPage ${i18n.language === "ar" ? "arabic" : ""
              } `}
          >

            <ListingComponent />
          </div>
        </div>
      </BodyLayout> : <ErrorPage slug={slug} url={url} />}
    </>

  );
}

export default SpeakerPage;
