import axios from "../config/axios";

export const isValidEmail = (email) => {

    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (EMAIL_REGEX.test(email)) {
        return true;
    }
    return false;
}

export const checkEmailExist = async (email) => {
    const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));
    if (currentActivity != null) {

        if (!email || !isValidEmail(email))
            return false;

        try {
            const res = await axios.post(
                `activities/check-email-exists/${currentActivity.activityId}`,
                { email: email }
            )

            return res.data.status;
        } catch (error) {
            return false;
        }
    }
};
