// import Header from "../../components/header/Header";
import React, { useEffect, useState } from "react";
import "./sponsorsPartners.scss";
import BodyLayout from "../../layout/BodyLayout";
import { useTranslation } from "react-i18next";
import { BiErrorCircle } from "react-icons/bi";
import SponsorListing from "./sponsorListing/SponsorListing";
import PartnerListing from "./partnerListing/partnerListing";
import { useParams } from "react-router-dom";
import {
  generalFeatureAllowed,
  removeAndCapitalizeEachWord,
} from "../../../Helper/featuresHelper";
import ErrorPage from "../error/ErrorPage";

const SponsorsPartners = () => {
  const { t, i18n } = useTranslation();

  const [content, setContent] = useState({});
  const [sponsorPartner, setSponsorPartner] = useState({
    sponsor: null,
    partner: null,
  });
  const { slug, url } = useParams();
  let content1 = JSON.parse(sessionStorage.getItem("content"));

  useEffect(() => {
    if (content1) {
      setContent(content1);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
    document.title = `${removeAndCapitalizeEachWord(
      slug
    )} | Sponsors & Partners`;
    content1 = JSON.parse(sessionStorage.getItem("content"));
  }, [slug]);
  return (
    <>
      {generalFeatureAllowed("sponsors") ||
        generalFeatureAllowed("partners") ? (
        <BodyLayout>
          <div className="paddingContainerSponsorPage">
            <div
              className={`contentContainerSponsorPage ${i18n.language === "ar" ? "arabic" : ""
                } `}
            >
          
              {content.activity &&
                content.activity.allowedFeatures &&
                content.activity.allowedFeatures.sponsors === "1" && (
                  <SponsorListing
                    state={sponsorPartner}
                    setState={setSponsorPartner}
                  />
                )}
              {content.activity &&
                content.activity.allowedFeatures &&
                content.activity.allowedFeatures.partners === "1" && (
                  <PartnerListing
                    state={sponsorPartner}
                    setState={setSponsorPartner}
                  />
                )}
                    {sponsorPartner.sponsor != null ||
                sponsorPartner.partner != null ? (
                <>
                  {content.activity &&
                    content.activity.allowedFeatures &&
                    content.activity.allowedFeatures.sponsors === "1" &&
                    content.activity.allowedFeatures.partners === "1" &&
                    sponsorPartner.sponsor <= 0 &&
                    sponsorPartner.partner <= 0 ? (
                    <div className="noItemsFound">
                      <BiErrorCircle className="errorIcon" />
                      <h1>{`No ${content.activity &&
                          content.activity.allowedFeatures &&
                          content.activity.allowedFeatures.sponsors === "1" &&
                          content.activity.allowedFeatures.partners === "1"
                          ? "Sponsors/ Partners"
                          : content.activity &&
                            content.activity.allowedFeatures &&
                            content.activity.allowedFeatures.sponsors === "1"
                            ? "Sponsors"
                            : content.activity &&
                              content.activity.allowedFeatures &&
                              content.activity.allowedFeatures.partners === "1"
                              ? "Partners"
                              : "Records"
                        }  Available!`}</h1>
                      <p>Please try again later.</p>
                    </div>
                  ) : content.activity &&
                    content.activity.allowedFeatures &&
                    content.activity.allowedFeatures.sponsors === "1" &&
                    sponsorPartner.sponsor <= 0 ? (
                    <div className="noItemsFound">
                      <BiErrorCircle className="errorIcon" />
                      <h1>{`No Sponsors Available!`}</h1>
                      <p>Please try again later.</p>
                    </div>
                  ) : content.activity &&
                    content.activity.allowedFeatures &&
                    content.activity.allowedFeatures.partners === "1" &&
                    sponsorPartner.partner <= 0 ? (
                    <div className="noItemsFound">
                      <BiErrorCircle className="errorIcon" />
                      <h1>{`No Partners Available!`}</h1>
                      <p>Please try again later.</p>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </BodyLayout>
      ) : (
        <ErrorPage slug={slug} url={url} />
      )}
    </>
  );
};
export default SponsorsPartners;
