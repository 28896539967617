import React, { useState, useEffect, useReducer } from "react";
import "./sponsorsPartners.scss";
import "swiper/scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useParams } from "react-router-dom";
import { Navigation } from "swiper";
import { TiArrowBack, TiArrowForward } from "react-icons/ti";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useTranslation } from "react-i18next";
import {
  listingInitialState,
  listingReducer,
} from "../../../../shared/reducers/listingReducer";
import axios from "../../../../../config/axios";
const Sponsors = () => {

  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  const { slug, url } = useParams();
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [partnerState, partnerDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );



  const loadPartner = async () => {



      partnerDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`activities/partners/${slug}`); //
        partnerDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data.items,
        });

      } catch (error) {
        partnerDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    
  };
  const loadSponsor = async () => {

 

      listingDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`activities/sponsors/${slug}`); //
        listingDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data.items,
        });

      } catch (error) {
        listingDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    
  };
  useEffect(() => {
    loadSponsor();
    loadPartner();
  }, []);
  return (
    <>
      {screen.content && screen.content.activity && screen.content.activity.allowedFeatures && (screen.content.activity.allowedFeatures.sponsors === "1" || screen.content.activity.allowedFeatures.partners === "1") ? <>

        {listingState.status !== "fetching" && (
          <>
            {listingState.error &&
              listingState.status === "fetchFailed" ? null : (
              <div className="paddingContainerSponsor">
                {(listingState.data.length > 0 || partnerState.data.length > 0) && (
                  <div
                    className={`contentContainerSponsor ${i18n.language === "ar" ? "arabic" : "english"
                      }`}
                  >
                    <h2 className="heading_text">

                      {screen.content.activity.allowedFeatures.partners === "1" && screen.content.activity.allowedFeatures.sponsors === "1" ?
                        screen.items && screen.items.menuSponsorsPartnersText : screen.content.activity.allowedFeatures.partners === "1" ? screen.items && screen.items.sectionPartnersText : screen.content.activity.allowedFeatures.sponsors === "1" && screen.items && screen.items.sectionSponsorsText}
                    </h2>
                    <div className="containerBackupSponsor">

                      <Swiper
                        className="px-0"
                        navigation={{
                          nextEl: ".review-swiper-button-prev",
                          prevEl: ".review-swiper-button-next",
                        }}
                        modules={[Navigation]}
                        spaceBetween={20}
                        breakpoints={{
                          300: {
                            slidesPerView: 1,
                          },
                          450: {
                            slidesPerView: 1,
                          },
                          600: {
                            slidesPerView: 2,
                          },
                          768: {
                            slidesPerView: 3,
                          },
                          992: {
                            slidesPerView: 4,
                          },

                          1200: {
                            slidesPerView: 5,
                            spaceBetween: 40,
                          },
                        }}
                      >
                        {screen.content.activity.allowedFeatures.partners === "1" &&
                          <>

                            {partnerState &&
                              !partnerState.isFetching &&
                              partnerState.status === "fetched" &&
                              partnerState.data && (
                                <>
                                  {partnerState.data.map((item, index) => {
                                    return (
                                      <React.Fragment key={item._id}> {item._id}
                                        {item.items && item.items.length > 0 && item.items.map((partnerItem, index) => {
                                          return (
                                            <SwiperSlide
                                              className="bg-secondaryGray"
                                              key={Math.random()}
                                            >

                                              {partnerItem.logo ? partnerItem.website ? <a href={partnerItem.website} title="Visit Website" target="_blank">
                                                <div className="sponsor_img bg-secondaryGray">
                                                  <img
                                                    className="mx-auto"
                                                    src={partnerItem.logo ? process.env.REACT_APP_BACKEND_URL + "public/" + partnerItem.logo : null}
                                                    alt={partnerItem.name}
                                                    loading="lazy"
                                                  />
                                                </div>
                                              </a> :
                                                <div className="sponsor_img ">
                                                  <div className="sponsor_img bg-secondaryGray">
                                                    <img
                                                      className="mx-auto"
                                                      src={partnerItem.logo ? process.env.REACT_APP_BACKEND_URL + "public/" + partnerItem.logo : null}
                                                      alt={partnerItem.name}
                                                      loading="lazy"
                                                    />
                                                  </div>
                                                </div>


                                                : partnerItem.website ? <a href={partnerItem.website} title="Visit Website" className="sponsor_img font-bold">{partnerItem.name}</a> : <p className="sponsor_img font-bold">{partnerItem.name}</p>}
                                            </SwiperSlide>
                                          )
                                        })}

                                      </React.Fragment>

                                    );
                                  })}
                                </>
                              )}
                          </>}
                        {screen.content.activity.allowedFeatures.sponsors === "1" &&
                          <>
                            {listingState &&
                              !listingState.isFetching &&
                              listingState.status === "fetched" &&
                              listingState.data && (
                                <>
                                  {listingState.data.map((item, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {item.items && item.items.length > 0 && item.items.map((item, index) => {
                                          return (
                                            <SwiperSlide
                                              className="bg-secondaryGray"
                                              key={item._id}
                                            >
                                              {item.logo ? item.website ? <a href={item.website} title="Visit Website" target="_blank">
                                                <div className="sponsor_img bg-secondaryGray">
                                                  <img
                                                    className="mx-auto"
                                                    src={item.logo ? process.env.REACT_APP_BACKEND_URL + "public/" + item.logo : null}
                                                    alt={item.name}
                                                    loading="lazy"
                                                  />
                                                </div>
                                              </a> :
                                                <div className="sponsor_img ">
                                                  <div className="sponsor_img bg-secondaryGray">
                                                    <img
                                                      className="mx-auto"
                                                      src={item.logo ? process.env.REACT_APP_BACKEND_URL + "public/" + item.logo : null}
                                                      alt={item.name}
                                                      loading="lazy"
                                                    />
                                                  </div>
                                                </div>


                                                : item.website ? <a href={item.website} title="Visit Website" className="sponsor_img font-bold">{item.name}</a> : <p className="sponsor_img font-bold">{item.name}</p>}

                                            </SwiperSlide>
                                          )
                                        })}

                                      </React.Fragment>

                                    );
                                  })}
                                </>
                              )}
                          </>}
                        <TiArrowBack className="review-swiper-button-prev" />
                        <TiArrowForward className="review-swiper-button-next" />
                      </Swiper>

                      <Link
                        className="viewAll_textHolder mt-0"
                        to={slug != undefined && url != undefined ? `/sponsors-partners/${slug}/${url}` : slug !== undefined ? `/sponsors-partners/${slug}` : "/sponsors-partners"}

                      >
                        <p
                          className="viewAll mb-0"
                          title={
                            i18n.language === "ar"
                              ? "شاهد المزيد من المتحدثين"
                              : "See more speakers"
                          }
                        >
                          {screen.items && screen.items.linkViewAllText}
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.926"
                              height="13"
                              viewBox="0 0 14.926 13"
                            >
                              <g
                                id="arrow"
                                transform="translate(-1961.224 -1924)"
                              >
                                <g id="Group_102" data-name="Group 102">
                                  <path
                                    id="Path_166"
                                    data-name="Path 166"
                                    d="M1973.4,1931.008h-10.887c-.219,0-.493.073-.646-.03-.27-.183-.64-.473-.643-.721,0-.231.394-.511.669-.681.16-.1.427-.026.647-.026h11.024c-.247-.286-.382-.462-.537-.617q-1.729-1.735-3.466-3.464c-.376-.374-.681-.784-.193-1.245s.877-.145,1.253.231q2.585,2.586,5.185,5.156a.791.791,0,0,1,.051,1.266q-2.9,2.892-5.82,5.761c-.165.163-.4.369-.595.362-.249-.009-.649-.17-.7-.346a1.1,1.1,0,0,1,.169-.908c.891-.967,1.839-1.882,2.775-2.808.589-.582,1.193-1.149,1.79-1.722Z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </> : null}

    </>
  );
};

export default Sponsors;
