import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import logoWhite from "../../../assets/images/logo.png";
import { Link, useParams } from "react-router-dom";
import "./footer.scss";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { featureAllowed } from "../../../Helper/featuresHelper";

const Footer = () => {
  let year = new Date().getFullYear();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  const { slug, url } = useParams();
  const pathname = window.location.pathname; //returns the current url minus the domain name
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  const { t, i18n } = useTranslation();
  function doesPathnameMatch(pathname) {
    // Regular expression pattern to match "about" or "disclaimer" anywhere in the pathname
    const pattern = /\/(contact-us)\//i;
    const pattern2 = /\/(disclaimer)\//i;
    if (pattern.test(pathname)) {

      return 1
    } else if (pattern2.test(pathname)) {

      return 2
    }
    else { return 4 }


  }
  return (
    <>
      {screen.content && screen.content.activity && (
        <footer>
          <div className="paddingContainerFooter">
            <div
              className={`${i18n.language === "ar"
                ? "contentContainerFooter arabic"
                : "contentContainerFooter english"
                } `}
            >
              <Row className="w-100 topRow mx-auto">
                <Col
                  xl={featureAllowed("showSocialChannels") && screen.content.activity && screen.content.activity.socialChannels && (screen.content.activity.socialChannels.facebook || screen.content.activity.socialChannels.twitter || screen.content.activity.socialChannels.linkedin || screen.content.activity.socialChannels.instagram || screen.content.activity.socialChannels.youtube) ? 4 : 7}
                  lg={featureAllowed("showSocialChannels") && screen.content.activity && screen.content.activity.socialChannels && (screen.content.activity.socialChannels.facebook || screen.content.activity.socialChannels.twitter || screen.content.activity.socialChannels.linkedin || screen.content.activity.socialChannels.instagram || screen.content.activity.socialChannels.youtube) ? 3 : 7}
                  md={featureAllowed("showSocialChannels") && screen.content.activity && screen.content.activity.socialChannels && (screen.content.activity.socialChannels.facebook || screen.content.activity.socialChannels.twitter || screen.content.activity.socialChannels.linkedin || screen.content.activity.socialChannels.instagram || screen.content.activity.socialChannels.youtube) ? 3 : 6}
                  xs={12}
                  className="hideOnMobile logo-container"
                >

                  {screen.content.activity && screen.content.activity.client && screen.content.activity.client.whiteLogo &&
                    <div className="logo">
                      <Link to={slug != undefined && url != undefined ? `/${slug}/${url}` : slug != undefined ? `/${slug}` : `/`}>
                        <img
                          src={screen.content.activity.client.whiteLogo ? process.env.REACT_APP_BACKEND_URL + "public/" + screen.content.activity.client.whiteLogo : logoWhite}
                          className="appLogo"
                          alt=""
                          title={
                            i18n.language === "ar"
                              ? "الصفحة الرئيسية"
                              : "Home page"
                          }
                          loading="lazy"
                        />
                      </Link>
                    </div>}
                </Col>

                <Col
                  xl={5}
                  lg={5}
                  md={6}
                  xs={12}
                  sm={12}
                  className="related-links pe-xl-0"
                >

                  <p className="aboutClient">
                    {featureAllowed("showAboutClientLink") && <>
                      {screen.content.activity && screen.content.activity.regPageCustomization && screen.content.activity.regPageCustomization.aboutClientLink && screen.content.activity.regPageCustomization.aboutClientLinkTitle &&
                        <>

                          <a
                            href={screen.content.activity.regPageCustomization.aboutClientLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={
                              i18n.language === "ar"
                                ? "انقر لعرض الموقع"
                                : "Click to view website"
                            }
                          >
                            {/* {screen.items&& screen.items.footerAboutText} */}
                            {screen.content.activity.regPageCustomization.aboutClientLinkTitle}
                          </a>

                          <span className="externalLink">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.564"
                              height="16.232"
                              viewBox="0 0 16.564 16.232"
                            >
                              <g
                                id="Group_114"
                                data-name="Group 114"
                                transform="translate(-2398.498 -3316.992)"
                              >
                                <g id="Group_113" data-name="Group 113">
                                  <path
                                    id="Path_172"
                                    data-name="Path 172"
                                    d="M2400.595,3331.378h12.466v-6.038c.4-.1.5-.167.556-.138,2.675,1.4.754,3.788,1.076,5.677.109.637-1.218,2.151-1.946,2.189-4.079.215-8.181.2-12.263,0a2.839,2.839,0,0,1-1.856-2.039c-.184-3.987-.157-7.989-.015-11.979a2.522,2.522,0,0,1,1.49-1.9,32.365,32.365,0,0,1,5.495-.1c.3.007.593.405.888.622a4.5,4.5,0,0,1-.86.948c-.245.154-.65.054-.984.068-4.366.194-4.37.194-4.364,4.632q.006,3.619.05,7.24A3.242,3.242,0,0,0,2400.595,3331.378Z"
                                    fill="#fff"
                                  />
                                  <path
                                    id="Path_173"
                                    data-name="Path 173"
                                    d="M2402.812,3327.17l8.586-7.972-3-1.229.277-.806h6.073c-.122,2.108.678,4.263-.521,6.028l-.952-.146-.407-2.911c-2.634,2.622-4.982,5.018-7.41,7.331a7.443,7.443,0,0,1-1.971.979Z"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </>
                      }
                    </>}
                  </p>
                  <p className={`${doesPathnameMatch(pathname) === 1 ? "linkActive" : ""}`}>
                    <Link
                      to={slug != undefined && url != undefined ? `/contact-us/${slug}/${url}` : slug != undefined ? `/contact-us/${slug}` : `/contact-us`}
                      title={
                        i18n.language === "ar"
                          ? "انقر للتواصل معنا"
                          : "Click to get in touch with us"
                      }
                    >
                      {screen.items && screen.items.footerContactUsText}
                    </Link>
                  </p>


                  {screen.content.activity && screen.content.activity.disclaimer && screen.content.activity.disclaimer.hasDisclaimer != "No" && screen.content.activity.disclaimer.disclaimer &&
                    <p className={`${doesPathnameMatch(pathname) === 2 ? "linkActive" : ""}`}>
                      <Link
                        to={slug != undefined && url != undefined ? `/disclaimer/${slug}/${url}` : slug != undefined ? `/disclaimer/${slug}` : `/disclaimer`}
                        title={
                          i18n.language === "ar"
                            ? "عرض إخلاء المسؤولية"
                            : "View disclaimer"
                        }
                      >
                        {screen.items && screen.items.footerDisclaimerText}

                      </Link> </p>}

                </Col>
                {featureAllowed("showSocialChannels") && <>

                  {screen.content.activity && screen.content.activity.socialChannels && (screen.content.activity.socialChannels.facebook || screen.content.activity.socialChannels.twitter || screen.content.activity.socialChannels.linkedin || screen.content.activity.socialChannels.instagram || screen.content.activity.socialChannels.youtube) ?
                    <Col xs={12} xl={3} lg={4} md={3} className="socialMediaCol">
                      <div className="">
                        <div className="socialMediaWrapper  ">
                          <div className="socialMediaContainer d-flex">
                            {screen.content.activity && screen.content.activity.socialChannels && screen.content.activity.socialChannels.twitter && (
                              <div>
                                <a
                                  className="mx-1 socialIcon   text-success opacity-75  rounded-circle d-flex align-items-center justify-content-center"
                                  href={screen.content.activity.socialChannels.twitter}
                                  target="_blank"
                                  title={
                                    i18n.language === "ar"
                                      ? "تابعنا على تويتر"
                                      : "Follow us on Twitter"
                                  }
                                >
                                  <FaSquareXTwitter></FaSquareXTwitter>
                                </a>
                              </div>
                            )}
                            {screen.content.activity && screen.content.activity.socialChannels && screen.content.activity.socialChannels.facebook && (
                              <div>
                                <a
                                  className="mx-1 socialIcon  text-success opacity-75  rounded-circle d-flex align-items-center justify-content-center"
                                  href={screen.content.activity.socialChannels.facebook}
                                  target="_blank"
                                  title={
                                    i18n.language === "ar"
                                      ? "اعجب بنا على الفيسبوك"
                                      : "Like us on Facebook"
                                  }
                                >
                                  <FaFacebookF></FaFacebookF>
                                </a>
                              </div>
                            )}
                            {screen.content.activity && screen.content.activity.socialChannels && screen.content.activity.socialChannels.linkedin && (
                              <div>
                                <a
                                  className="mx-1 socialIcon  text-success opacity-75  rounded-circle d-flex align-items-center justify-content-center"
                                  href={screen.content.activity.socialChannels.linkedin}
                                  target="_blank"
                                  title={
                                    i18n.language === "ar"
                                      ? "تابعنا على لينكد إن"
                                      : "Follow us on LinkedIn"
                                  }
                                >
                                  <FaLinkedinIn></FaLinkedinIn>
                                </a>
                              </div>
                            )}
                            {screen.content.activity && screen.content.activity.socialChannels && screen.content.activity.socialChannels.instagram && (
                              <div>
                                <a
                                  className="me-0 ms-1 socialIcon  text-success opacity-75  rounded-circle d-flex align-items-center justify-content-center"
                                  href={screen.content.activity.socialChannels.instagram}
                                  target="_blank"
                                  title={
                                    i18n.language === "ar"
                                      ? "متابعتنا على الانستقرام"
                                      : "Follow us on Instagram"
                                  }
                                >
                                  <FaInstagram></FaInstagram>
                                </a>
                              </div>
                            )}
                            {screen.content.activity && screen.content.activity.socialChannels && screen.content.activity.socialChannels.youtube && (
                              <div>
                                <a
                                  className="me-0 ms-1 socialIcon  text-success opacity-75  rounded-circle d-flex align-items-center justify-content-center"
                                  href={screen.content.activity.socialChannels.youtube}
                                  target="_blank"
                                  title={
                                    i18n.language === "ar"
                                      ? ""
                                      : "View us on youtube"
                                  }
                                >
                                  <FaYoutube ></FaYoutube >
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col> : null}
                </>}
              </Row>
              {screen.content.activity && (
                <Row className="w-100 mx-auto">

                  <Col xs={12} xl={6} md={6} className="copyRightHolder">
                    {screen.content.activity && screen.content.activity.regPageCustomization && screen.content.activity.regPageCustomization.copyrightMessage &&
                      <p className="copy mb-0">
                        © {year}{" "}{screen.content.activity && screen.content.activity.client && screen.content.activity.client.legalName ? screen.content.activity.client.legalName + "." : screen.content.activity.client.name + "."}{" "}
                        {i18n.language === "ar"
                          ? screen.content.activity.regPageCustomization.copyrightMessageArabic
                          : screen.content.activity.regPageCustomization.copyrightMessage}{" "}

                      </p>}
                  </Col>

                  <Col xs={12} xl={6} md={6} className="aheadLogoHolder">
                    <div className="aheadLogo">
                      <div className="AheadLogoWrapper">
                        <div className="AHEADLogoContainer">
                          <a
                            href="https://www.ahead.pro"
                            target="_blank"
                            className="aheadLogoAnimation"
                            title={
                              i18n.language === "ar"
                                ? "قم بزيارة AHEAD"
                                : "Visit AHEAD"
                            }
                          ></a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
