import React, { useState, useEffect } from "react";

const Heading = () => {
  const [items, setItems] = useState({});

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  return (
    <h1 className="heading_text text-tertiaryClient mt-3 mb-4 pb-3 mx-0">
      {items.footerContactUsText}
    </h1>
  );
};

export default Heading;
