import React, { useReducer, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { listingInitialState, listingReducer } from "../../shared/reducers/listingReducer";
import axios from "../../../config/axios";
import { BiErrorCircle } from "react-icons/bi";
const ErrorPageNoLink = () => {

    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center text-center w-100 p-5 h-100">
                <div className="mt-4 pt-5 noItemsFound errorPageContainer">
                    <div className="d-flex align-items-center justify-content-center ">

                        <BiErrorCircle className="errorIcon" />
                    </div>
                    <p className="main">Page Not Found</p>
                    <p>
                        The link you clicked may be broken or the page may have been removed. <br />
                    </p>

                </div>



            </div>
        </>
    );
};

export default ErrorPageNoLink;
