export const createInitialState = {
  data: {},
  isLoading: true,
  status: "fetching", //fetched,submitting,submitted
  error: "",

  // isFetching: false,
  // isSubmitted: false,
  // isSubmitting: false,
  // isSubmitted: false,
  // hasError: false,
  // error: ""
};

export const createReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return {
        ...state,
        isLoading: true,
        status: "fetching", //fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
      };

    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        status: "fetched", //fetching,fetched,fetchFailed,submitting,submitFailed,submitted
        data: action.payload,
      };

    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        status: "fetchFailed", //fetching,fetched,fetchFailed,submitting,submitFailed,submitted
        error: action.payload,
      };
    case "FORM_SUBMISSION_REQUEST":
      return {
        ...state,
        isSubmitting: true,
        status: "submitting", //fetching,fetched,fetchFailed,submitting,submitFailed,submitted
        error: "",
      };

    case "FORM_SUBMISSION_FAILURE":
      // console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        status: "submitFailed", //fetching,fetched,fetchFailed,submitting,submitFailed,submitted
        error: action.payload,
      };

    case "FORM_SUBMISSION_SUCCESS":
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };

    case "FORM_INITIAL_STATE":
      return {
        ...state,
        isLoading: false,
        status: "fetched", //fetching,fetched,fetchFailed,submitting,submitFailed,submitted
        error: "",
      };
    default:
      return state;
  }
};
