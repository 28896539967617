import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import "../agendaPage.scss";
import { BsInfoCircle } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import dummy from "../../../../assets/images/dummy-speaker.png";
import { useTranslation } from "react-i18next";
function MyVerticallyCenteredModal(props) {
  const { i18n } = useTranslation();
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={i18n.language === "ar" ? "arabic" : ""}
    >
      <div className={`modalDiv ${i18n.language === "ar" ? "arabic" : ""}`}>
        <Modal.Header className="bg-tertiaryClient py-2 px-4">
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="fs-6 bold mb-0 text-primaryWhite fontRegular">
              {" "}
              {items.modalTitle}
            </h4>
          </Modal.Title>
          <div>
            <IoMdCloseCircle
              onClick={() => props.onHide()}
              className="closeIcon"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Row className=" px-4">
            <Col xl={9} className="my-3">
              {props.info}
            </Col>
          </Row>
          <Row>
            <Col xl={8}></Col>
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
}

const InfoModal = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { i18n } = useTranslation();
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  return (
    <>
      <p
        className={`speaker cursor-pointer agendaBreakout mb-0 ${
          props.margin == "yes" && "mb-5"
        }`}
        title={items.tooltipMoreDetails}
        onClick={() => setModalShow(true)}
      >
        <span className={i18n.language === "ar" ? "ms-3" : "me-3"}>
          <BsInfoCircle className="infoIcon" />
        </span>
      </p>
      <MyVerticallyCenteredModal
        show={modalShow}
        info={props.info}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default InfoModal;
