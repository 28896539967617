import React, { useState, useRef, useEffect, useReducer } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Alert, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import "./registrationForm.scss";
import * as yup from "yup";
import { BiErrorCircle } from "react-icons/bi";
import moment from "moment";
import greenCheck from "../../../assets/images/done.png";
import caution from "../../../assets/images/caution.png";
import { AiOutlineTwitter } from "react-icons/ai";
import "../../../assets/scss/_swiper.scss";
import "swiper/scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import facebook from "../../../assets/images/facebook.png";
import twitter from "../../../assets/images/twitter.png";
import linkedin from "../../../assets/images/linkedin.png";
import instagram from "../../../assets/images/instagram.png";
import { Link, useParams } from "react-router-dom";
import CMSFormInput from "../../shared/formFields/CMSFormInput";
import CMSFormSelect from "../../shared/formFields/CMSFormSelect";
import CMSFormImage from "../../shared/formFields/CMSFormImage";
import CMSFormRadioButtons from "../../shared/formFields/CMSFormRadioButtons";
import CMSFormCheckBoxes from "../../shared/formFields/CMSFormCheckBoxes";
import CMSFormTextArea from "../../shared/formFields/CMSFormTextArea";
import CMSFormTelephone from "../../shared/formFields/CMSFormTelephone";
import { Disclaimer } from "./disclaimer/Disclaimer";
import { ContainerLoading, SubmissionLoading } from "../../shared/loading/Loading";
import CountriesInput from "../../shared/Formpartials/CountriesInput";
import CheckboxGroup from "../../shared/Formpartials/CheckboxGroup";
import { useTranslation } from "react-i18next";
import {
  listingInitialState,
  listingReducer,
} from "../../shared/reducers/listingReducer";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../shared/errorMessages/BlockErrorMessage";
import {
  createInitialState,
  createReducer,
} from "../../shared/reducers/createReducer";
import { checkEmailExist } from "../../../Helper/emailHelper";
import { HiChevronDoubleRight } from "react-icons/hi";
import CMSFormCheckBox from "../../shared/formFields/CMSFormCheckBox";
import CMSFormEmail from "../../shared/formFields/CMSFormEmail";

export const RegistrationForm = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const filesShareRef = useRef();
  const [state, setState] = useState({
    values: "",
    loading: false,
    formInitialData: null,
    formInitialData2: null,
    stepOneFields: [],
    stepTwoFields: [],
    breakoutSettings: [],
    activeStep: "Step 1",
    items: {},
    content: {}
  });
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const { slug, url } = useParams();
  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      //setItems(items);
      setState((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      //setContent(content);
      setState((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, [slug]);
  const { t, i18n } = useTranslation();
  const validateEmailExistence = async (email) => {
    const exists = await checkEmailExist(email);
    return exists === "1" ? "This email is already registered for this event." : null;
  };
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].title, value: item[i]._id });
    }

    return array;
  };

  useEffect(() => {
    if (state.loading === true) {
      setTimeout(() => {

        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }, 1000);
    }
  }, [state.loading]);

  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [tweetsState, tweetsDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [registrationState, registrationDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));


  const loadRegistrationDetails = async () => {



    registrationDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`activities/form-settings/${slug}`); //
      registrationDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });


      setState((prevState) => ({
        ...prevState,
        stepOneFields: res.data.personalInfoForm,
        breakoutSettings: res.data.breakoutForm,
        stepTwoFields: res.data.breakoutDays,
      }));
    } catch (error) {
      registrationDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }

  };

  const onSubmit = async (data) => {

    registrationDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(
        `activities/register/${currentActivity.activityId}`,
        data
      );

      registrationDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      localStorage.removeItem("registrationValues");
      localStorage.removeItem("breakOutValues");
      setTimeout(() => {
        registrationDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    } catch (error) {

      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;
      console.log(error)

      registrationDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      loadDataFromStorage();
      setTimeout(() => {
        registrationDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  useEffect(() => {

    loadRegistrationDetails();
  }, [slug]);
  useEffect(() => {
    loadDataFromStorage();
  }, [state.stepOneFields, state.stepTwoFields, state.activeStep]);

  // useEffect(() => {
  //   localStorage.removeItem("registrationValues");
  //   localStorage.removeItem("breakOutValues");
  // }, [])
  const loadDataFromStorage = async () => {
    var initialValues = localStorage.getItem("registrationValues");
    var initialValues2 = localStorage.getItem("breakOutValues");
    const x = state.stepOneFields.reduce((acc, curr) => {
      acc[curr.fieldName] = "";
      return acc;
    }, {});
    const tAndC = (state.content.activity && state.content.activity.disclaimer && state.content.activity.disclaimer.hasDisclaimer === "Add as Checkbox in Form")
      ? { tAndC: false } // Assuming a default value of false for tAndC
      : {};
    const urlParam = url != undefined
      ? { urlParam: url } // Assuming a default value of false for tAndC
      : {};
    const result = Object.assign(
      {},
      ...state.stepTwoFields.map((day) => {
        return day.breakouts.reduce((acc, breakout) => {
          if (breakout.breakoutSessions && breakout.breakoutSessions.length > 0 && breakout.title && breakout.title.trim() !== "") {
            acc[breakout.title] = "";
          }
          return acc;
        }, {});
      })
    );

    initialValues =
      initialValues != null ? JSON.parse(initialValues) : { ...x, ...tAndC, ...urlParam };
    initialValues2 =
      initialValues2 != null ? JSON.parse(initialValues2) : result;

    setState((prevState) => ({
      ...prevState,
      formInitialData2: initialValues2,
      formInitialData: initialValues
    }));
    console.log(initialValues)
  };
  function getFormattedName(input) {
    // Use a regular expression to split camelCase into words
    const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toLowerCase() + word.slice(1));
    // Join the words back into a string
    const formattedString = capitalizedWords.join(' ');
    return formattedString;
  }
  let validationSchema = yup.object().shape(
    state.stepOneFields.length > 0 &&
    state.stepOneFields.reduce((acc, field) => {
      if (field.isSelected === "1") {
        if (field.fieldName === "file1" || field.fieldName === "file2") {
          field.isRequired === "1"
            ? (acc[field.fieldName] = yup
              .mixed()
              .required(state.items && state.items.formErrorRequired)
              .test("FILE_SIZE", state.items && state.items.formErrorFileSize, (value) => {
                return value && filesShareRef.current
                  ? filesShareRef.current.files[0].size <= 2408698
                    ? true
                    : false
                  : true;
              }))
            : (acc[field.fieldName] = yup
              .mixed()
              .test("FILE_SIZE", state.items && state.items.formErrorFileSize, (value) => {
                return value && filesShareRef.current
                  ? filesShareRef.current.files[0].size <= 2408698
                    ? true
                    : false
                  : true;
              }));
        }

        else if (field.fieldName === "email") {
          field.isRequired === "1"
            ? (acc[field.fieldName] = yup
              .string()
              .email("Please enter a valid email address").required("Please enter your email address"))
            : (acc[field.fieldName] = yup.string().email("Please enter a valid email address"));
        }
        else {
          field.isRequired === "1"
            ? (acc[field.fieldName] = yup.string().required((field.fieldName === "mobile" || field.fieldName === "companyPhone") ? `Please enter your ${getFormattedName(field.fieldName)} number` : field.fieldName === "country" ? `Please select your ${getFormattedName(field.fieldName)}` : `Please enter your ${getFormattedName(field.fieldName)}`))
            : (acc[field.fieldName] = yup.string());
        }

      }

      return acc;
    }, {})
  );
  // If disclaimer condition is met, add the tAndC schema
  if (state.content.activity && state.content.activity.disclaimer && state.content.activity.disclaimer.hasDisclaimer === "Add as Checkbox in Form" && state.content.activity.disclaimer.disclaimer) {
    const tAndCSchema = yup.boolean().required(state.items && state.items.formErrorRequired).oneOf([true], "Please accept the terms and conditions");

    validationSchema = yup.object().shape({
      ...validationSchema.fields,
      tAndC: tAndCSchema,
    });
  }
  const validationSchemaStep2 = yup.object().shape(
    Object.assign(
      {},
      ...state.stepTwoFields.map((day) => {
        return day.breakouts.reduce((acc, breakout) => {
          if (breakout.breakoutSessions && breakout.breakoutSessions.length > 0 && breakout.title && breakout.title.trim() !== "") {

            if (state.breakoutSettings && state.breakoutSettings.validationType === "One For Each Day") {
              (acc[breakout.title] = yup.string().required(state.items && state.items.formErrorBreakout))
            }
            else {
              (acc[breakout.title] = yup.string())
            }

          }
          return acc;
        }, {});
      })
    )
  );
  const handleScroll = (error) => {

    if (Object.keys(error).length > 0) {
      const element = document.getElementsByName(Object.keys(error)[0])[0];
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 20);
    }

  };

  return (
    <>
      {registrationState.isLoading &&
        <div >
          <ContainerLoading />
        </div>}
      {registrationState.status !== "fetching" && (
        <>
          {registrationState.error &&
            registrationState.status === "fetchFailed" ? (
            <BlockErrorMessage error={registrationState.error} />
          ) : (
            <section className="my-3 formContent">
              <>
                {state.content && state.content.activity && state.content.activity.regPageCustomization && state.content.activity.regPageCustomization.status === "Started" &&

                  <Row ref={myRef}>
                    <Col>
                      <h2 className="heading_text  text-primaryClient pt-4 pb-4 ms-0">
                        {state.items && state.items.sectionRegistrationForm}
                      </h2>
                    </Col>
                  </Row>
                }
              </>




              <>
                <Row className="pe-1" >
                  <Col xl={12} className="smoothTransition">
                    <>
                      {state.content.activity && state.content.activity.regPageCustomization && state.content.activity.regPageCustomization.status === "Not Started" ? (
                        <Card className="registrationMessage my-3">
                          <Card.Body>
                            {/* <h5>{state.items && state.items.formErrorMessageHeading}</h5> */}
                            <h5>{state.items && state.items.formMessageRegistrationNotStarted}</h5>
                            {/* <p>{state.items && state.items.formErrorMessageSubHeading}</p> */}
                            <p> Please stay tuned for further updates. Thank you for your interest.</p>
                          </Card.Body>
                        </Card>
                      ) : state.content.activity && state.content.activity.regPageCustomization && state.content.activity.regPageCustomization.status === "Ended" ? (
                        <Card className="registrationMessage my-3">
                          <Card.Body>
                            {/* <h5>{state.items && state.items.formErrorMessageHeading}</h5> */}
                            <h5>{state.items && state.items.formMessageRegistrationEnd}</h5>
                            <p> We apologize for any inconvenience. Stay tuned for future events! <br />
                              Thank you for your interest.</p>
                          </Card.Body>
                        </Card>
                      ) : null}
                    </>
                  </Col>
                </Row>
                {state.content.activity && state.content.activity.regPageCustomization && state.content.activity.regPageCustomization.status === "Started" &&
                  <Row className="d-flex align-items-start justify-content-between ">
                    <>
                      <Col
                        xl={
                          registrationState.data.socialMedia === "1" ||
                            registrationState.data.tweets === "1"
                            ? 8
                            : 12
                        }
                        className="smoothTransition"
                      >

                        {registrationState.data && (
                          <>
                            {registrationState.isSubmitted ? (
                              <div className="mt-4 pt-5">
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={greenCheck}
                                    alt="form submitted"
                                    className="submittedImage"
                                    loading="lazy"
                                  />
                                </div>
                                <h2 className="text-tertiaryClient text-center  fs-2 bold mt-2  mb-3">
                                  {state.items && state.items.formSubmissionSuccessHeading}
                                </h2>

                                <p className="text-center">
                                  {state.items && state.items.formSubmissionSuccessMessage}
                                </p>
                                <Alert variant="success" className="bg-successGreen mt-3">
                                  <Alert.Heading className="fs-6 bold">
                                    {state.items && state.items.formSubmissionDisplayMessageHead}
                                  </Alert.Heading>

                                  <p className="fs-8 mb-2">
                                    {
                                      state.items && state.items.formSubmissionDisplayMessageSubHead
                                    }
                                  </p>
                                  <p className="fs-8 mb-1">
                                    {state.items && state.items.formSubmissionDisplayMessageBody}
                                  </p>
                                  <p className="fs-8 mb-2">
                                    {state.items && state.items.formSubmissionDisplayMessageBody2}
                                  </p>

                                  <p className="mb-0 mt-1 fs-8">
                                    {state.items && state.items.formSubmissionDisplayMessageBody3}{" "}
                                    <Link
                                      to={slug != undefined && url != undefined ? `/contact-us/${slug}/${url}` : slug != undefined ? `/contact-us/${slug}` : `/contact-us`}
                                      title="Click to contact us"
                                      className="text-green bold fs-8"
                                    >
                                      {state.items && state.items.footerContactUsText}
                                    </Link>
                                  </p>
                                </Alert>
                              </div>
                            ) : registrationState.status ===
                              "submitFailed" ? (
                              <div className="mt-4 pt-5 noItemsFound">
                                <div className="d-flex align-items-center justify-content-center ">

                                  <BiErrorCircle className="errorIcon" />
                                </div>
                                <h2 className="text-tertiaryClient text-center  bold mt-2  mb-3">
                                  Submission Error!
                                </h2>

                                <p className="text-center">
                                  {state.items && state.items.formErrorMessageSubHeading2}
                                  {state.items && state.items.formErrorMessageSubHeading}
                                </p>
                              </div>
                            ) : (
                              registrationState.status != "fetching" && (
                                <>
                                  <Row className="mb-5 mt-4 pe-1 stepsRowTop">
                                    {registrationState.isSubmitting &&
                                      < >
                                        <SubmissionLoading />
                                      </>}
                                    <Col xl={12} md={12} xs={12} className="">
                                      <Row>
                                        <div className="TopFlexSteps">
                                          <>
                                            {registrationState.data && registrationState.data
                                              .breakoutForm && registrationState.data
                                                .breakoutForm.showBreakoutScreen === "1" && (
                                                <>
                                                  <Col
                                                    className={`stepsRow ${i18n.language === "ar"
                                                      ? "arabic"
                                                      : "english"
                                                      } ${state.activeStep ===
                                                        "Step 1"
                                                        ? "activeForm"
                                                        : ""
                                                      }`}
                                                  // onClick={() =>
                                                  //   setState({
                                                  //     ...state,
                                                  //     activeStep: "Step 1",
                                                  //   })
                                                  // }
                                                  >
                                                    <p
                                                      className={`step ${state.activeStep ===
                                                        "Step 1"
                                                        ? "activeForm"
                                                        : ""
                                                        }`}
                                                    >
                                                      {" "}
                                                      {state.items && state.items.tabStep1}
                                                    </p>
                                                  </Col>

                                                  <Col
                                                    className={`stepsRow ${i18n.language === "ar"
                                                      ? "arabic"
                                                      : "english"
                                                      }  ${state.activeStep ===
                                                        "Step 2"
                                                        ? "activeForm"
                                                        : ""
                                                      }`}
                                                  // onClick={() =>
                                                  //   setState({
                                                  //     ...state,
                                                  //     activeStep: "Step 2",
                                                  //   })
                                                  // }
                                                  >
                                                    <p
                                                      className={`step  ${state.activeStep ===
                                                        "Step 2"
                                                        ? "activeForm"
                                                        : ""
                                                        }`}
                                                    >
                                                      {" "}
                                                      {state.items && state.items.tabStep2}
                                                    </p>
                                                  </Col>
                                                </>
                                              )}
                                          </>
                                        </div>

                                        <>

                                          {state.activeStep === "Step 1" && (
                                            <div className="mb-0 mt-5 pt-xl-0 pt-lg-0 pt-md-0 pt-4 ">

                                              <Formik
                                                initialValues={state.formInitialData}
                                                validationSchema={
                                                  validationSchema
                                                }
                                                enableReinitialize={true}
                                                validateOnChange={true}
                                                validateOnBlur={true}
                                                onSubmit={(values, form) => {

                                                  if (!emailError) {
                                                    if (values.mobile?.length <= 5) {
                                                      values.mobile = ""
                                                    }

                                                    if (registrationState.data &&
                                                      registrationState.data
                                                        .breakoutForm && registrationState.data.breakoutForm.showBreakoutScreen === "1"
                                                    ) {


                                                      setState((prevState) => ({
                                                        ...prevState,
                                                        values: values,
                                                        loading: true,
                                                        activeStep: "Step 2",
                                                      }));
                                                      localStorage.setItem(
                                                        "registrationValues",
                                                        JSON.stringify(values)
                                                      );
                                                    } else {

                                                      localStorage.setItem(
                                                        "registrationValues",
                                                        JSON.stringify(values))
                                                      if (values.tAndC) {
                                                        values = { ...values, disclaimerAccepted: "1" }
                                                        delete values.tAndC
                                                      }


                                                      onSubmit(values);
                                                    }

                                                  }



                                                }}
                                              >
                                                {(form) => (
                                                  <Form
                                                    onSubmit={
                                                      form.handleSubmit
                                                    }
                                                  >
                                                    {console.log(form.errors)}


                                                    <Row className="">
                                                      {registrationState.data &&
                                                        registrationState.data.personalInfoForm.map(
                                                          (
                                                            field,
                                                            index
                                                          ) => {
                                                            if (field.isSelected === "1") return (
                                                              <React.Fragment key={index}>

                                                                {(field.fieldName ===
                                                                  "file1" || field.fieldName ===
                                                                  "file2") && (
                                                                    <Col
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={12}
                                                                      lg={12}
                                                                      xl={12}
                                                                      id={field.fieldName}
                                                                      className="mb-4 pb-2"
                                                                    >
                                                                      <CMSFormImage
                                                                        name={
                                                                          field.fieldName
                                                                        }
                                                                        form={
                                                                          form
                                                                        }
                                                                        label={
                                                                          i18n.language ===
                                                                            "ar"
                                                                            ? field.fieldNameArabic
                                                                            : field.fieldLabel
                                                                        }
                                                                        description={field.fieldDescription}
                                                                        required={field.isRequired === "1"
                                                                          ? true
                                                                          : false
                                                                        }
                                                                      />
                                                                    </Col>
                                                                  )}
                                                                {field.fieldName ===
                                                                  "comments" && (
                                                                    <Col
                                                                      xs={12}
                                                                      sm={12}
                                                                      md={12}
                                                                      lg={12}
                                                                      xl={12}
                                                                      id="comments"
                                                                      className="mb-4 pb-2"
                                                                    >
                                                                      <CMSFormTextArea
                                                                        name="comments"
                                                                        label={
                                                                          i18n.language ===
                                                                            "ar"
                                                                            ? field.fieldNameArabic
                                                                            : field.fieldLabel
                                                                        }
                                                                        // placeholder={
                                                                        //   state.items && state.items.formLabelComments
                                                                        // }
                                                                        description={field.fieldDescription}
                                                                        maxLength={
                                                                          320
                                                                        }
                                                                        form={
                                                                          form
                                                                        }
                                                                        required={field.isRequired === "1"
                                                                          ? true
                                                                          : false
                                                                        }
                                                                      />
                                                                    </Col>
                                                                  )}

                                                                {(field.fieldName !==
                                                                  "comments" && field.fieldName !==
                                                                  "file1" && field.fieldName !==
                                                                  "file2" && field.fieldName !==
                                                                  "image1" && field.fieldName !==
                                                                  "image2") &&
                                                                  <Col
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={6}
                                                                    xl={6}
                                                                    id={field.fieldName}
                                                                    className="mb-4 pb-2"
                                                                  >
                                                                    {(field.fieldName !==
                                                                      "country" &&
                                                                      field.fieldName !==
                                                                      "email" &&
                                                                      field.fieldName !==
                                                                      "comments" && field.fieldName !==
                                                                      "mobile" &&
                                                                      field.fieldName !==
                                                                      "companyPhone") ? (
                                                                      <CMSFormInput
                                                                        name={
                                                                          field.fieldName
                                                                        }

                                                                        description={field.fieldDescription}
                                                                        label={
                                                                          i18n.language ===
                                                                            "ar"
                                                                            ? field.fieldNameArabic
                                                                            : field.fieldLabel
                                                                        }
                                                                        // label={
                                                                        //   items.formLabelFirstName
                                                                        // }
                                                                        form={
                                                                          form
                                                                        }
                                                                        required={field.isRequired === "1"
                                                                          ? true
                                                                          : false
                                                                        }
                                                                      />
                                                                    ) : null}
                                                                    {field.fieldName ===
                                                                      "email" ? (
                                                                      <CMSFormEmail
                                                                        name={
                                                                          field.fieldName
                                                                        }
                                                                        errorState={
                                                                          emailError
                                                                        }
                                                                        setErrorState={
                                                                          setEmailError
                                                                        }
                                                                        onBlur={true}
                                                                        description={field.fieldDescription}
                                                                        label={
                                                                          i18n.language ===
                                                                            "ar"
                                                                            ? field.fieldNameArabic
                                                                            : field.fieldLabel
                                                                        }
                                                                        // label={
                                                                        //   items.formLabelFirstName
                                                                        // }
                                                                        form={
                                                                          form
                                                                        }
                                                                        required={field.isRequired === "1"
                                                                          ? true
                                                                          : false
                                                                        }
                                                                      />
                                                                    ) : null}

                                                                    {field.fieldName ===
                                                                      "country" ? (
                                                                      <CountriesInput
                                                                        listType="all"
                                                                        controlId="country"
                                                                        label={
                                                                          i18n.language ===
                                                                            "ar"
                                                                            ? field.fieldNameArabic
                                                                            : field.fieldLabel
                                                                        }
                                                                        name="country"
                                                                        type="text"
                                                                        as="select"
                                                                        required={field.isRequired === "1"
                                                                          ? true
                                                                          : false
                                                                        }
                                                                        description={field.fieldDescription}
                                                                        value={
                                                                          form
                                                                            .values
                                                                            .country &&
                                                                          form
                                                                            .values
                                                                            .country
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          form.handleChange(
                                                                            e
                                                                          );
                                                                        }}
                                                                        onBlur={
                                                                          form.handleBlur
                                                                        }
                                                                      />
                                                                    ) : null}

                                                                    {(field.fieldName ===

                                                                      "mobile" || field.fieldName ===

                                                                      "companyPhone") ? (
                                                                      <CMSFormTelephone
                                                                        name={
                                                                          field.fieldName
                                                                        }
                                                                        label={
                                                                          i18n.language ===
                                                                            "ar"
                                                                            ? field.fieldNameArabic
                                                                            : field.fieldLabel
                                                                        }
                                                                        errorState={
                                                                          error
                                                                        }
                                                                        description={field.fieldDescription}
                                                                        setErrorState={
                                                                          setError
                                                                        }
                                                                        // label={
                                                                        //   items.formLabelLandLine
                                                                        // }
                                                                        required={field.isRequired === "1"
                                                                          ? true
                                                                          : false
                                                                        }
                                                                        form={
                                                                          form
                                                                        }
                                                                      />
                                                                    ) : null}
                                                                  </Col>}
                                                              </React.Fragment>
                                                            );
                                                          }
                                                        )}

                                                      {state.content.activity && state.content.activity.disclaimer && state.content.activity.disclaimer.hasDisclaimer === "Add as Checkbox in Form" && state.content.activity.disclaimer.disclaimer ? (
                                                        <Col
                                                          xs={12}
                                                          sm={12}
                                                          md={12}
                                                          lg={12}
                                                          xl={12}
                                                          id="tAndC"
                                                          className={`mb-4 pb-2 ${i18n.language ===
                                                            "ar"
                                                            ? "arabic"
                                                            : ""
                                                            } `}
                                                        >

                                                          <CheckboxGroup
                                                            checked={
                                                              form
                                                                .values
                                                                .tAndC
                                                            }
                                                            type="checkbox"
                                                            name="tAndC"
                                                            id="check"
                                                            onBlur={(e) => {
                                                              form.handleBlur(e);
                                                            }}
                                                            onChange={(
                                                              e
                                                            ) => {
                                                              form.handleChange(
                                                                e
                                                              );
                                                            }}
                                                            label={
                                                              <p className="mb-0 d-flex">
                                                                {
                                                                  state.items && state.items.formLabelIAccept
                                                                }

                                                              </p>
                                                            }
                                                          />
                                                        </Col>
                                                      ) : null}
                                                      {state.content.activity && state.content.activity.disclaimer && state.content.activity.disclaimer.hasDisclaimer === "Show As HTML" && state.content.activity.disclaimer.disclaimer ? (
                                                        <Col
                                                          xl={12}
                                                          lg={12}
                                                          md={12}
                                                          sm={12}
                                                          xs={12}
                                                          className="mb-3"
                                                        >

                                                          <Alert variant="success" className="bg-lightGray">
                                                            <Alert.Heading className="fs-6 bold">
                                                              {
                                                                state.items && state.items.formLabelDisclaimer
                                                              }
                                                            </Alert.Heading>

                                                            <Col className="d-flex align-item-center justify-content-center flex-column">
                                                              <div className="html bg-lightGray"
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                dangerouslySetInnerHTML={
                                                                  i18n.language ===
                                                                    "ar"
                                                                    ? {
                                                                      __html: state.content.activity.disclaimer.disclaimerArabic,
                                                                    }
                                                                    : {
                                                                      __html: state.content.activity.disclaimer.disclaimer,
                                                                    }
                                                                }
                                                              />
                                                            </Col>
                                                          </Alert>
                                                        </Col>
                                                      ) : null}
                                                    </Row>

                                                    <button
                                                      className="BorderPrimaryBlue"
                                                      type="submit"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        form.submitForm();
                                                        executeScroll(e); // Ensure that form validation is completed before submission
                                                        handleScroll(form.errors);  // Scroll to errors if any
                                                      }}
                                                    >
                                                      <>
                                                        {registrationState
                                                          .data && registrationState
                                                            .data
                                                            .breakoutForm && registrationState
                                                              .data
                                                              .breakoutForm
                                                              .showBreakoutScreen === "1"
                                                          ? state.items && state.items.formButtonNext
                                                          : state.items && state.items.formButtonRegister}
                                                      </>
                                                    </button>
                                                  </Form>

                                                  //{/* <FormUpload  label="Upload Registrant ID " name="file" value={values.file} mandatory={true} onChange={handleChange} description="(File should be jpg, jpeg, png, or pdf. Max 2MB file size.)" /> */}
                                                  //{/* <ImageUpload label="Upload Registrant ID " name="file" value={values.file} mandatory={true} onChange={handleChange} description="(File should be jpg, jpeg, png, or pdf. Max 2MB file size.)" /> */}
                                                  // {/* <Row className="mb-4">
                                                  //   <Col xs={12} xl={6} md={6}
                                                  //     className={`  ${
                                                  //       errors.captcha && touched.captcha ? "" : ""
                                                  //     }`}
                                                  //   >
                                                  //     <FormInput
                                                  //       name="captcha"
                                                  //       controlId="captcha"
                                                  //       type="text"
                                                  //       label="Type text displayed below"
                                                  //       mandatory={true}
                                                  //       value={values.captcha}
                                                  //       onChange={handleChange}
                                                  //       onBlur={handleBlur}
                                                  //     />
                                                  //   </Col>
                                                  //   <Col xs={12} xl={6} md={6}

                                                  //   >
                                                  //     captcha

                                                  //   </Col>
                                                  //   <Col className="mb-4"></Col>
                                                )}
                                              </Formik>
                                            </div>
                                          )}
                                        </>

                                        {state.activeStep === "Step 2" && (
                                          <div className="mb-0 mt-5 pt-xl-0 pt-lg-0 pt-md-0 pt-4 ">
                                            <Formik
                                              initialValues={state.formInitialData2}
                                              validationSchema={
                                                validationSchemaStep2
                                              }
                                              enableReinitialize={true}
                                              validateOnChange={true}
                                              validateOnBlur={true}

                                              onSubmit={(
                                                values,
                                                { resetForm }
                                              ) => {
                                                // executeScroll();

                                                const sessionArray = Object.entries(values)
                                                  .filter(([key, value]) => value !== "")
                                                  .map(([key, value]) => (value));
                                                const dataSaved =
                                                  localStorage.getItem(
                                                    "registrationValues"
                                                  );
                                                const data =
                                                  JSON.parse(dataSaved);
                                                localStorage.setItem(
                                                  "breakOutValues",
                                                  JSON.stringify(values)
                                                );
                                                var allValues = {
                                                  ...data,
                                                  breakoutIds: sessionArray,
                                                };
                                                if (allValues.tAndC) {


                                                  allValues = { ...allValues, disclaimerAccepted: "1" }

                                                  delete allValues.tAndC
                                                }

                                                // const formData =
                                                //   new FormData();
                                                // for (let name in allValues) {
                                                //   if (
                                                //     Array.isArray(
                                                //       allValues[name]
                                                //     )
                                                //   ) {
                                                //     formData.append(
                                                //       name,
                                                //       JSON.stringify(
                                                //         allValues[name]
                                                //       )
                                                //     );
                                                //   } else {
                                                //     formData.append(
                                                //       name,
                                                //       allValues[name]
                                                //     );
                                                //   }
                                                // }


                                                onSubmit(allValues);
                                              }}
                                            >
                                              {(form) => (
                                                <Form
                                                  onSubmit={form.handleSubmit}
                                                >
                                                  <div className="mb-5">
                                                    {state.breakoutSettings && state.breakoutSettings.heading ? <label >{state.breakoutSettings.heading}</label> : <label>Select breakout(s) from below that you would like to attend</label>}<br />
                                                    {state.breakoutSettings && state.breakoutSettings.message && <small className="fs-8" style={{
                                                      fontStyle: "italic",
                                                      fontSize: 12,
                                                      color: "#555",
                                                      marginBottom: "5px",
                                                    }}>({state.breakoutSettings.message})</small>}
                                                  </div>


                                                  {registrationState.data &&
                                                    registrationState.data.breakoutDays.map(
                                                      (field, item) => {
                                                        return (
                                                          <>
                                                            {registrationState.data &&
                                                              registrationState.data.breakoutDays && registrationState.data.breakoutDays.length > 1 && field.breakouts && field.breakouts.length > 0 &&
                                                              <Col xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12} className="mb-4 dayTextBold">
                                                                <HiChevronDoubleRight className="dayTextBold me-2" />  Day {field.day}
                                                              </Col>}
                                                            {field.breakouts && field.breakouts.map((breakout, index) => {
                                                              return (
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={12}
                                                                  xl={12}
                                                                  id={
                                                                    breakout.title
                                                                  }
                                                                  className={`mb-4 ${registrationState.data &&
                                                                    registrationState.data.breakoutDays && registrationState.data.breakoutDays.length > 1 ? "mx-4" : ""} pb-2`}
                                                                >
                                                                  {breakout.breakoutSessions && breakout.breakoutSessions.length > 0 &&
                                                                    <CMSFormRadioButtons
                                                                      name={
                                                                        breakout.title
                                                                      }
                                                                      label={
                                                                        i18n.language ===
                                                                          "ar"
                                                                          ? breakout.titleArabic
                                                                          : breakout.title
                                                                      }

                                                                      options={
                                                                        i18n.language ===
                                                                          "ar"
                                                                          ? handleFormatArray(
                                                                            breakout.breakoutSessions
                                                                          )
                                                                          : handleFormatArray(
                                                                            breakout.breakoutSessions
                                                                          )
                                                                      }
                                                                      form={form}
                                                                      required={state.breakoutSettings && state.breakoutSettings.validationType === "One For Each Day" ? true : false}
                                                                    />}
                                                                </Col>
                                                              )
                                                            })}


                                                          </>
                                                        );
                                                      }
                                                    )}

                                                  <Col>
                                                    <button
                                                      className="BorderPrimaryBlue  mt-4"
                                                      type="submit"
                                                      onClick={(e) => {

                                                        setState((prevState) => ({
                                                          ...prevState,
                                                          activeStep:
                                                            "Step 1",
                                                        }));
                                                        localStorage.setItem(
                                                          "breakOutValues",
                                                          JSON.stringify(form.values)
                                                        );
                                                      }
                                                      }
                                                    >
                                                      {state.items && state.items.formButtonBack}
                                                    </button>
                                                    <button
                                                      className={`BorderPrimaryBlue mt-4 ${i18n.language === "ar"
                                                        ? "me-3"
                                                        : "ms-3"
                                                        }`}
                                                      type="submit"
                                                      // onClick={(e) => {
                                                      //   form.handleSubmit(e);
                                                      //   handleScroll(form.errors);
                                                      // }}
                                                      onClick={async (e) => {
                                                        e.preventDefault();
                                                        await form.submitForm();
                                                        executeScroll(e); // Ensure that form validation is completed before submission
                                                        handleScroll(form.errors);  // Scroll to errors if any
                                                      }}
                                                    >
                                                      {
                                                        state.items && state.items.formButtonRegister
                                                      }
                                                    </button>
                                                  </Col>
                                                </Form>
                                              )}
                                            </Formik>
                                          </div>
                                        )}
                                      </Row>
                                    </Col>

                                    <Col xs={12} md={3} sm={6} xl={12}>
                                      <div
                                        className={`mandatory_field mandatory_text ${i18n.language === "ar"
                                          ? "arabic"
                                          : "english"
                                          }   text-primaryGray`}
                                      >

                                        {state.activeStep === "Step 1" ? ((state.stepOneFields.filter((e) => e.isSelected === "1").length) === (state.stepOneFields.filter((e) => e.isRequired === "1" && e.isSelected === "1").length)) ? "All fields are mandatory" : state.items && state.items.formMandatoryText : state.activeStep === "Step 2" && (state.breakoutSettings && state.breakoutSettings.validationType === "One For Each Day") ? "All fields are mandatory" : state.items && state.items.formMandatoryText}
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              )
                            )}
                          </>
                        )}
                      </Col>
                      {registrationState.data.eventStarted === "1" &&
                        registrationState.data.eventEnded !== "1" && (
                          <Col xl={3} xs={12} md={6} className="">
                            <Row className=" mt-4 rightSideContainer ">
                              {registrationState.data.socialMedia === "1" && (
                                <>
                                  {listingState.status === "fetched" && (
                                    <Col
                                      xl={12}
                                      className="border followContainer my-3"
                                    >
                                      <h6 className="text_left my-3 text-primary">
                                        {state.items && state.items.sectionFollowUs}
                                      </h6>
                                      <div className="social d-flex align-items-center justify-content-around my-4">
                                        {listingState.data &&
                                          listingState.data.facebook && (
                                            <a
                                              href="https://www.facebook.com/"
                                              target="_blank"
                                              title={state.items && state.items.tooltipFacebook}
                                              className="tooltiptop"
                                            >
                                              <img
                                                src={facebook}
                                                width="60"
                                                height="60"
                                              />
                                            </a>
                                          )}
                                        {listingState.data &&
                                          listingState.data.twitter && (
                                            <a
                                              href="https://twitter.com/"
                                              target="_blank"
                                              title={state.items && state.items.tooltipTwitter}
                                            >
                                              <img
                                                src={twitter}
                                                width="60"
                                                height="60"
                                              />
                                            </a>
                                          )}
                                        {listingState.data &&
                                          listingState.data.instagram && (
                                            <a
                                              href="https://www.instagram.com/"
                                              target="_blank"
                                              title={state.items && state.items.tooltipInstagram}
                                            >
                                              <img
                                                src={instagram}
                                                width="60"
                                                height="60"
                                              />
                                            </a>
                                          )}
                                        {listingState.data &&
                                          listingState.data.linkedin && (
                                            <a
                                              href="https://www.linkedin.com/"
                                              target="_blank"
                                              title={state.items && state.items.tooltipLinkedIn}
                                            >
                                              <img
                                                src={linkedin}
                                                width="60"
                                                height="60"
                                              />
                                            </a>
                                          )}
                                      </div>
                                    </Col>
                                  )}
                                </>
                              )}
                              {registrationState.data.tweets === "1" && (
                                <Col
                                  xl={12}
                                  className="my-3 border followContainer"
                                >
                                  <div className="liveFeeds_container">
                                    {tweetsState.status === "fetched" &&
                                      tweetsState.data.length > 0 && (
                                        <div className="liveFeeds borderedbox">
                                          <h6
                                            className={`text_left my-3 ${i18n.language === "ar"
                                              ? "arabic"
                                              : ""
                                              }`}
                                          >
                                            <span className="icon-twitter  fontcolor-primary">
                                              <AiOutlineTwitter
                                                fill="#019de1"
                                                fontSize={25}
                                                width="50px"
                                                className="mb-2"
                                                height="50px"
                                              />
                                            </span>{" "}
                                            {state.items && state.items.sectionSocialFeeds}
                                            <span className="hashtag_container fontcolor-primary ">
                                              {/* #expo2020 */}
                                            </span>
                                          </h6>
                                          <div>
                                            <Swiper
                                              className={`tweetSlider ${i18n.language === "ar"
                                                ? "arabic"
                                                : "english"
                                                } `}
                                              navigation={true}
                                              autoHeight={true}
                                              autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: true,
                                              }}
                                              loop
                                              modules={[Navigation, Autoplay]}
                                              slidesPerView={1}
                                            >
                                              {tweetsState.data &&
                                                tweetsState.data
                                                  .sort(
                                                    (a, b) =>
                                                      a.sort_order -
                                                      b.sort_order
                                                  )
                                                  .map((tweet, index) => (
                                                    <SwiperSlide
                                                      key={tweet.id}
                                                      className=""
                                                    >
                                                      <Row className="my-3 d-flex align-items-center ">
                                                        <Col
                                                          xl={3}
                                                          lg={3}
                                                          md={2}
                                                          sm={2}
                                                          xs={3}
                                                        >
                                                          <img
                                                            src={tweet.userPic}
                                                            width="70px"
                                                            height="70px"
                                                            className="rounded-circle"
                                                          />
                                                        </Col>
                                                        <Col
                                                          className={
                                                            i18n.language ===
                                                              "ar"
                                                              ? "me-3"
                                                              : "ms-3"
                                                          }
                                                        >
                                                          <a
                                                            className="text-decoration-none text-primaryClient"
                                                            href={`https://twitter.com/${tweet.displayName}`}
                                                            target="_blank"
                                                            title="Click to view profile"
                                                          >
                                                            <p className="m-0">
                                                              {
                                                                tweet.displayName
                                                              }
                                                            </p>
                                                          </a>
                                                          <a
                                                            className="text-decoration-none text-primaryClient"
                                                            href={`https://twitter.com/${tweet.displayName}`}
                                                            target="_blank"
                                                            title="Click to view profile"
                                                          >
                                                            <p className="m-0">
                                                              {tweet.screenName}
                                                            </p>
                                                          </a>
                                                          <p className="text-primaryGray fs-8 font-italic">
                                                            {moment(
                                                              parseInt(
                                                                tweet.dateCreated
                                                              )
                                                            ).fromNow()}
                                                          </p>
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col>
                                                          <div
                                                            className="mb-3"
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                tweet.text,
                                                            }}
                                                          />
                                                          <div
                                                            className={
                                                              tweet.image
                                                                ? "mb-0"
                                                                : "mb-5"
                                                            }
                                                          ></div>
                                                          {tweet.image && (
                                                            <div className="d-flex align-items-center justify-content-center mb-3 mt-0">
                                                              <img
                                                                className="tweetAddedImage"
                                                                src={
                                                                  tweet.image
                                                                }
                                                              />
                                                            </div>
                                                          )}
                                                        </Col>
                                                      </Row>
                                                    </SwiperSlide>
                                                  ))}
                                            </Swiper>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        )}
                    </>
                  </Row>}
              </>

            </section >
          )}
        </>
      )}
    </>
  );
};
