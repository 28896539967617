import React, { useReducer, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { listingInitialState, listingReducer } from "../../shared/reducers/listingReducer";
import axios from "../../../config/axios";
import { BiErrorCircle } from "react-icons/bi";
const ErrorPage = ({ slug, url }) => {
  const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));
  const pathname = window.location.pathname;
  function doesPathnameMatch(pathname) {
    // Regular expression pattern to match "about" or "disclaimer" anywhere in the pathname
    const pattern = /\/(about|disclaimer|sponsors-partners|contact-us|speakers|highlights)\//i;
    const pattern2 = /\/(1|2|3|4|5|6|7|8|9|agenda)\//i;
    const pattern3 = /\/(registration)\//i;
    if (pattern.test(pathname)) {
    
      return 1
    } else if (pattern2.test(pathname)) {
      
      return 2
    } else if (pattern3.test(pathname)) {
      
      return 3
    } else { return 4 }


  }
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center text-center w-100 p-5 h-100">
        <div className="mt-4 pt-5 noItemsFound errorPageContainer">
          <div className="d-flex align-items-center justify-content-center ">

            <BiErrorCircle className="errorIcon" />
          </div>
          <h1>Page Not Found</h1>
          <p>
            The link you clicked may be broken or the page may have been removed. <br />

          </p>

          {doesPathnameMatch(pathname) != 4 &&
            <p> Visit the <Link to={slug != undefined && url != undefined ? `/${slug}/${url}` : slug != undefined ? `/${slug}` : `/`} className="nounderline"><a className="text-tertiaryClient">
              Home page
            </a>{" "}</Link></p>}


        </div>



      </div>
    </>
  );
};

export default ErrorPage;
