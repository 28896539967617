import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "./countDown.scss";
import { HoverWhite } from "../../../../shared/buttons/Buttons";
import Countdown from "./CountDown";
import { useTranslation } from "react-i18next";
import { clientFeatureAllowed, featureAllowed, generalFeatureAllowed } from "../../../../../Helper/featuresHelper";
import { formattedLocation } from "../../../../../Helper/locationHelper";
const CountDownHolder = () => {
  const pathname = window.location.pathname;
  const button_style = {
    width: "179.02px",
    height: "52.02px",
  };
  const { t, i18n } = useTranslation();
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  function doesPathnameMatch(pathname) {
    // Regular expression pattern to match "about" or "disclaimer" anywhere in the pathname
    const pattern = /\/(about|disclaimer|sponsors-partners|contact-us|speakers|highlights)\//i;
    const pattern2 = /\/(1|2|3|4|5|6|7|8|9)\//i;
    const pattern3 = /\/(registration)\//i;
    if (pattern.test(pathname)) {
      
      return 1
    } else if (pattern2.test(pathname)) {
     
      return 2
    } else if (pattern3.test(pathname)) {
      
      return 3
    } else { return 4 }


  }
  const targetRef = useRef(null);
  useEffect(() => {
    // Scroll smoothly to the target element on load
    if (targetRef.current && doesPathnameMatch(pathname) != 4) {
      setTimeout(() => {
        targetRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);

    }
  }, []); // Run only once on component mount
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);


  return (
    <>

      {screen.content && (
        <div className="paddingContainerCountDown" ref={targetRef}>
          <div className="contentContainerCountDown">
            {" "}
            <Row
              className={`countdownContainer py-3 ${i18n.language === "ar" ? "arabic" : ""
                }`}
            >
              <Col className="me-auto" xl={screen.content && screen.content.activity && screen.content.activity.firstDayDateTime && featureAllowed("showCountDownTimer") && generalFeatureAllowed("location") && clientFeatureAllowed("location") && featureAllowed("showViewLocationButton") && screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapLink ? 5 : screen.content && screen.content.activity && screen.content.activity.firstDayDateTime && featureAllowed("showCountDownTimer") ? 8 : generalFeatureAllowed("location") && clientFeatureAllowed("location") && featureAllowed("showViewLocationButton") && screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapLink ? 8 : 12} lg={12} md={12} xs={12}
              >
                {screen.content.activity &&
                  <>
                    <h2

                      className="heading_text text-primaryWhite"
                    >
                      {/* From 7 to 10 February */}
                      {i18n.language === "ar"
                        ? screen.content.activity.formattedDateArabic
                        : screen.content.activity.formattedDate}
                      {screen.content.activity.formattedDate && screen.content.activity.location && screen.content.activity.location.city && ", "}
                      {i18n.language === "ar"
                        ? screen.content.activity.location && screen.content.activity.location.nameArabic
                        : `${screen.content.activity.location && screen.content.activity.location.name}`}

                    </h2>
                    <h6 className="text-primaryWhite">{formattedLocation()}</h6>

                  </>
                }


                {/* {events.map((event) => (
                <h2 key={event.id} className="heading_text text-primaryWhite">
                  {i18n.language === "ar" ? event.venue_idsAr : event.venue_ids}
                </h2>
              ))} */}
              </Col>
              {generalFeatureAllowed("location") && clientFeatureAllowed("location") && <>
                {screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapLink && <>
                  {featureAllowed("showViewLocationButton") &&
                    <Col
                      xl={3}
                      lg={7}
                      md={6}
                      xs={12}
                      sm={6}
                      className="ps-xl-4 locationButton"
                    >

                      <a
                        href={screen.content.activity.location.googleMapLink}
                        title={
                          i18n.language === "ar"
                            ? "انقر لعرض الموقع"
                            : "Click to view location "
                        }
                        target="_blank"
                        className={screen.content && screen.content.activity && screen.content.activity.firstDayDateTime && featureAllowed("showCountDownTimer") ? "subFooterClickHereLink":"subFooterClickHereLink end"}
                      >
                        <HoverWhite
                          name={screen.items && screen.items.buttonViewLocation}
                          style={button_style}
                        />
                      </a>

                    </Col>}
                </>

                }
              </>}
              {screen.content && screen.content.activity && screen.content.activity.firstDayDateTime && featureAllowed("showCountDownTimer") &&
                <Col
                  xl={4}
                  lg={5}
                  md={6}
                  sm={6}
                  className=" countdown_container"
                >
                  <h1 className="countdown_h1">

                    {screen.content && screen.content.activity && screen.content.activity.firstDayDateTime && <>
                      {featureAllowed("showCountDownTimer") &&
                        <Countdown key={screen.content.activity._id} date={screen.content.activity.firstDayDateTime} />}
                    </>

                    }
                  </h1>
                </Col>}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default CountDownHolder;
