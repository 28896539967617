import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CMSFormTextArea = (props) => {
  const {
    label,
    name,
    theme,
    required,
    description,
    placeholder,
    form,
    maxLength,
    ...rest
  } = props;
  const [items, setItems] = useState({});
  const { i18n } = useTranslation();
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  return (
    <div className="form-group position-relative">
      <label
        htmlFor={name}
        className={`${i18n.language === "ar" ? "arabic pe-2" : ""} ${required == true ? "mandatory_field ps-2 " : " "
          } ps-1 mb-0`}
      >
        {" "}
        {label}
      </label>
      <p
        className="ps-1"
        style={{
          fontStyle: "italic",
          fontSize: 11,
          color: "#555",
          marginBottom: "2px",
          marginTop: "1px",
          minHeight: 16,
        }}
      >{description &&
        (description)}
      </p>
      <Field
        as="textarea"
        id={name}
        name={name}
        rows={6}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`form-control customInput ${i18n.language === "ar" ? "arabic" : ""} textArea ${form.touched[name] && form.errors[name] ? "is-invalid" : ""
          }`}
        {...rest}
      />
      <div
        className={`maxLength mt-1 ${theme === "light" ? "cream" : ""} ${form.touched[name] && form.errors[name] ? "light" : ""
          } end`}
      >

        {maxLength
          ? form.values[name] && form.values[name].length +
          " / " +
          maxLength + " " +
          items.formTextAreaCharacterText
          : null}
      </div>
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="errorMessage">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormTextArea;
