import React, { useState, useEffect, useReducer } from "react";
import "./agenda.scss";
import { Row, Col } from "react-bootstrap";
import "swiper/scss";
import "../../../../../assets/scss/_swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useTranslation } from "react-i18next";
import { TiArrowBack, TiArrowForward } from "react-icons/ti";
import {
  listingInitialState,
  listingReducer,
} from "../../../../shared/reducers/listingReducer";
import axios from "../../../../../config/axios";
import BlockErrorMessage from "../../../../shared/errorMessages/BlockErrorMessage";
import { Link, useParams } from "react-router-dom";
import { getDateFromDay, getFileFromDay } from "../../../../../Helper/agendaHelper";
import { getStatusByDay } from "../../../../../Helper/featuresHelper";
import { GoClock } from "react-icons/go";
const Agenda = () => {

  var day = "0";
  const { t, i18n } = useTranslation();
  const { slug, url } = useParams();
  const [state, setState] = useState({
    agenda: [],
    items: {}
  });
  const [agendaData, setAgendaData] = useState([]);


  const content = JSON.parse(localStorage.getItem("content"));
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setState((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
  }, []);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));


    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`activities/agenda/${slug}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      if (res.data) {
        if (res.data.agendaSettings) {
          if (res.data.agendaSettings.type === "Per Event") {


            setState((prevState) => ({
              ...prevState,
              agenda: res.data.items.filter(item => item.day === 1),
              active: day ? day : 1,
            }));
          }
          else {

            setState((prevState) => ({
              ...prevState,
              agenda: res.data.items,
              active: day ? day : 1,
            }));
          }
        }

      }

    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }

  };

  const length = () => {
    const filteredItems = listingState.data && listingState.data.items.filter(item => item.showOnHome === "1" && item.status === "1" && getStatusByDay(parseInt(item.day), slug));
    const itemsCount = filteredItems.length;
    return itemsCount
  }
  useEffect(() => {
    loadItems();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      // Assuming state.agenda is available here
      const sortedAgenda = state.agenda && state.agenda.sort((a, b) => a.day - b.day || a.sortOrder - b.sortOrder);
      const updatedAgenda = await Promise.all(sortedAgenda.map(async (agenda) => {
        const dayStatus = await getStatusByDay(parseInt(agenda.day), slug);
        return { ...agenda, dayStatus };
      }));
      setAgendaData(updatedAgenda);
    };

    fetchData();
  }, [state.agenda, slug]);
  return (
    <>
      {listingState.status !== "fetching" && (
        <>

          {listingState.error && listingState.status === "fetchFailed" ? (
            <BlockErrorMessage error={listingState.error} />
          ) : length() > 0 && (
            <div className="paddingContainerAgenda">
              <div
                className={`contentContainerAgenda ${i18n.language === "ar" ? "arabic" : "english"
                  }`}
              >
                <h2 className="heading_text">{state.items && state.items.menuAgendaText} </h2>
                {listingState.data && listingState.data.items && listingState.data.items.length <= 0 && (
                  <BlockErrorMessage message1="Agenda Not Available" />
                )}

                {listingState.data && listingState.data.items && listingState.data.items.length > 0 && length() > 0 && (
                  <>
                    {" "}
                    <div className="containerBackupAgenda bg-secondaryGray">
                      <Swiper
                        className="swiperBackup "
                        navigation={
                          length() > 4
                            ? {
                              nextEl: ".review-swiper-button-prev",
                              prevEl: ".review-swiper-button-next",
                            }
                            : false
                        }
                        modules={[Navigation]}
                        spaceBetween={35}
                        breakpoints={{
                          300: {
                            slidesPerView: 1,
                            width: 300,
                          },
                          450: {
                            slidesPerView: 1,
                            width: 350,
                          },
                          600: {
                            slidesPerView: 1,
                            width: 434,
                          },
                          768: {
                            slidesPerView: 2,
                            width: 640,
                          },
                          992: {
                            slidesPerView: 3,
                          },

                          1025: {
                            slidesPerView: length() > 4 ? 4 : length(),
                          },
                        }}
                      >
                        {agendaData && agendaData.sort((a, b) => a.day - b.day || a.sortOrder - b.sortOrder).map((agenda, index) => {
                          if (agenda.showOnHome === "1" &&
                            agenda.status === "1" && agenda.dayStatus) return (
                              <SwiperSlide
                                key={Math.random()}
                                className="bg-secondaryGray"
                              >
                                <p hidden>{(day = agenda.day)}</p>

                                <div className="agenda_slider">
                                  <div
                                    className={`agenda_container day${agenda.day} length${length()} ${getFileFromDay(listingState.data && listingState.data.files && listingState.data.files, agenda.day) ? "hover" : ""}`}
                                  >
                                    {content && content.activity && content.activity.days && content.activity.days.filter((e) => e.status != "0").length > 1 ? <>
                                      <h4 className="day">
                                        {state.items && state.items.agendaDayText} {agenda.day}
                                      </h4>
                                      <h5 className="date">
                                        {" "}
                                        {i18n.language === "ar"
                                          ? getDateFromDay(agenda.day)
                                          : getDateFromDay(agenda.day)}

                                      </h5>
                                    </> : <h4 className="day mb-4 mt-3">
                                      <GoClock className="me-1" /> {agenda.startTime && agenda.endTime ? `${agenda.startTime} - ${agenda.endTime}` : agenda.startTime ? agenda.startTime : null}
                                    </h4>}
                                    {content && content.activity && content.activity.days && content.activity.days.filter((e) => e.status != "0").length > 1 ? <> <i className="d-flex align-items-center"><GoClock className="me-1" /> {agenda.startTime && agenda.endTime ? `${agenda.startTime} - ${agenda.endTime}` : agenda.startTime ? agenda.startTime : null}</i> </> : null}


                                    <h6 className="session">
                                      {i18n.language === "ar"
                                        ? agenda.titleArabic
                                        : agenda.title}
                                    </h6>

                                    <h6 className="linkToView">
                                      <Link
                                        to={slug != undefined && url != undefined ? `/agenda/${slug}/${url}/${agenda.day}` : slug != undefined ? `/agenda/${slug}/${agenda.day}` : `/agenda/${agenda.day}`}
                                        rel="noopener noreferrer"
                                        title={
                                          i18n.language === "ar"
                                            ? "عرض جدول الأعمال الكامل"
                                            : "View full agenda"
                                        }
                                      >
                                        {state.items && state.items.linkViewAllText}
                                      </Link>
                                    </h6>
                                    {getFileFromDay(listingState.data && listingState.data.files && listingState.data.files, agenda.day) ?
                                      <div
                                        className="iconDiv"
                                        title={
                                          i18n.language === "ar"
                                            ? "تحميل جدول الأعمال"
                                            : "Download agenda"
                                        }
                                      >
                                        <a
                                          href={getFileFromDay(listingState.data && listingState.data.files && listingState.data.files, agenda.day)}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          download
                                        >
                                          <svg
                                            className="download"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19.682"
                                            height="24"
                                            viewBox="0 0 19.682 24"
                                          >
                                            <g
                                              id="Group_96"
                                              data-name="Group 96"
                                              transform="translate(-2122.318 -1299)"
                                            >
                                              <path
                                                id="Path_163"
                                                data-name="Path 163"
                                                d="M2142,1310.993q0,4.956,0,9.913a2,2,0,0,1-2.037,2.089q-7.794.01-15.59,0a2.021,2.021,0,0,1-2.054-2.127c.008-5.089-.008-10.179.007-15.269a1.213,1.213,0,0,1,.322-.8q2.978-2.817,6.005-5.58a1,1,0,0,1,.624-.213q5.33-.019,10.661-.006a2.021,2.021,0,0,1,2.063,2.076v9.912Zm-18.567-4.942c-.012.176-.022.264-.022.353q0,7.125,0,14.252c0,1.036.261,1.277,1.313,1.277H2138.6c.447,0,.894.01,1.34-.005a.9.9,0,0,0,.969-.969c.008-.2.009-.393.009-.589v-18.753c0-.233.006-.466-.013-.7a.826.826,0,0,0-.727-.8,3.423,3.423,0,0,0-.641-.042q-4.527,0-9.053,0c-.17,0-.341.017-.547.028,0,1.454-.01,2.846,0,4.237.01,1.208-.486,1.713-1.711,1.71C2126.64,1306.048,2125.055,1306.051,2123.43,1306.051Zm5.4-5.531-4.809,4.452c1.586,0,3.005.011,4.423-.017a.541.541,0,0,0,.369-.379C2128.846,1303.279,2128.834,1301.981,2128.834,1300.52Z"
                                              />
                                              <path
                                                id="Path_164"
                                                data-name="Path 164"
                                                d="M2131.807,1317.567V1317q0-5.171,0-10.341c0-.161-.054-.361.022-.473.134-.2.346-.469.528-.471s.374.288.5.489c.073.118.019.314.019.475q0,5.143,0,10.287v.72c.209-.181.338-.28.452-.394q1.272-1.266,2.537-2.539c.274-.275.575-.5.913-.141s.106.642-.169.918q-1.894,1.893-3.778,3.8a.579.579,0,0,1-.927.038q-2.12-2.124-4.221-4.264c-.12-.121-.271-.293-.265-.436.006-.182.124-.476.253-.512a.808.808,0,0,1,.665.123c.709.654,1.379,1.348,2.057,2.034.427.431.842.874,1.262,1.311Z"
                                              />
                                            </g>
                                          </svg>
                                        </a>
                                      </div> : null}
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                        })}

                        {/* {state.agenda && state.agenda.sort((a, b) => a.day - b.day || a.sortOrder - b.sortOrder)
                          .map((agenda, index) =>
                            agenda.showOnHome === "1" &&
                            agenda.status === "1" && getStatusByDay(parseInt(agenda.day)) && (
                              <SwiperSlide
                                key={Math.random()}
                                className="bg-secondaryGray"
                              >
                                <p hidden>{(day = agenda.day)}</p>

                                <div className="agenda_slider">
                                  <div
                                    className={`agenda_container day${agenda.day} length${length()} ${getFileFromDay(listingState.data && listingState.data.files && listingState.data.files, agenda.day) ? "hover" : ""}`}
                                  >
                                    {content && content.activity && content.activity.days && content.activity.days.filter((e) => e.status != "0").length > 1 ? <>
                                      <h4 className="day">
                                        {state.items && state.items.agendaDayText} {agenda.day}
                                      </h4>
                                      <h5 className="date">
                                        {" "}
                                        {i18n.language === "ar"
                                          ? getDateFromDay(agenda.day)
                                          : getDateFromDay(agenda.day)}

                                      </h5>
                                    </> : <h4 className="day mb-4 mt-3">
                                      <GoClock className="me-1" /> {agenda.startTime && agenda.endTime ? `${agenda.startTime} - ${agenda.endTime}` : agenda.startTime ? agenda.startTime : null}
                                    </h4>}
                                    {content && content.activity && content.activity.days && content.activity.days.filter((e) => e.status != "0").length > 1 ? <> <i className="d-flex align-items-center"><GoClock className="me-1" /> {agenda.startTime && agenda.endTime ? `${agenda.startTime} - ${agenda.endTime}` : agenda.startTime ? agenda.startTime : null}</i> </> : null}


                                    <h6 className="session">
                                      {i18n.language === "ar"
                                        ? agenda.titleArabic
                                        : agenda.title}
                                    </h6>

                                    <h6 className="linkToView">
                                      <Link
                                        to={slug != undefined && url != undefined ? `/agenda/${slug}/${url}/${agenda.day}` : slug != undefined ? `/agenda/${slug}/${agenda.day}` : `/agenda/${agenda.day}`}
                                        rel="noopener noreferrer"
                                        title={
                                          i18n.language === "ar"
                                            ? "عرض جدول الأعمال الكامل"
                                            : "View full agenda"
                                        }
                                      >
                                        {state.items && state.items.linkViewAllText}
                                      </Link>
                                    </h6>
                                    {getFileFromDay(listingState.data && listingState.data.files && listingState.data.files, agenda.day) ?
                                      <div
                                        className="iconDiv"
                                        title={
                                          i18n.language === "ar"
                                            ? "تحميل جدول الأعمال"
                                            : "Download agenda"
                                        }
                                      >
                                        <a
                                          href={getFileFromDay(listingState.data && listingState.data.files && listingState.data.files, agenda.day)}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          download
                                        >
                                          <svg
                                            className="download"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19.682"
                                            height="24"
                                            viewBox="0 0 19.682 24"
                                          >
                                            <g
                                              id="Group_96"
                                              data-name="Group 96"
                                              transform="translate(-2122.318 -1299)"
                                            >
                                              <path
                                                id="Path_163"
                                                data-name="Path 163"
                                                d="M2142,1310.993q0,4.956,0,9.913a2,2,0,0,1-2.037,2.089q-7.794.01-15.59,0a2.021,2.021,0,0,1-2.054-2.127c.008-5.089-.008-10.179.007-15.269a1.213,1.213,0,0,1,.322-.8q2.978-2.817,6.005-5.58a1,1,0,0,1,.624-.213q5.33-.019,10.661-.006a2.021,2.021,0,0,1,2.063,2.076v9.912Zm-18.567-4.942c-.012.176-.022.264-.022.353q0,7.125,0,14.252c0,1.036.261,1.277,1.313,1.277H2138.6c.447,0,.894.01,1.34-.005a.9.9,0,0,0,.969-.969c.008-.2.009-.393.009-.589v-18.753c0-.233.006-.466-.013-.7a.826.826,0,0,0-.727-.8,3.423,3.423,0,0,0-.641-.042q-4.527,0-9.053,0c-.17,0-.341.017-.547.028,0,1.454-.01,2.846,0,4.237.01,1.208-.486,1.713-1.711,1.71C2126.64,1306.048,2125.055,1306.051,2123.43,1306.051Zm5.4-5.531-4.809,4.452c1.586,0,3.005.011,4.423-.017a.541.541,0,0,0,.369-.379C2128.846,1303.279,2128.834,1301.981,2128.834,1300.52Z"
                                              />
                                              <path
                                                id="Path_164"
                                                data-name="Path 164"
                                                d="M2131.807,1317.567V1317q0-5.171,0-10.341c0-.161-.054-.361.022-.473.134-.2.346-.469.528-.471s.374.288.5.489c.073.118.019.314.019.475q0,5.143,0,10.287v.72c.209-.181.338-.28.452-.394q1.272-1.266,2.537-2.539c.274-.275.575-.5.913-.141s.106.642-.169.918q-1.894,1.893-3.778,3.8a.579.579,0,0,1-.927.038q-2.12-2.124-4.221-4.264c-.12-.121-.271-.293-.265-.436.006-.182.124-.476.253-.512a.808.808,0,0,1,.665.123c.709.654,1.379,1.348,2.057,2.034.427.431.842.874,1.262,1.311Z"
                                              />
                                            </g>
                                          </svg>
                                        </a>
                                      </div> : null}
                                  </div>
                                </div>
                              </SwiperSlide>
                            )
                          )} */}
                        {length() > 4 && (
                          <>
                            <TiArrowBack className="review-swiper-button-prev" />
                            <TiArrowForward className="review-swiper-button-next" />
                          </>
                        )}
                      </Swiper>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Agenda;
