import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import axios from "../../../config/axios";
import { listingInitialState, listingReducer } from "../reducers/listingReducer";

const CountriesInput = ({
  listType,
  name,
  type,
  placeholder,
  onChange,
  onBlur,
  className,
  description,
  variant,
  value,
  error,
  children,
  label,
  required,
  touched,
  errors,
  mandatory,
  validate,
  as,
  md,
  rows,
  controlId,
  options,
  disabled,
  readOnly,
  ref,
  ...props
}) => {

  const [countryList, setCountryList] = useState([]);
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  // const API = "https://jsonplaceholder.typicode.com/posts";
  // const fetchPost = () => {
  //   fetch(API)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setCountryList(res);
  //     });
  // };
  // useEffect(() => {
  //   fetchPost();
  // }, []);

  // const countryOptions = countryList.map((item) => {
  //   return (
  //     <option key={item.id} value={item.id}>
  //       {item.title}
  //     </option>
  //   )
  // })
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`countries`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setCountryList(res.data);

    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const loadCountries = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`general/countries-list`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

      setCountryList(res.data.items);

    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    // loadItems();
    loadCountries();
  }, []);
  const { t, i18n } = useTranslation();
  const countryOptions = countryList.map((item, id) => {
    return (
      <option key={id} value={item.name}>
        {item.name}
      </option>
    );
  });
  return (
    <React.Fragment>
      <Field name={name} validate={validate}>
        {({ field, form: { touched, errors }, meta }) => (
          <Form.Group md={md} controlId={controlId}>
            {label && (
              <Form.Label
                className={`${i18n.language === "ar" ? "arabic pe-2" : ""} ${required == true ? "mandatory_field ps-2 " : " "
                  } ps-1 mb-0`}
              >
                {label}
              </Form.Label>
            )}
            <p
              className="ps-1"
              style={{
                fontStyle: "italic",
                fontSize: 11,
                color: "#555",
                marginBottom: "2px",
                marginTop: "1px",
                minHeight: 16,
              }}
            >{description &&
              (description)}
            </p>
            <Form.Select
              className={`form-control ${i18n.language === "ar" ? "arabic pe-2" : ""
                } customInput ${touched[name] && errors[name] ? "is-invalid" : " "
                }`}
              name={name}
              ref={ref}
              as={as}
              type={type}
              label={label}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              options={options}
              errors={errors}
              required={required}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              autoComplete="off"
            >
              <option values="">
                {items.formPlaceholderCountry}
              </option>
              {countryOptions}
            </Form.Select>
            <ErrorMessage
              component="div"
              name={name}
              className="errorMessage"
            />
          </Form.Group>
        )}
      </Field>
    </React.Fragment>
  );
};

CountriesInput.defaultProps = {
  type: "select",
  className: "",
};
CountriesInput.propTypes = {
  name: PropTypes.string.isRequired,
  // type: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "number",
    "email",
    "tel",
    "password",
    "select",
  ]),
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default CountriesInput;
