import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
function CMSFormCheckBox(props) {
  const { label,value, name, options, form, required, ...rest } = props;

  return (
    <div>
      <div
        className={` ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
      >
        <div key={label} className="checkBoxContainer mb-1 pb-1 ms-2">
          <Field
            name={name}
            type="checkbox"
            id={value}
            className="checkbox"
            value={value}
            defaultChecked={form.values[name].includes(value)}
          />
          <label
            htmlFor={name}
            className={`${
              required == true ? "mandatory_field ps-2 " : " "
            } ps-1 mb-3`}
          >
            {" "}
            {label}
          </label>
        </div>
      </div>
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
}

export default CMSFormCheckBox;
