import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import buttonLoad from "../../../../assets/images/buttonload.json";
import { BorderBlue } from "../../../shared/buttons/Buttons";
import {
  listingInitialState,
  listingReducer,
} from "../../../shared/reducers/listingReducer";
import { BiErrorCircle } from "react-icons/bi";
import axios from "../../../../config/axios";
import { ContainerLoading } from "../../../shared/loading/Loading";
import BlockErrorMessage from "../../../shared/errorMessages/BlockErrorMessage";
import { useParams } from "react-router-dom";
const imagePerRow = 6;
const PartnerListing = ({ state, setState }) => {

  const { t, i18n } = useTranslation();
  const handleMoreImage = () => {

    setScreen((prevState) => ({
      ...prevState,
      imageLoading: true,
    }));
    setTimeout(() => {
      setScreen((prevState) => ({
        ...prevState,
        next: screen.next + imagePerRow,
        imageLoading: false,
      }));
    }, 2000);
  };

  const [screen, setScreen] = useState({
    items: {},
    imageLoading: false,
    next: imagePerRow
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }

  }, []);
  const [partnerState, partnerDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const { slug, url } = useParams();
  const loadPartner = async () => {
    const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));


    partnerDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`activities/partners/${slug}`); //
      partnerDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data.items,
      });
      setState((prevState) => ({
        ...prevState,
        partner: res.data?.items?.length,
      }));
    } catch (error) {
      partnerDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }

  };

  useEffect(() => {
    loadPartner();

  }, []);
  return (
    <>
      {partnerState.isLoading && <ContainerLoading />}
      {partnerState.status !== "fetching" && (
        <>

          {partnerState.data
            ?.map((partner, index) => {
              if (index === 0) {
                if (partner.items && partner.items.length > 0) return (
                  <>
                    {partner.items && partner.items.length > 0 &&
                      <h2 className="heading_text text-tertiaryClient pt-5  pb-3">
                        {screen.items && screen.items.sectionPartnersText}
                      </h2>}
                  </>

                );
              }

            })}
          {partnerState.error && partnerState.status === "fetchFailed" ? (
            <BlockErrorMessage error={partnerState.error} />
          ) : (
            <>
            
              <Row
                className={`mx-auto  ${partnerState.data.length > 0 ? "pb-5" : ""
                  }`}
              >
                <Col>
                  {partnerState.data
                    ?.map((partner, index) => {
                      if (partner.items && partner.items.length > 0) return (
                        <Row className={partnerState.data.length > 1 ? "bg-lightGray pt-0 mb-2" : "pt-0"} key={Math.random()}>
                          {partnerState.data.length > 1 &&
                            <Col md={12} className="boxHolder">
                              <div class="ribbon-2">{partner.name}</div>

                            </Col>}

                          {partner.items && partner.items.length > 0 && partner.items.map((partnerItem, index) => {
                            return (
                              <Col
                                xl={2}
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                key={index}
                                className="px-0 imageContainer mx-3"
                              >
                                {partnerItem.logo ? partnerItem.website ? <a href={partnerItem.website} title="Visit Website" target="_blank">
                                  <div className="sponsor_img ">
                                    <img
                                      className=""
                                      src={partnerItem.logo ? process.env.REACT_APP_BACKEND_URL + "public/" + partnerItem.logo : null}
                                      alt={partnerItem.name}
                                      loading="lazy"
                                    />
                                  </div>
                                </a> :
                                  <div className="sponsor_img ">
                                    <img
                                      className=""
                                      src={partnerItem.logo ? process.env.REACT_APP_BACKEND_URL + "public/" + partnerItem.logo : null}
                                      alt={partnerItem.name}
                                      loading="lazy"
                                    />
                                  </div>


                                  : partnerItem.website ? <a href={partnerItem.website} title="Visit Website" className="sponsor_img font-bold">{partnerItem.name}</a> : <p className="sponsor_img font-bold">{partnerItem.name}</p>}
                              </Col>
                            )
                          })}

                        </Row>

                      );
                    })}{" "}
                </Col>

                {/* <div className="mx-auto">
                  {screen.imageLoading == true && (
                    <Lottie
                      animationData={buttonLoad}
                      className="mx-auto"
                      style={{ width: "100px" }}
                      loop={true}
                    />
                  )}
                </div>
                {screen.next < partnerState.data?.length && screen.imageLoading != true && (
                  <a
                    title="View more"
                    className="mb-3 mx-auto mt-3 text-decoration-none"
                    onClick={handleMoreImage}
                  >
                    <BorderBlue
                      name={screen.items && screen.items.buttonLoadMore}
                      style={button_style}
                      container={container_style}
                    />
                  </a>
                )} */}
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PartnerListing;
