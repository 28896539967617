import React, { useState, useEffect, useRef, useReducer } from "react";
import CMSFormInput from "../../../shared/formFields/CMSFormInput";
import CMSFormTelephone from "../../../shared/formFields/CMSFormTelephone";
import CMSFormTextArea from "../../../shared/formFields/CMSFormTextArea";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiFillCheckCircle } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import {
  createInitialState,
  createReducer,
} from "../../../shared/reducers/createReducer";
import axios from "../../../../config/axios";
import { ContainerLoading, SubmissionLoading } from "../../../shared/loading/Loading";
import { BiErrorCircle } from "react-icons/bi";
const ContactForm = () => {
  const [items, setItems] = useState({});
  const [error, setError] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  var initialValues = JSON.parse(localStorage.getItem("contactValues"));
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`general/contact-us`,
        data
      ); //

      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      localStorage.removeItem("contactValues")
      executeScroll();
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    } catch (error) {

      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      initialValues = JSON.parse(localStorage.getItem("contactValues"));
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const currentActivity = JSON.parse(localStorage.getItem("currentActivity"));


  const handleScroll = (error) => {

    if (Object.keys(error).length > 0) {
      const element = document.getElementsByName(Object.keys(error)[0])[0];
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 20);
    } else {
      executeScroll()
    }


  };
  return (
    <>
      <div className="mb-0 mt-5 pt-xl-0 pt-lg-0 pt-md-0 pt-4 "></div>
      <div ref={myRef}>
        {createState.isSubmitted === true ? (
          <div className="mt-4 d-flex align-items-center justify-content-center flex-column successAlert">
            <AiFillCheckCircle className="successIcon" />
            <h4 className="text-tertiaryClient my-2">{items.formContactMessage}</h4>
            <p>{items.formContactMessageBody}</p>
          </div>
        ) : createState.status === "submitFailed" ? (
          <div className="noItemsFound">
            <BiErrorCircle className="errorIcon" />
            <h1>Error!</h1>
            <p>Please try again after some time.</p>
          </div>
        ) : (
          <div >

            <Formik
              initialValues={initialValues && initialValues.clientId ? initialValues : {
                clientId: currentActivity.clientId,
                activityId: currentActivity.activityId,
                firstName: "",
                lastName: "",
                email: "",
                mobile: "",
                comments: "",
                company: "",
                designation: "",
              }}

              validationSchema={Yup.object().shape({
                firstName: Yup.string().required(items.formErrorFirstName),
                lastName: Yup.string().required(items.formErrorLastName),
                email: Yup.string()
                  .email(items.formErrorEmailValid)
                  .required(items.formErrorEmail),
                mobile: Yup.string()
                  .required(items.formErrorMobile),
                comments: Yup.string()
                  .min(6, items.formErrorMessageLength)
                  .required(items.formErrorMessage),
              })}
              enableReinitialize={true}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values) => {
                // same shape as initial values
                // setSubmitted(true);
                localStorage.setItem(
                  "contactValues",
                  JSON.stringify(values)
                );
                onSubmit(values);

              }}
            >
              {(form) => (
                <Form onSubmit={form.handleSubmit}>
                  {createState.status === "submitting" && (
                    <SubmissionLoading />
                  )}
                  <Row>

                    <Col xs={12} xl={6} md={6} className="mb-4">
                      <CMSFormInput
                        name="firstName"
                        label={items.formLabelFirstName}
                        form={form}
                        required={true}
                      />
                    </Col>
                    <Col xs={12} xl={6} md={6} className="mb-4">
                      <CMSFormInput
                        name="lastName"
                        label={items.formLabelLastName}
                        form={form}
                        required={true}
                      />
                    </Col>

                    <Col xs={12} xl={6} md={6} className="mb-4">
                      <CMSFormInput
                        name="email"
                        label={items.formLabelEmail}
                        form={form}
                        required={true}
                      />
                    </Col>
                    <Col xs={12} xl={6} md={6} className="mb-4">
                      <CMSFormTelephone
                        name="mobile"
                        placeholder="mobile"
                        errorState={error}
                        setErrorState={setError}
                        label={items.formLabelMobile}
                        form={form}
                        required={true}
                      />
                    </Col>
                    <Col xs={12} xl={6} md={6} className="mb-4">
                      <CMSFormInput
                        name="company"
                        label={items.formLabelCompany}
                        form={form}
                      />
                    </Col>

                    <Col xs={12} xl={6} md={6} className="mb-4">
                      <CMSFormInput
                        name="designation"
                        label={items.formLabelDesignation}
                        form={form}
                      />
                    </Col>

                    <Col xs={12} className="mb-4">
                      <CMSFormTextArea
                        name="comments"
                        required={true}
                        label={items.formLabelComments}
                        // placeholder={items.formLabelComments}
                        // description={items.formCommentsDescription}
                        maxLength={320}
                        form={form}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col className="">
                      <button
                        className="BorderPrimaryBlue "
                        type="submit"
                        onClick={(e) => {
                          form.handleSubmit(e);
                          handleScroll(form.errors);
                        }}
                      >
                        {items.formButtonSubmit}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>

          </div>
        )}
      </div>






    </>
  );
};

export default ContactForm;
