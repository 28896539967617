import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import Lottie from "lottie-react";
import twitterAnime from "../../../../assets/images/twitteranime.json";
import Animation from "./animation/Animation";
import { Link } from "react-router-dom";
import "./tweet.scss";
import { useTranslation } from "react-i18next";
import {
  listingInitialState,
  listingReducer,
} from "../../../shared/reducers/listingReducer";
import axios from "../../../../config/axios";
import { FaSquareXTwitter } from "react-icons/fa6";
function Tweet() {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const { i18n } = useTranslation();
  function doScaledTimeout(i) {
    setTimeout(function () {
      alert(i);
    }, i * 5000);
  }
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`event_settings_content`
      ); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    //loadItems();
  }, []);
  function getWordLength(word) {
    if(word)
    return word.length;
  }
  return (
    <Row
      className={`Hashtag-container  bg-primaryBlack text-white  mx-auto ${screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag ? null : "hide"
        }`}
    >
      <Col xl={12} className="mx-auto p-0">
        <div className="paddingContainerTweet">
          <div
            className={`contentContainerTweet ${i18n.language === "ar" ? "arabic" : "english"
              }`}
          >
            <Row className="d-flex py-1 pt-3 mt-1 pb-lg-3  align-items-start  me-0 ">
              <Col
                xl={getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 20 ? 4 : getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 10 ? 3 : 2}
                lg={getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 20 ? 5 : getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 10 ? 4 : 3}
                md={12}
                xs={12}
                className=" left-text hashtagCol mb-md-2 mb-xl-1 mb-lg-1 "
              >
                {screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag && (
                  <>
                    <p
                      className="hashtag  m-0 "
                      title={`Tweet with #${screen.content.activity.content.hashtag}`}
                    >
                      
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://twitter.com/intent/tweet?text=%20%23${screen.content.activity.content.hashtag}%20`}
                      >
                        <p className="hashtagHolder">

                            <p className="hashtagText">
                              #{screen.content.activity.content.hashtag}{" "}
                              <span className="ms-2 straightBar"> |</span>
                            </p>
                          
                        </p>{" "}
                      </a>{" "}
                    </p>
                  </>
                )}
              </Col>

              <Col
                xl={getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 20 ? 8 : getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 10 ? 9 : 10}
                lg={getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 20 ? 7 : getWordLength(screen.content && screen.content.activity && screen.content.activity.content && screen.content.activity.content.hashtag) > 10 ? 8 : 9}
                xs={12}
                md={12}
                sm={12}
                className="center-text p-0 d-flex"
              >

                <Animation />
              </Col>
              {/* <Col
                xl={2}
                lg={4}
                xs={12}
                md={8}
                sm={7}
                className={`right-text socialMediaWall  ${
                  listingState.data.socialMediaWall != "" ? "" : "hide"
                }`}
              >
                <Link
                  to="/"
                  className="linkToSocialMedia"
                  title="Visit social media wall"
                >
                  <p
                    className={
                      i18n.language === "ar"
                        ? "socialWallText arabic"
                        : "socialWallText "
                    }
                  >
                    {screen.items && screen.items.linkSocialMediaWallText}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={i18n.language === "ar" ? "me-2" : "ms-2"}
                        width="16.564"
                        height="16.232"
                        viewBox="0 0 16.564 16.232"
                      >
                        <g
                          id="Group_121"
                          data-name="Group 121"
                          transform="translate(-3039.436 -3224.513)"
                        >
                          <path
                            id="Path_176"
                            data-name="Path 176"
                            d="M3041.532,3238.9H3054v-6.038c.4-.1.5-.166.556-.138,2.675,1.4.754,3.788,1.076,5.677.109.637-1.218,2.151-1.946,2.19-4.079.214-8.181.2-12.263,0a2.837,2.837,0,0,1-1.856-2.039c-.184-3.986-.157-7.989-.015-11.979a2.523,2.523,0,0,1,1.489-1.9,32.379,32.379,0,0,1,5.5-.1c.3.008.592.405.888.622a4.5,4.5,0,0,1-.86.948c-.245.154-.65.054-.984.069-4.366.193-4.37.193-4.364,4.631q0,3.619.05,7.24A3.219,3.219,0,0,0,3041.532,3238.9Z"
                          />
                          <path
                            id="Path_177"
                            data-name="Path 177"
                            d="M3043.75,3234.692l8.586-7.973-3-1.229.277-.806h6.073c-.122,2.108.678,4.263-.521,6.028l-.952-.146-.407-2.911c-2.634,2.622-4.982,5.018-7.41,7.332a7.486,7.486,0,0,1-1.971.978Z"
                          />
                        </g>
                      </svg>
                    </span>
                  </p>
                </Link>
              </Col> */}
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Tweet;
