import React, { useState, useEffect, useReducer } from "react";
import BodyLayout from "../../layout/BodyLayout";
import { useTranslation } from "react-i18next";
import "./agendaPage.scss";
import { Col, Row, Table } from "react-bootstrap";
import { FiDownloadCloud } from "react-icons/fi";
import AgendaSpeakerModal from "./agendaPartials/AgendaSpeakerModal";
import InfoModal from "./agendaPartials/InfoModal";
import { BiErrorCircle } from "react-icons/bi";
import {
   detailsInitialState,
   detailsReducer,
} from "../../shared/reducers/detailsReducer";
import TopGrid from "./agendaPartials/TopGrid";
import Heading from "./agendaPartials/Heading";
import {
   listingInitialState,
   listingReducer,
} from "../../shared/reducers/listingReducer";
import BlockErrorMessage from "../../shared/errorMessages/BlockErrorMessage";
import { ContainerLoading } from "../../shared/loading/Loading";
import axios from "../../../config/axios";
import { useParams } from "react-router-dom";
import { clientFeatureAllowed, countDaysWithStatus1, generalFeatureAllowed, getStatusByDay, removeAndCapitalizeEachWord } from "../../../Helper/featuresHelper";
import ErrorPage from "../error/ErrorPage";
const AgendaPage = () => {
   const { i18n } = useTranslation();
   const [state, setState] = useState({
      loading: true,
      active: "1",
      agenda: [],
      speakers: [],
      items: {}
   });
   const [agendaData, setAgendaData] = useState([]);
   let { day, slug, url } = useParams();
   const [agendaState, agendaDispatch] = useReducer(
      listingReducer,
      listingInitialState
   );

   const loadAgenda = async () => {

      agendaDispatch({
         type: "FETCH_REQUEST",
      });
      try {
         const res = await axios.get(`activities/agenda/${slug}`); //
         agendaDispatch({
            type: "FETCH_SUCCESS",
            payload: res.data,
         });

         if (res.data) {
            if (res.data.agendaSettings) {
               if (res.data.agendaSettings.type === "Per Event") {


                  setState((prevState) => ({
                     ...prevState,
                     agenda: res.data.items.filter(item => item.day === 1),
                     active: day ? day : 1,
                  }));
               }
               else {

                  setState((prevState) => ({
                     ...prevState,
                     agenda: res.data.items,
                     active: day ? day : 1,
                  }));
               }
            }

         }

         const items = JSON.parse(localStorage.getItem(`activeAgendaDay${slug}`));

      } catch (error) {
         agendaDispatch({
            type: "FETCH_FAILURE",
            payload: error,
         });
      }

   };
   useEffect(() => {
      const fetchData = async () => {
         // Assuming state.agenda is available here
         const sortedAgenda = state.agenda && state.agenda.sort((a, b) => a.day - b.day || a.sortOrder - b.sortOrder);
         const updatedAgenda = await Promise.all(sortedAgenda.map(async (agenda) => {
            const dayStatus = await getStatusByDay(parseInt(agenda.day), slug);
            return { ...agenda, dayStatus };
         }));
         setAgendaData(updatedAgenda);
      };

      fetchData();
   }, [state.agenda, slug]);
   const array = [];
   agendaData &&
      agendaData
         .sort((a, b) => a.sortOrder - b.sortOrder)
         .map(function (item) {
            if (item.day === state.active) return array.push(item);
         });
   const breakouts = agendaData.reduce((acc, d) => {
      if (Object.keys(acc).includes(d.breakoutId)) return acc;

      acc[d.breakoutId] = agendaData.filter(
         (g) => g.breakoutId === d.breakoutId
      );
      return acc;
   }, {});
   delete breakouts.null;
   const [detailsState, detailsDispatch] = useReducer(
      detailsReducer,
      detailsInitialState
   );
   const [isVisible, setIsVisible] = useState(true);

   useEffect(() => {
      const handleVisibilityChange = () => {

         setIsVisible(!document.hidden);
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
         document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
   }, []);
   useEffect(() => {
      loadAgenda();
   }, [])

   useEffect(() => {

      // loadAgenda();
      const items = JSON.parse(localStorage.getItem("constants"));
      if (items) {

         setState((prevState) => ({
            ...prevState,
            items: items,
         }));
      }
   }, [i18n.language, slug, isVisible]);

   let result = agendaData.filter((e) => e.dayStatus != "0").map(({ day }) => day);
   const max = result.reduce((a, b) => Math.max(a, b), -Infinity);

   useEffect(() => {
      document.title = `${removeAndCapitalizeEachWord(slug)} | Agenda`;
   }, [slug]);
   function hasDuplicateStartTimes(array) {
      const x = array.some((obj, index) => {
         const firstIndex = array.findIndex(item => item.startTime === obj.startTime);
         return firstIndex !== index;
      });
      return x
   }
   return (
      <>
         {generalFeatureAllowed("agenda") ? <BodyLayout>
            {agendaState.isLoading &&
               <ContainerLoading />
            }

            {agendaState.status !== "fetching" && (
               <>
                  <>
                     {detailsState.isFetching === false && (
                        <div className="paddingContainerAgendaPage">
                           <div
                              className={`contentContainerAgendaPage ${i18n.language === "ar" ? "arabic" : ""
                                 } `}
                           >
                              <div
                                 className={`agendaContent ${i18n.language === "ar" ? "arabic" : ""
                                    }`}
                              >
                                 {agendaState.error &&
                                    agendaState.status === "fetchFailed" ? (
                                    <div className="py-0">
                                       <BlockErrorMessage />
                                    </div>
                                 ) : (
                                    <Row className="agendaDisplayContainer">
                                       {(state.agenda.length <= 0) ? (
                                          <>
                                             {i18n.language === "ar" ? (
                                                <div className="noItemsFound">
                                                   <BiErrorCircle className="errorIcon" />
                                                   <h1> عذرا، هناك خطأ ما </h1>
                                                   <p>
                                                      سنعمل على إصلاح ذلك على الفور.وفي الوقت
                                                      نفسه، يجوز لك
                                                   </p>
                                                </div>
                                             ) : (
                                                <div className="noItemsFound">
                                                   <BiErrorCircle className="errorIcon" />
                                                   <h1> Agenda Not Available </h1>
                                                   <p>
                                                      {"\n"}
                                                      Please try again after some time.
                                                   </p>
                                                </div>
                                             )}
                                          </>
                                       ) : null}

                                       {state.agenda.length > 0 && (
                                          <>
                                             <Heading />
                                             <Col xl={12} className={max > 1 ? "mx-auto mt-4 pt-2" : ""}>
                                                <TopGrid
                                                   parent={state}
                                                   parentState={setState}
                                                   maxDay={max}
                                                />

                                             </Col>
                                             <Col xl={12} className="mx-auto mt-4 pt-2">
                                                <Table
                                                   size="sm"
                                                   className="agendaTable mt-xl-2"
                                                >
                                                   <tbody>

                                                      {state.agenda &&
                                                         state.agenda.map((agenda, index) => (
                                                            <React.Fragment key={index}>
                                                               {agenda.day ===
                                                                  parseInt(state.active) &&
                                                                  agenda.status === "1" && getStatusByDay(parseInt(agenda.day), slug) && (
                                                                     <tr>
                                                                        {agenda.type === "Session" && (
                                                                           <>
                                                                              <td className="text-left timeTd">
                                                                                 {agenda.startTime}
                                                                                 {/* - {agenda.endTime} */}
                                                                              </td>
                                                                              <td
                                                                                 className={`agendaItem ${agenda.isDone === "1"
                                                                                    ? "done"
                                                                                    : ""
                                                                                    }  ${agenda.isActive === "1"
                                                                                       ? "activeNow"
                                                                                       : ""
                                                                                    } ${agenda.isHeading === "1"
                                                                                       ? "heading"
                                                                                       : ""
                                                                                    }`}
                                                                              >
                                                                                 <div>
                                                                                    {i18n.language === "ar"
                                                                                       ? agenda.titleArabic
                                                                                       : agenda.title} {" "}
                                                                                    <span className="small">
                                                                                       {agenda.isActive ===
                                                                                          "1"
                                                                                          ? "(happening now)"
                                                                                          : ""}
                                                                                    </span>
                                                                                    {agenda.subTitle &&
                                                                                       <div className="agendaSubTitle">
                                                                                          <small className="text-italic">
                                                                                             {agenda.subTitle}
                                                                                          </small>

                                                                                       </div>}
                                                                                    {agenda.description &&
                                                                                       <div className="agendaDescription">
                                                                                          <div className="text-italic" dangerouslySetInnerHTML={{ __html: agenda.description }}>

                                                                                          </div>

                                                                                       </div>}
                                                                                    {clientFeatureAllowed("speakers") && generalFeatureAllowed("speakers") && agenda.speakers && agenda.speakers.length > 0 && agenda.speakers.map((speaker, index) => {
                                                                                       if (speaker.isModerator === "1" && speaker.firstName) return (
                                                                                          <React.Fragment key={index}>
                                                                                             <AgendaSpeakerModal
                                                                                                firstName={
                                                                                                   speaker.firstName
                                                                                                }
                                                                                                lastName={
                                                                                                   speaker.lastName
                                                                                                }
                                                                                                firstNameArabic={
                                                                                                   speaker.firstNameArabic
                                                                                                }
                                                                                                lastNameArabic={
                                                                                                   speaker.lastNameArabic
                                                                                                }
                                                                                                moderator={true}
                                                                                                id={
                                                                                                   speaker._id
                                                                                                }
                                                                                             />
                                                                                          </React.Fragment>
                                                                                       )
                                                                                       if (speaker.isModerator === "0" && speaker.firstName) return (
                                                                                          <>
                                                                                             <AgendaSpeakerModal
                                                                                                firstName={
                                                                                                   speaker.firstName
                                                                                                }
                                                                                                lastName={
                                                                                                   speaker.lastName
                                                                                                }
                                                                                                firstNameArabic={
                                                                                                   speaker.firstNameArabic
                                                                                                }
                                                                                                lastNameArabic={
                                                                                                   speaker.lastNameArabic
                                                                                                }
                                                                                                id={
                                                                                                   speaker._id
                                                                                                }
                                                                                             />
                                                                                          </>
                                                                                       )
                                                                                    })}
                                                                                 </div>
                                                                                 <span className="rightIcons">
                                                                                    {agenda.info && (
                                                                                       <InfoModal
                                                                                          info={
                                                                                             i18n.language ===
                                                                                                "en"
                                                                                                ? agenda.info
                                                                                                : agenda.infoArabic
                                                                                          }
                                                                                       />
                                                                                    )}
                                                                                    {agenda.download && (
                                                                                       <a
                                                                                          href={
                                                                                             agenda.download
                                                                                          }
                                                                                          download
                                                                                          target="_blank"
                                                                                          className="text-primaryBlack"
                                                                                          title={
                                                                                             state.items && state.items.tooltipDownload
                                                                                          }
                                                                                       >
                                                                                          <FiDownloadCloud className="infoIcon" />
                                                                                       </a>
                                                                                    )}
                                                                                 </span>
                                                                              </td>
                                                                           </>
                                                                        )}
                                                                        {agenda.type === "Panel Discussion" && (
                                                                           <>
                                                                              <td className="text-left timeTd">
                                                                                 {agenda.startTime}
                                                                                 {/* - {agenda.endTime} */}
                                                                              </td>
                                                                              <td
                                                                                 className={`agendaItem  ${agenda.isActive === "1"
                                                                                    ? "activeNow"
                                                                                    : ""
                                                                                    } ${agenda.isHeading === "1"
                                                                                       ? "heading"
                                                                                       : ""
                                                                                    }`}
                                                                              >
                                                                                 <div>
                                                                                    {i18n.language === "ar"
                                                                                       ? agenda.titleArabic
                                                                                       : agenda.title} {" "}
                                                                                    <span className="small">
                                                                                       {agenda.isActive ===
                                                                                          "1"
                                                                                          ? "(happening now)"
                                                                                          : ""}
                                                                                    </span>
                                                                                    {agenda.subTitle &&
                                                                                       <div className="agendaSubTitle">
                                                                                          <small className="text-italic">
                                                                                             {agenda.subTitle}
                                                                                          </small>

                                                                                       </div>}
                                                                                    {agenda.description &&
                                                                                       <div className="agendaDescription">
                                                                                          <div className="text-italic" dangerouslySetInnerHTML={{ __html: agenda.description }}>

                                                                                          </div>

                                                                                       </div>}
                                                                                    {clientFeatureAllowed("speakers") && generalFeatureAllowed("speakers") && agenda.speakers && agenda.speakers.length > 0 && agenda.speakers.map((speaker, index) => {
                                                                                       if (speaker.isModerator === "1" && speaker.firstName) return (
                                                                                          <React.Fragment key={index}>
                                                                                             <AgendaSpeakerModal
                                                                                                firstName={
                                                                                                   speaker.firstName
                                                                                                }
                                                                                                lastName={
                                                                                                   speaker.lastName
                                                                                                }
                                                                                                firstNameArabic={
                                                                                                   speaker.firstNameArabic
                                                                                                }
                                                                                                lastNameArabic={
                                                                                                   speaker.lastNameArabic
                                                                                                }
                                                                                                moderator={true}
                                                                                                id={
                                                                                                   speaker._id
                                                                                                }
                                                                                             />
                                                                                          </React.Fragment>
                                                                                       )
                                                                                    })}
                                                                                    {clientFeatureAllowed("speakers") && generalFeatureAllowed("speakers") && agenda.speakers && agenda.speakers.length > 0 && agenda.speakers.map((speaker, index) => {
                                                                                       if (speaker.isModerator === "0" && speaker.firstName) return (
                                                                                          <React.Fragment key={index}>
                                                                                             <AgendaSpeakerModal
                                                                                                firstName={
                                                                                                   speaker.firstName
                                                                                                }
                                                                                                lastName={
                                                                                                   speaker.lastName
                                                                                                }
                                                                                                firstNameArabic={
                                                                                                   speaker.firstNameArabic
                                                                                                }
                                                                                                lastNameArabic={
                                                                                                   speaker.lastNameArabic
                                                                                                }
                                                                                                id={
                                                                                                   speaker._id
                                                                                                }
                                                                                             />
                                                                                          </React.Fragment>
                                                                                       )
                                                                                    })}
                                                                                 </div>
                                                                                 <span className="rightIcons">
                                                                                    {agenda.info && (
                                                                                       <InfoModal
                                                                                          info={
                                                                                             i18n.language ===
                                                                                                "en"
                                                                                                ? agenda.info
                                                                                                : agenda.infoArabic
                                                                                          }
                                                                                       />
                                                                                    )}
                                                                                    {agenda.download && (
                                                                                       <a
                                                                                          href={
                                                                                             agenda.download
                                                                                          }
                                                                                          download
                                                                                          target="_blank"
                                                                                          className="text-primaryBlack"
                                                                                          title={
                                                                                             state.items && state.items.tooltipDownload
                                                                                          }
                                                                                       >

                                                                                          <FiDownloadCloud className="infoIcon" />
                                                                                       </a>
                                                                                    )}
                                                                                 </span>
                                                                              </td>
                                                                           </>)}
                                                                        {agenda.type === "Breakout" &&
                                                                           breakouts[
                                                                           agenda.breakoutId
                                                                           ] && (
                                                                              <>
                                                                                 <td className="text-left timeTd">
                                                                                    <p className="pt-2 mb-0">
                                                                                       {hasDuplicateStartTimes(breakouts[
                                                                                          agenda.breakoutId
                                                                                       ]) === true ? agenda.startTime : null}

                                                                                       {/* - {agenda.endTime} */}
                                                                                    </p>
                                                                                 </td>
                                                                                 <td className="paddingNo " >

                                                                                    <Table
                                                                                       borderless
                                                                                       className="mb-0 breakoutTable"
                                                                                    >
                                                                                       <tbody>
                                                                                          <tr>
                                                                                             <td style={{ backgroundColor: "#ccc" }} colSpan={breakouts[
                                                                                                agenda
                                                                                                   .breakoutId
                                                                                             ].length}>
                                                                                                <p className="mb-0 py-1 breakoutName">{agenda.breakoutName}</p>
                                                                                                {agenda.breakoutSubTitle &&
                                                                                                   <div className="agendaSubTitle">
                                                                                                      <small className="text-italic">
                                                                                                         {agenda.breakoutSubTitle}
                                                                                                      </small>

                                                                                                   </div>}
                                                                                                {agenda.breakoutDescription &&
                                                                                                   <div className="agendaDescription">
                                                                                                      <div className="text-italic" dangerouslySetInnerHTML={{ __html: agenda.breakoutDescription }}>

                                                                                                      </div>

                                                                                                   </div>}
                                                                                             </td>
                                                                                          </tr>

                                                                                          <tr>
                                                                                             {breakouts[
                                                                                                agenda
                                                                                                   .breakoutId
                                                                                             ] &&
                                                                                                breakouts[
                                                                                                   agenda
                                                                                                      .breakoutId
                                                                                                ].map(
                                                                                                   (
                                                                                                      value,
                                                                                                      index2
                                                                                                   ) => (
                                                                                                      <React.Fragment key={index2}>
                                                                                                         <td
                                                                                                            className={` ${i18n.language ===
                                                                                                               "ar"
                                                                                                               ? "arabic"
                                                                                                               : "english"
                                                                                                               } breakoutTd${breakouts[
                                                                                                                  value
                                                                                                                     .breakoutId
                                                                                                               ]
                                                                                                                  .length
                                                                                                               } ${agenda.isActive ===
                                                                                                                  "1"
                                                                                                                  ? "activeNow"
                                                                                                                  : ""
                                                                                                               } ${value.isHeading ===
                                                                                                                  "1"
                                                                                                                  ? "heading"
                                                                                                                  : ""
                                                                                                               }`}
                                                                                                         >
                                                                                                            <div>
                                                                                                               {i18n.language ===
                                                                                                                  "ar"
                                                                                                                  ? value.titleArabic
                                                                                                                  : value.title}{" "}
                                                                                                               <span className="small">
                                                                                                                  {agenda.isActive ===
                                                                                                                     "1"
                                                                                                                     ? "(happening now)"
                                                                                                                     : ""}
                                                                                                               </span>
                                                                                                               {value.subTitle &&
                                                                                                                  <div className="agendaSubTitle">
                                                                                                                     <small className="text-italic">
                                                                                                                        {value.subTitle}
                                                                                                                     </small>

                                                                                                                  </div>}
                                                                                                               {hasDuplicateStartTimes(breakouts[
                                                                                                                  agenda.breakoutId
                                                                                                               ]) === false && value.startTime && value.endTime &&
                                                                                                                  <div className="agendaSubTime">
                                                                                                                     <small className="text-italic">
                                                                                                                        {value.startTime} {value.startTime && value.endTime ? "-" : ""} {value.endTime}
                                                                                                                     </small>

                                                                                                                  </div>}
                                                                                                               {value.description &&
                                                                                                                  <div className="agendaDescription">
                                                                                                                     <div className="text-italic" dangerouslySetInnerHTML={{ __html: value.description }}>

                                                                                                                     </div>

                                                                                                                  </div>}
                                                                                                               {value.speakers && value.speakers.length > 0 && value.speakers.map((speaker, index) => {
                                                                                                                  if (speaker.isModerator === "1" && speaker.firstName) return (
                                                                                                                     <React.Fragment key={index}>
                                                                                                                        <AgendaSpeakerModal
                                                                                                                           firstName={
                                                                                                                              speaker.firstName
                                                                                                                           }
                                                                                                                           lastName={
                                                                                                                              speaker.lastName
                                                                                                                           }
                                                                                                                           firstNameArabic={
                                                                                                                              speaker.firstNameArabic
                                                                                                                           }
                                                                                                                           lastNameArabic={
                                                                                                                              speaker.lastNameArabic
                                                                                                                           }
                                                                                                                           moderator={true}
                                                                                                                           id={
                                                                                                                              speaker._id
                                                                                                                           }
                                                                                                                        />
                                                                                                                     </React.Fragment>
                                                                                                                  )
                                                                                                                  if (speaker.isModerator === "0" && speaker.firstName) return (
                                                                                                                     <React.Fragment key={index}>
                                                                                                                        <AgendaSpeakerModal
                                                                                                                           firstName={
                                                                                                                              speaker.firstName
                                                                                                                           }
                                                                                                                           lastName={
                                                                                                                              speaker.lastName
                                                                                                                           }
                                                                                                                           firstNameArabic={
                                                                                                                              speaker.firstNameArabic
                                                                                                                           }
                                                                                                                           lastNameArabic={
                                                                                                                              speaker.lastNameArabic
                                                                                                                           }
                                                                                                                           id={
                                                                                                                              speaker._id
                                                                                                                           }
                                                                                                                        />
                                                                                                                     </React.Fragment>
                                                                                                                  )
                                                                                                               })}
                                                                                                            </div>
                                                                                                            <span className="bottomIcons mt-3">
                                                                                                               {agenda.info && (
                                                                                                                  <InfoModal
                                                                                                                     info={
                                                                                                                        i18n.language ===
                                                                                                                           "en"
                                                                                                                           ? agenda.info
                                                                                                                           : agenda.infoArabic
                                                                                                                     }
                                                                                                                  />
                                                                                                               )}
                                                                                                               {agenda.download && (
                                                                                                                  <a
                                                                                                                     href={
                                                                                                                        agenda.download
                                                                                                                     }
                                                                                                                     target="_blank"
                                                                                                                     download
                                                                                                                     title="Please click to download"
                                                                                                                     className="text-primaryBlack"
                                                                                                                  >
                                                                                                                     <FiDownloadCloud className="infoIcon" />
                                                                                                                  </a>
                                                                                                               )}
                                                                                                            </span>
                                                                                                         </td>
                                                                                                      </React.Fragment>
                                                                                                   )
                                                                                                )}
                                                                                             {
                                                                                                delete breakouts[
                                                                                                agenda
                                                                                                   .breakoutId
                                                                                                ]
                                                                                             }
                                                                                          </tr>
                                                                                       </tbody>
                                                                                    </Table>
                                                                                 </td>
                                                                              </>
                                                                           )}
                                                                     </tr>
                                                                  )}
                                                            </React.Fragment>
                                                         ))}
                                                   </tbody>
                                                </Table>
                                             </Col>
                                          </>
                                       )}
                                    </Row>
                                 )}
                              </div>
                           </div>
                        </div>
                     )}
                  </>
               </>
            )}
         </BodyLayout> : <ErrorPage slug={slug} url={url} />}
      </>

   );
};

export default AgendaPage;
