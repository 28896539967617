import React,{useEffect} from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { checkEmailExist } from "../../../Helper/emailHelper";

const CMSFormEmail = (props) => {
    const { t, i18n } = useTranslation();
    const {
        name,
        label,
        form,
        required,
        autoFocus,
        description,
        placeholder,
        helpMessage,
        errorState,
        setErrorState,
        onBlur,
        ...rest
    } = props;
    // Function to handle onBlur event
    const handleBlur = async (e) => {
        // Call the onBlur function passed from props, if it exists
        if (onBlur) {
            const x = await checkEmailExist(form.values[name]);
            if (x === "1") {
                form.setFieldError("email", "This email is already registered for this event.")
                setErrorState(true)
            }
            else (setErrorState(false))
        }

        // You can add additional onBlur logic here if needed
    };
    useEffect(() => {
        handleBlur()
    }, [form.values[name]])

    return (
        <div className="form-group ">
            {/* Created Useable Component */}
            <label
                htmlFor={name}
                className={`${i18n.language === "ar" ? "arabic pe-2" : ""} ${required == true ? "mandatory_field ps-2 " : " "
                    } ps-1 mb-0`}
            >
                {" "}
                {label}
            </label>

            <p
                className="ps-1"
                style={{
                    fontStyle: "italic",
                    fontSize: 11,
                    color: "#555",
                    marginBottom: "2px",
                    marginTop: "1px",
                    minHeight: 16,
                }}
            >
                {description &&
                    (description)}
            </p>
            {/* Created Useable Component */}
            {helpMessage && helpMessage.message && (
                <OverlayTrigger
                    trigger="hover"
                    key={`info-popup-${name}`}
                    placement="right"
                    overlay={
                        <Popover id={`popover-positioned-${name}`}>
                            {helpMessage.heading && (
                                <Popover.Header as="h3">{helpMessage.heading}</Popover.Header>
                            )}

                            <Popover.Body>{helpMessage.message}</Popover.Body>
                        </Popover>
                    }
                >
                    <i className="fa fa-info-circle ml-2 infoIcon"></i>
                </OverlayTrigger>
            )}

            <Field
                name={name}
                autoFocus={autoFocus}
                placeholder={placeholder}
                onBlur={(e) => { form.handleBlur(e); handleBlur() }}
                onChange={(e) => { form.handleChange(e); handleBlur() }}
                className={`form-control customEmail ${i18n.language === "ar" ? "arabic" : ""}  ${((form.touched[name] && form.errors[name]) || (!form.errors[name] &&
                    errorState)) ? "is-invalid" : " "
                    }`}
                {...rest}
            />
            {required === true && <div className="required"></div>}

            {form.touched[name] && form.errors[name] && (
                <span id={`${name}-error"`} className="errorMessage">
                    <ErrorMessage name={name} />
                </span>
                // <div className="errorMessage">{form.errors[name]}</div>
            )}
            {!form.errors[name] &&
                errorState && (
                    <span id={`${name}-error"`} className="error errorMessage">
                        This email is already registered for this event.
                    </span>
                )}
        </div>
    );
};

export default CMSFormEmail;
