import React, { useState, useEffect } from "react";
const Heading = () => {
  const [items, setItems] = useState({});
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
      setItems(items);
    }
  }, []);
  return (
    <h2 className="heading_text  text-tertiaryClient pb-4 mb-3">
      {items.menuAgendaText}
    </h2>
  );
};

export default Heading;
