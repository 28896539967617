import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function CMSFormCheckBoxes(props) {
  const { label, name, options, form, required, ...rest } = props;
  const { t, i18n } = useTranslation();
  return (
    <div>
      <label
        htmlFor={name}
        className={`${
          required == true ? "mandatory_field ps-2 " : " "
        } ps-1 mb-3`}
      >
        {" "}
        {label}
      </label>
      <div
        className={` ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
      >
    
        {options.map((option) => {
          return (
            <div key={option.label} className="checkBoxContainer mb-1 pb-1 ms-2">
              <Field
                name={name}
                type="checkbox"
                id={option.value}
                className="checkbox"
                value={option.value}
                defaultChecked={form.values[name] && form.values[name].includes(option.value)}
              />
              <label
                htmlFor={option.value}
                className={` ${i18n.language === "ar" ?"me-2" : "ms-2"} ${
                  form.touched[name] && form.errors[name]
                    ? "isInvalidText checkboxText "
                    : " checkboxText"
                }`}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
}

export default CMSFormCheckBoxes;
