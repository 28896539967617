import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import "./subheader.scss";
import { HoverBlue } from "../../../shared/buttons/Buttons";
import bannerVisual from "../../../../assets/images/bannervisual.png";
import { Link, useParams } from "react-router-dom";
import bg from "../../../../assets/images/banner visual.png";
import { useTranslation } from "react-i18next";
import CountDownHolder from "./countdown/CountDownHolder";
import RegisterSubheader from "../../../pages/registration/registrationSubHeader/RegisterSubheader";
import { featureAllowed, findLargestDateOver } from "../../../../Helper/featuresHelper";

const Subheader = () => {

  const button_style = {
    width: "179.02px",
    height: "52.02px",
  };
  const { slug, url } = useParams();
  const { t, i18n } = useTranslation();
  const pathname = window.location.pathname; //returns the current url minus the domain name
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {

      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);

  function doesPathnameMatch(pathname) {
    // Regular expression pattern to match "about" or "disclaimer" anywhere in the pathname
    const pattern = /\/(about|disclaimer|sponsors-partners|contact-us|speakers|highlights)\//i;
    const pattern2 = /\/(1|2|3|4|5|6|7|8|9|agenda)\//i;
    const pattern3 = /\/(registration)\//i;
    if (pattern.test(pathname)) {
      
      return 1
    } else if (pattern2.test(pathname)) {
      
      return 2
    } else if (pattern3.test(pathname)) {
      
      return 3
    } else { return 4 }


  }
  return (
    <>

      {screen.content && screen.content.activity && (
        <section>
          {doesPathnameMatch(pathname) === 1 ? (
            <>
              {screen.content.activity.regPageCustomization && featureAllowed("showBannerOnSubPage") &&
                <Row
                  className="subPage_subheader_row mx-auto"
                  style={{
                    backgroundImage: `url(${screen.content.activity.regPageCustomization.subPageBanner ? process.env.REACT_APP_BACKEND_URL + "public/" + screen.content.activity.regPageCustomization.subPageBanner : null})`,
                  }}
                >
                  <Col
                    xl={12}
                    className={`subPage_subheader_container pr-0 ${i18n.language === "ar" ? "arab" : ""
                      }`}
                  >
                    <Row
                      className={`subPageRow ${i18n.language === "ar" ? "arabic" : "english"
                        }`}
                    >
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="p-0"
                      >
                        <div className="heading_container ">
                          <h1

                            className={`heading ${screen.content.activity.title.length > 35 &&
                              screen.content.activity.title.length < 45
                              ? "fs-67"
                              : screen.content.activity.title.length > 45 &&
                                screen.content.activity.title.length < 67
                                ? "fs-57"
                                : screen.content.activity.title.length > 67
                                  ? "fs-50"
                                  : "fs-45"
                              }`}
                          >
                            {i18n.language === "ar"
                              ? screen.content.activity.titleArabic
                              : screen.content.activity.title}
                          </h1>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>}

            </>
          ) : doesPathnameMatch(pathname) === 2 ? (
            <>
              <RegisterSubheader needed="no" />
            </>
          ) : doesPathnameMatch(pathname) === 3 ? (
            <>
              <RegisterSubheader file={`${process.env.REACT_APP_API_URL}activities/download-calendar/${slug}`} />
            </>
          ) : (
            doesPathnameMatch(pathname) === 4 && (
              <>
                {screen.content &&
                  screen.content.activity && screen.content.activity.content &&
                  <Row className="subheader_row ms-0 ">
                    <Col
                      xl={12}
                      className="subheader_container  mx-auto p-0"
                    >
                      <Row
                        className={`${i18n.language === "ar" ? "arabic" : "english"
                          } paddingRow w-100 mx-auto h-100`}
                      >
                        <Col
                          xl={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 8 : 11}
                          lg={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 7 : 11}
                          md={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 7 : 11}
                          sm={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 6 : 11}
                          xs={12}
                          className="mx-auto p-0 h-100"
                        >
                          <div className="heading_container ">
                            <>
                              <React.Fragment >

                                <h1
                                  className={`heading ${screen.content.activity.title.length > 35 &&
                                    screen.content.activity.title.length < 45
                                    ? "fs-67"
                                    : screen.content.activity.title.length > 45 &&
                                      screen.content.activity.title.length < 67
                                      ? "fs-57"
                                      : screen.content.activity.title.length > 67
                                        ? "fs-50"
                                        : "normal"
                                    }`}
                                >
                                  {i18n.language === "ar"
                                    ? screen.content.activity.titleArabic
                                    : screen.content.activity.title}
                                </h1>
                                {screen.content.activity && screen.content.activity.content &&
                                  <p className="description">
                                    {i18n.language === "ar"
                                      ? screen.content.activity.content.subTitleArabic
                                      : screen.content.activity.content.subTitle}
                                  </p>}
                              </React.Fragment>
                              {screen.items &&
                                <Link
                                  to={slug != undefined && url != undefined ? `/registration/${slug}/${url}` : slug != undefined ? `/registration/${slug}` : `/registration`}
                                  title={
                                    i18n.language === "ar"
                                      ? "سجل الان"
                                      : "Register Now"
                                  }
                                >
                                  <HoverBlue
                                    name={screen.items && screen.items.buttonRegisterNow}
                                    style={button_style}
                                  />
                                </Link>}
                            </>
                          </div>
                        </Col>

                        <Col xl={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 4 : 1} lg={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 5 : 1} md={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 5 : 1} sm={featureAllowed("showBannerOnHomePage") && screen.content.activity.regPageCustomization.homePageBanner ? 5 : 1} className="p-0">


                          <div className="banner_container px-0">
                            {featureAllowed("showBannerOnHomePage") &&
                              <img
                                src={screen.content.activity.regPageCustomization.homePageBanner ? process.env.REACT_APP_BACKEND_URL + "public/" + screen.content.activity.regPageCustomization.homePageBanner : null}
                                alt=" "
                                className="banner_image "
                                loading="lazy"
                              />}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              </>
            )
          )}
          <CountDownHolder />
        </section>
      )}
    </>
  );
};

export default Subheader;
