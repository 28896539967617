
export const getDateFromDay = (dayToFind) => {
    const content = JSON.parse(localStorage.getItem("content"));

    if (content.activity && content.activity.days) {
        const foundDay = content.activity.days.find(days => days.day === dayToFind);

        return foundDay ? foundDay.date : null;
    }
    else {
        return null;
    }

};

export const getFileFromDay = (array,dayToFind) => {

    if (array) {
        const foundDay = array.find(days => days.day === dayToFind);
        if(foundDay && foundDay.file &&foundDay.file != "") {
            return  process.env.REACT_APP_BACKEND_URL + "public/" + foundDay.file;
        }
        else{
            return null;
        }
        
    }
    else {
        return null;
    }

};
