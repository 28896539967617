import React, { useState, useEffect, useReducer } from "react";
import "./subfooter.scss";
import { Row, Col } from "react-bootstrap";
import LocationLoading from "../../../../assets/images/locloading2.json";
import Lottie from "lottie-react";
import RegisterNow from "./registerNow/RegisterNow";
import { useTranslation } from "react-i18next";
import {
  listingInitialState,
  listingReducer,
} from "../../../shared/reducers/listingReducer";
import { featureAllowed, generalFeatureAllowed } from "../../../../Helper/featuresHelper";
const SubFooter = (props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [screen, setScreen] = useState({
    items: {},
    content: {}
  })

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("constants"));
    if (items) {
 
      setScreen((prevState) => ({
        ...prevState,
        items: items,
      }));
    }
    const content = JSON.parse(localStorage.getItem("content"));
    if (content) {
      setScreen((prevState) => ({
        ...prevState,
        content: content,
      }));
    }
  }, []);
  const { t, i18n } = useTranslation();
  const pathname = window.location.pathname;
  function doesPathnameMatch(pathname) {
    // Regular expression pattern to match "about" or "disclaimer" anywhere in the pathname
    const pattern = /\/(about|disclaimer|sponsors-partners|contact-us|speakers|highlights)\//i;
    const pattern2 = /\/(1|2|3|4|5|6|7|8|9|agenda)\//i;
    const pattern3 = /\/(registration)\//i;
    if (pattern.test(pathname)) {
  
      return 1
    } else if (pattern2.test(pathname)) {
     
      return 2
    } else if (pattern3.test(pathname)) {
    
      return 3
    } else {return 4}


  }
  return (
    <section>
      {props.no ? null : <RegisterNow />}
      {generalFeatureAllowed("location") && <>
        {screen.content && screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapLink &&
          <>


            <div className="paddingContainerSubFooter">
              <div
                className={
                  i18n.language === "ar"
                    ? "contentContainerSubFooter arabic"
                    : "contentContainerSubFooter"
                }
              >
                {" "}
                <Row className="d-flex align-items-center locationRow  ">
                  <Col xl={6} md={8} sm={6} xs={12}>
                    <h2 className="left-text location">
                      {screen.items && screen.items.sectionLocationText}
                    </h2>
                  </Col>

                  <Col
                    xl={6}
                    xs={12}
                    md={4}
                    sm={6}
                    className={`direction ${i18n.language === "ar" ? "right-text" : "right-text"
                      }`}
                  >
                    {screen.content.activity && screen.content.activity.location && (
                      <a
                        className="getDirectionTextHolder"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapLink}`}
                      >
                        <p
                          className="getDirection"
                          title={
                            i18n.language === "ar"
                              ? "عرض الاتجاهات"
                              : "View directions"
                          }
                        >
                          {screen.items && screen.items.linkGetDirectionText}
                          <span className="directionArrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14.925"
                              height="13"
                              viewBox="0 0 14.925 13"
                            >
                              <g
                                id="Group_119"
                                data-name="Group 119"
                                transform="translate(-3041.075 -2593.364)"
                              >
                                <g id="Group_118" data-name="Group 118">
                                  <path
                                    id="Path_174"
                                    data-name="Path 174"
                                    d="M3053.251,2600.372h-10.887c-.22,0-.494.073-.646-.03-.27-.182-.641-.472-.643-.721,0-.23.393-.51.668-.68.16-.1.428-.026.647-.026h11.024c-.247-.286-.382-.462-.537-.617q-1.728-1.737-3.465-3.464c-.377-.374-.681-.784-.194-1.245s.878-.145,1.253.231q2.585,2.586,5.185,5.156a.791.791,0,0,1,.051,1.266q-2.9,2.892-5.819,5.761c-.166.163-.4.369-.595.362-.249-.009-.65-.17-.7-.347a1.1,1.1,0,0,1,.169-.907c.892-.967,1.84-1.883,2.776-2.808.589-.582,1.192-1.149,1.789-1.723Z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </p>
                      </a>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </>}
        {doesPathnameMatch(pathname) === 4 ? featureAllowed("showLocationMapOnHomePage") ?
          <>  {screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapSrc && (
            <Row className="mx-auto bg-primaryWhite">
              <Col xl={12} className="mx-auto d-flex p-0 map">
                {loading ? (
                  <>
                    <div
                      className="w-100 d-flex align-items-center justify-content-center"
                      style={{ height: "543px" }}
                    >
                      <Lottie
                        animationData={LocationLoading}
                        className="w-100 "
                        style={{ height: "100px" }}
                        loop={true}
                      />
                    </div>
                  </>
                ) : (
                  <iframe
                    className={`w-100 ${i18n.language === "ar" ? "arabic" : ""}`}
                    height="543"
                    lang="ar"
                    src={
                      i18n.language === "ar"
                        ? listingState.data.iframeLinkArabic
                        : screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapSrc
                    }
                  ></iframe>
                )}
              </Col>
            </Row>
          )}</> : null :
           <>  {screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapSrc && (
            <Row className="mx-auto bg-primaryWhite">
              <Col xl={12} className="mx-auto d-flex p-0 map">
                {loading ? (
                  <>
                    <div
                      className="w-100 d-flex align-items-center justify-content-center"
                      style={{ height: "543px" }}
                    >
                      <Lottie
                        animationData={LocationLoading}
                        className="w-100 "
                        style={{ height: "100px" }}
                        loop={true}
                      />
                    </div>
                  </>
                ) : (
                  <iframe
                    className={`w-100 ${i18n.language === "ar" ? "arabic" : ""}`}
                    height="543"
                    lang="ar"
                    src={
                      i18n.language === "ar"
                        ? listingState.data.iframeLinkArabic
                        : screen.content.activity && screen.content.activity.location && screen.content.activity.location.googleMapSrc
                    }
                  ></iframe>
                )}
              </Col>
            </Row>
          )}</>}

      </>}

    </section>
  );
};

export default SubFooter;
