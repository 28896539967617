import React, { useEffect } from "react";
import BodyLayout from "../../layout/BodyLayout";
import { useTranslation } from "react-i18next";
import "./contactUs.scss";
import Heading from "./partials/Heading";
import ContactForm from "./partials/Form";
import { removeAndCapitalizeEachWord } from "../../../Helper/featuresHelper";
import { useParams } from "react-router-dom";
const ContactUs = () => {
  const { i18n } = useTranslation();
  
  const { slug } = useParams();
  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
    document.title = `${removeAndCapitalizeEachWord(slug)} | Contact-Us`;
  }, [slug]);
  return (
    <BodyLayout>
      <div className="paddingContainerContactUs" >
        <div
          className={`contentContainerContactUs ${i18n.language === "ar" ? "arabic" : ""
            } `}
        >
          <Heading />
          <div >
            <ContactForm />
          </div>
        </div>
      </div>
    </BodyLayout>
  );
};

export default ContactUs;
