import React, { useReducer, useEffect, useState } from "react";
import BodyLayout from "../../layout/BodyLayout";
import { useTranslation } from "react-i18next";
import "./disclaimer.scss";
import Heading from "./partials/Heading";
import {
  listingInitialState,
  listingReducer,
} from "../../shared/reducers/listingReducer";
import axios from "../../../config/axios";
import { ContainerLoading } from "../../shared/loading/Loading";
import BlockErrorMessage from "../../shared/errorMessages/BlockErrorMessage";
import { useParams } from "react-router-dom";
import { removeAndCapitalizeEachWord } from "../../../Helper/featuresHelper";

const DisclaimerPage = () => {
  const { i18n } = useTranslation();
  const [content, setContent] = useState({});
  const content1 = JSON.parse(localStorage.getItem("content"));
  useEffect(() => {
   
    if (content1) {
      setContent(content1);
    }
  }, []);
  const { slug } = useParams();
  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
    document.title = `${removeAndCapitalizeEachWord(slug)} | Disclaimer`;
  }, [slug]);
  return (
    <BodyLayout>
      <div className="paddingContainerDisclaimer">
        <div
          className={`contentContainerDisclaimer ${i18n.language === "ar" ? "arabic" : ""
            } `}
        >

          {content.activity && content.activity.disclaimer && content.activity.disclaimer.disclaimer ? (
            <>
              <Heading />
              {content.activity && content.activity.disclaimer && (
                <div className="disclaimer_description">
                  <>
                    {i18n.language === "ar" ? (
                      <div
                        className="html"
                        dangerouslySetInnerHTML={{
                          __html: content.activity && content.activity.disclaimer.disclaimerArabic
                        }}
                      />
                    ) : (
                      <div
                        className="html"
                        dangerouslySetInnerHTML={{
                          __html: content.activity && content.activity.disclaimer.disclaimer,
                        }}
                      />
                    )}
                  </>
                </div>
              )}
            </>
          ):<BlockErrorMessage message1="Disclaimer Not Available" />}
        </div>
      </div>
    </BodyLayout>
  );
};

export default DisclaimerPage;
