import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BiErrorCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
const BlockErrorMessage = ({ error, message1 }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(500);
  const { i18n } = useTranslation();
  const { slug } = useParams();
  useEffect(() => {
    if (!error) {
      setErrorMessage("Action failed, try again later.");
    } else {
      if (typeof error === "string" || error instanceof String) {
        setErrorMessage(error);
      } else if (typeof error === "Object" || error instanceof Error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setStatus(500);
          setErrorMessage("Action failed.");
        }
      }
    }
  }, [error]);
  useEffect(() => {
    localStorage.removeItem(`activeAgendaDay${slug}`);
  }, [slug]);
  return (
    <>
      {i18n.language === "ar" ? (
        <div className="noItemsFound">
          <BiErrorCircle className="errorIcon" />
          <h1>عذرا، هناك خطأ ما</h1>
          <p>
            {" "}
            سنعمل على إصلاح ذلك على الفور. يرجى المحاولة مرة أخرى لاحقًا.
            {/* سنعمل على إصلاح ذلك على الفور. وفي الوقت نفسه، يجوز لك{" "}
            <Link to="/">العوده للصفحة الرئيسية</Link> */}
          </p>
        </div>
      ) : (
        <div className="noItemsFound">
          <BiErrorCircle className="errorIcon" />
          <h1>{message1 ? message1 : "Sorry, Something went wrong"}</h1>
          <p>
            {" "}
            {/* We will work on fixing that right away. */}
            Please try again later.
            {/* Meanwhile, you may{" "}
            <Link to="/">return to Home Page</Link> */}
          </p>
        </div>
      )}
    </>
    // <div className="error-page inDashboardErrorPage">
    //     <h2 className="headline text-danger">Sorry!</h2>
    //     <div className="error-content">
    //         <h3><i className="fas fa-exclamation-triangle text-danger" />Something went wrong.</h3>
    //         <p>
    //             We will work on fixing that right away.
    //             Meanwhile, you may <Link to="/">return to Home Page</Link>.
    //         </p>
    //     </div>
    // </div>
  );
};

export default BlockErrorMessage;
