import React, { useEffect, useReducer, useState,useContext } from "react";
import { Route, Routes, useHistory, useNavigate,  } from "react-router-dom";
import Home from "../components/pages/home/Home";
import AboutTheEvent from "../components/pages/about/AboutTheEvent";
import ErrorPage from "../components/pages/error/ErrorPage";
import AgendaPage from "../components/pages/agenda/Agenda";
import SpeakerPage from "../components/pages/speakers/SpeakerPage";
import SponsorsPartners from "../components/pages/sponsorsPartners/SponsorsPartners";
import RegistrationPage from "../components/pages/registration/RegistrationPage";
import ContactUs from "../components/pages/contactUs/ContactUs";
import DisclaimerPage from "../components/pages/disclaimer/DisclaimerPage";
import ErrorPageNoLink from "../components/pages/error/ErrorPageNoLink";
import { listingInitialState, listingReducer } from "../components/shared/reducers/listingReducer";
import axios from "./axios";
import { ContainerLoading } from "../components/shared/loading/Loading";
import { ClientContext } from "../context/ClientContext";

const AppRoutes = () => {
  const [content, setContent] = useState({});
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const { client, setClient } = useContext(ClientContext);
  // const loadItems = async () => {


  //   listingDispatch({
  //     type: "FETCH_REQUEST",
  //   });

  //   try {
  //     const res = await axios.get(`activities/client/${process.env.REACT_APP_CLIENT_ID}`); //
  //     listingDispatch({
  //       type: "FETCH_SUCCESS",
  //       payload: res.data,
  //     });

  //     localStorage.setItem("clientDetails", JSON.stringify(res.data));
  //     setContent(res.data)
  //   } catch (error) {
  //     listingDispatch({
  //       type: "FETCH_FAILURE",
  //       payload: error,
  //     });

  //   }

  // };

  // useEffect(() => {
  //   loadItems();
  // }, []);
  // const findLargestDateOver = () => {
  //   const content = JSON.parse(localStorage.getItem("clientDetails"));
  //   if (content) {
  //     const schedule = content && content.activity && content.activity.days
  //     let largestDate = null;

  //     schedule.forEach(day => {
  //       if (day.date) {
  //         const currentDate = new Date(day.date);

  //         if (!largestDate || currentDate > largestDate) {
  //           largestDate = currentDate;
  //         }
  //       }
  //     });
  //     const today = new Date();
  //     const eventLargestDay = new Date(largestDate)
  //     const isLargestDateGreaterThanToday = eventLargestDay < today;
  //     return isLargestDateGreaterThanToday
  //   }

  // }
  // const findPackageExpired = () => {
  //   const content = JSON.parse(localStorage.getItem("clientDetails"));
  //   if (content) {
  //     var endDate = new Date(content.activity && content.activity.client && content.activity.client.package && content.activity.client.package.endDate);
  //     var today = new Date();

  //     if (endDate > today) {
  //       return false;
  //     } else if (endDate < today) {
  //       return true
  //     } else {
  //       return false;
  //     }
  //   }

  // }

  useEffect(() => {
    console.log(client.data)
    const clientContent = JSON.parse(localStorage.getItem("clientDetails"));
    if (clientContent) {
      if (clientContent.clientTheme) {
        const root = document.documentElement;
        root.style.setProperty(`--client-primary`, clientContent.clientTheme.primaryColor);
        root.style.setProperty(`--client-secondary`, clientContent.clientTheme.secondaryColor);
        root.style.setProperty(`--client-tertiary`, clientContent.clientTheme.tertiaryColor);
        root.style.setProperty(`--client-quaternary`, clientContent.clientTheme.quaternaryColor);
      }
    }
  }, [client]);
  const clientFeatureAllowed = (feature) => {
    const content = JSON.parse(localStorage.getItem("clientDetails"));
    if (feature) {
      if (
        content &&
        content.packageFeatures &&
        content.packageFeatures[feature] === "1"
      ) {

        return true;
      }
    }
    return false
  };

  return (
    <Routes>
       
        <>
          <Route path=":slug" element={<Home />} />
          <Route path=":slug/:url" element={<Home />} />
          {/* <Route path="/:slug" exact={true} element={<Home />} /> */}
          <Route path="/about/:slug" element={<AboutTheEvent />} />
          <Route path="/about/:slug/:url" element={<AboutTheEvent />} />
          <Route path="/about/:slug/:url" element={<AboutTheEvent />} />
          {clientFeatureAllowed("agenda") &&
            <>
              <Route path="/agenda/:slug/:day" element={<AgendaPage />} />
              <Route path="/agenda/:slug/:url/:day" element={<AgendaPage />} />
            </>}
          {clientFeatureAllowed("speakers") && <>

            <Route path="/speakers/:slug" element={<SpeakerPage />} />
            <Route path="/speakers/:slug/:url" element={<SpeakerPage />} />
            </>
            }
          {(clientFeatureAllowed("sponsors") || clientFeatureAllowed("partners")) ? <>
            <Route path="/sponsors-partners/:slug/" element={<SponsorsPartners />} /> 
            <Route path="/sponsors-partners/:slug/:url" element={<SponsorsPartners />} />
            </>: null}
          <Route path="/registration/:slug/" element={<RegistrationPage />} />
          <Route path="/registration/:slug/:url" element={<RegistrationPage />} />
          <Route path="/contact-us/:slug" element={<ContactUs />} />
          <Route path="/contact-us/:slug/:url" element={<ContactUs />} />
          <Route path="/disclaimer/:slug" element={<DisclaimerPage />} />
          <Route path="/disclaimer/:slug/:url" element={<DisclaimerPage />} />
          <Route path="*" element={<ErrorPageNoLink />} />

        </>


     
    </Routes>
  );
};

export default AppRoutes;
