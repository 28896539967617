import "./App.scss";
import React from "react";
import AppRoutes from "./config/appRoutes";
import ClientContextProvider from "./context/ClientContext";
function App() {
  
  return (
    <ClientContextProvider>
      <AppRoutes />
    </ClientContextProvider>
  );
}

export default App;
